import * as types from 'Constants/actionTypes';

const initialSettings = {
  resource_records: [],
  fetching: false,
  fetched: false,
  delete_in_progress: false,
  reconfigure_in_progress: false,
  add_in_progress: false,
};

const resourceRecords = (state = initialSettings, action) => {
  switch (action.type) {
    case types.FETCH_RESOURCE_RECORDS_INITIATED:
      return { ...state, fetching: true };
    case types.FETCH_RESOURCE_RECORDS_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        resource_records: action.Response,
      };
    case types.DELETE_RESOURCE_RECORD_INITIATED:
      return { ...state, delete_in_progress: true };
    case types.DELETE_RESOURCE_RECORD_SUCCESS:
      return {
        ...state,
        delete_in_progress: false,
        resource_records: state.resource_records.filter((i) => i.id !== action.id),
      };
    case types.DELETE_RESOURCE_RECORD_ERROR:
      return { ...state, delete_in_progress: false };
    case types.RECONFIGURE_RESOURCE_RECORD_INITIATED:
      return { ...state, reconfigure_in_progress: true };
    case types.RECONFIGURE_RESOURCE_RECORD_SUCCESS:
      return { ...state, reconfigure_in_progress: false };
    case types.RECONFIGURE_RESOURCE_RECORD_ERROR:
      return { ...state, reconfigure_in_progress: false };
    case types.ADD_RESOURCE_RECORD_INITIATED:
      return { ...state, add_in_progress: true };
    case types.ADD_RESOURCE_RECORD_SUCCESS:
      return {
        ...state,
        add_in_progress: false,
        resource_records: [...state.resource_records, action.response],
      };
    case types.ADD_RESOURCE_RECORD_ERROR:
      return { ...state, add_in_progress: false };
    case types.LOGOUT_USER:
      return { ...initialSettings };
    default:
      return state;
  }
};

export default resourceRecords;
