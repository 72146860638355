// default imports
import { addPaymentMethod } from 'Actions/paymentMethod';
import $ from 'jquery';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormGroup, Form, ControlLabel } from 'react-bootstrap';
import { connect } from 'react-redux';

// styling
import './Payment.css';

export class Payment extends Component {
  constructor(props) {
    super(props);
    this.stripe = window.Stripe(process.env.REACT_APP_STRIPE_PK_KEY);
    this.card = undefined;
    this.clientSecret = undefined;
    this.state = {
      message: undefined,
      error: undefined,
      statusIcon: <i className="" />,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.card = this.stripe.elements().create('card', {
      iconStyle: 'solid',
      style: {
        base: {
          iconColor: '#ccc',
          fontWeight: '500',
          fontSize: '16px',
          '::placeholder': {
            color: '#ccc',
          },
        },
        invalid: {
          iconColor: '#e85746',
          color: '#e85746',
        },
      },
      classes: {
        focus: 'is-focused',
        empty: 'is-empty',
      },
    });

    this.card.mount('#card-element');
    $.each($('input.field'), (index, elem) => {
      elem.addEventListener('focus', () => {
        elem.classList.add('is-focused');
      });
      elem.addEventListener('blur', () => {
        elem.classList.remove('is-focused');
      });
      elem.addEventListener('keyup', () => {
        if (elem.value.length === 0) {
          elem.classList.add('is-empty');
        } else {
          elem.classList.remove('is-empty');
        }
      });
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({
      message: undefined,
      error: undefined,
      statusIcon: <i className="fa fa-spin fa-spinner in-progress" />,
    });
    this.props.FetchService.getSetupIntent()
      .then((intent) => {
        this.clientSecret = intent.client_secret;
        if (this.stripe) {
          this.stripe.handleCardSetup(this.clientSecret, this.card).then((setupData) => {
            const { error, setupIntent } = setupData;
            if (error) {
              this.setState({
                error: `Setup failed: ${error.message}`,
                statusIcon: <i className="fa fa-times error" />,
              });
            } else {
              this.props.addPaymentMethod(setupIntent.payment_method).then(() => {
                this.setState({
                  message: 'Card successfully saved!',
                  statusIcon: <i className="fa fa-check success" />,
                });
              });
            }
          });
        }
      })
      .catch((error) => {
        this.setState({ error, statusIcon: <i className="fa fa-times error" /> });
      });
  }

  render() {
    const { error, message, statusIcon } = this.state;
    return (
      <div id="payment">
        <Form horizontal onSubmit={this.handleSubmit}>
          <FormGroup className="card-form">
            <ControlLabel>
              <div id="card-element" className="field is-empty" />
            </ControlLabel>
            <button
              type="button"
              disabled={this.props.disabled}
              className="btn btn-success"
              onClick={(e) => {
                this.handleSubmit(e);
              }}
            >
              {this.props.buttonText}
            </button>
            <div className="process-box">{statusIcon}</div>
          </FormGroup>
        </Form>
        <div
          className="error-box error "
          style={{
            textAlign: 'center',
            fontWeight: 400,
            whiteSpace: 'normal',
            height: 'auto',
          }}
        >
          {error}
        </div>
        <div className="success-box success " style={{ textAlign: 'center', fontWeight: 400 }}>
          {message}
        </div>
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

Payment.propTypes = {
  disabled: PropTypes.bool,
  buttonText: PropTypes.string.isRequired,
  addPaymentMethod: PropTypes.func.isRequired,
  FetchService: PropTypes.shape().isRequired,
};
Payment.defaultProps = {
  disabled: false,
};

export default connect(mapStateToProps, {
  addPaymentMethod,
})(Payment);
