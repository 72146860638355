import * as types from 'Constants/actionTypes';

const initialSettings = {
  locations: {},
  fetching: false,
  locations_fetched: false,
};

const locations = (state = initialSettings, action) => {
  switch (action.type) {
    case types.FETCH_LOCATIONS_INIT:
      return { ...state, fetching: true };
    case types.FETCH_LOCATIONS_SUCCESS:
      return {
        ...state,
        fetching: false,
        locations_fetched: true,
        locations: action.Response.sort((a, b) => a.location_id - b.location_id),
      };
    case types.FETCH_LOCATIONS_ERROR:
      return {
        ...state,
        fetching: false,
        locations_fetched: false,
        errors: action.Response.error,
      };
    default:
      return state;
  }
};

export default locations;
