// default imports
import { logout } from 'Actions/authentication';
import { fetchMoreStaffUsers, getStaffUsers } from 'Actions/staffUsers';
// custom components
// styling
import logo from 'assets/images/logo_color_white.svg';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Button, FormGroup, Navbar, Nav, NavItem, NavDropdown, MenuItem } from 'react-bootstrap';
import { connect } from 'react-redux';
import Select from 'react-select';

import StorageService from '../../utils/StorageService';
import './Header.css';

export class Header extends PureComponent {
  constructor(props) {
    super(props);
    this.changePath = this.changePath.bind(this);
    this.logout = this.logout.bind(this);
    this.state = {
      doSearchUser: false,
      selectedUser: StorageService.getImpersonatedUser(),
    };
  }

  componentDidMount() {
    const { dispatch, staffUsers } = this.props;
    if (this.isLoggedIn() && !staffUsers.fetched) {
      dispatch(getStaffUsers());
    }
  }

  isActive() {
    const { auth } = this.props;
    return Object.prototype.hasOwnProperty.call(auth.getDomainsInformation(), 'count');
  }

  logout() {
    const { dispatch, history } = this.props;
    dispatch(logout());
    this.setState({ doSearchUser: false, selectedUser: null });
    history.push('/login');
    StorageService.setAlertDismissTime(null);
  }

  changePath(path) {
    const { history } = this.props;
    history.push(path);
  }

  // eslint-disable-next-line class-methods-use-this
  openNewTab(path) {
    window.open(path, '_blank');
  }

  toggleUserSearchInput = () => {
    const { doSearchUser, selectedUser } = this.state;
    const { dispatch, staffUsers } = this.props;
    const doSearch = doSearchUser || selectedUser;
    if (doSearch && selectedUser) {
      StorageService.setImpersonatedUser(null);
      window.location = '/';
    }
    if (!doSearch && !staffUsers.fetched) {
      dispatch(getStaffUsers());
    }
    this.setState({ doSearchUser: !doSearch, selectedUser: StorageService.getImpersonatedUser() });
  };

  selectUser = (selectedUserOption) => {
    const { staffUsers } = this.props;
    const selectedUser = staffUsers.results.find((i) => i.id === selectedUserOption.value);
    this.setState({ selectedUser });
    StorageService.setImpersonatedUser(selectedUser);
    window.location.reload();
  };

  loadUserOptions = (search) => {
    const { dispatch } = this.props;
    dispatch(getStaffUsers(search));
  };

  loadMoreUsers = () => {
    const { dispatch, staffUsers } = this.props;
    dispatch(fetchMoreStaffUsers(staffUsers));
  };

  isLoggedIn() {
    const { auth } = this.props;
    return auth.loggedIn();
  }

  render() {
    let navbar;
    const { authentication } = this.props;
    const { user } = authentication;
    const impersonatedUser = StorageService.getImpersonatedUser();
    const showImpersonationButton = impersonatedUser ? impersonatedUser.is_staff : user.is_staff;
    const { staffUsers } = this.props;
    if (this.isLoggedIn()) {
      navbar = (
        <div>
          <Navbar.Header className="navbarHeader">
            <Navbar.Brand className="navbarBrand">
              <a href="/">
                <img src={logo} alt="" className="navbarBrandLogo" />
              </a>
            </Navbar.Brand>
          </Navbar.Header>
          <Nav className="nav">
            <NavItem eventKey={1} className="navItem" onClick={() => this.changePath('/dashboard')}>
              Dashboard
            </NavItem>
            {!user.is_user && (
              <NavItem eventKey={2} className="navItem" onClick={() => this.changePath('/history')}>
                History
              </NavItem>
            )}
            <NavDropdown eventKey={3} title="Manage" id="configure-dropdown">
              {user && !user.manually_paying && !user.is_user && (
                <MenuItem onClick={() => this.changePath('/configure/subscriptions')}>
                  Subscriptions
                </MenuItem>
              )}
              {!user.is_user && (
                <MenuItem onClick={() => this.changePath('/configure/domains')}>Domains</MenuItem>
              )}

              <MenuItem onClick={() => this.changePath('/configure/alerts')}>Alerts</MenuItem>
              {(user.is_reseller || user.is_operator) && (
                <MenuItem onClick={() => this.changePath('/configure/users')}>Users</MenuItem>
              )}
              {false && ( // Remove this when API keys visible again
                <MenuItem onClick={() => this.changePath('/configure/apikeys')}>Api Keys</MenuItem>
              )}
            </NavDropdown>
            <NavDropdown eventKey={4} title="Legal" id="legal-dropdown">
              <MenuItem
                onClick={() => this.openNewTab('https://dnsmonitor.com/service-agreement/')}
              >
                Service Agreement
              </MenuItem>

              <MenuItem onClick={() => this.openNewTab('https://dnsmonitor.com/privacy-policy/')}>
                Privacy Policy
              </MenuItem>

              <MenuItem onClick={() => this.openNewTab('https://dnsmonitor.com/cookies-policy/')}>
                Cookies Policy
              </MenuItem>
            </NavDropdown>
            {showImpersonationButton && (
              <NavItem eventKey={6} onClick={this.toggleUserSearchInput} className="navItem">
                Impersonate
              </NavItem>
            )}
          </Nav>

          <Button className="logoutBtn" onClick={this.logout}>
            Log out
          </Button>
        </div>
      );
    }

    const { doSearchUser, selectedUser } = this.state;
    const isImpersonating = doSearchUser || selectedUser;
    const options = staffUsers.results.map((i) => ({ value: i.id, label: i.email }));
    if (selectedUser && !options.some((i) => i.value === selectedUser.id)) {
      options.push({ value: selectedUser.id, label: selectedUser.email });
    }
    return (
      <div>
        <Navbar className="navbar navbar-fixed-top" id="navbar">
          {navbar}
        </Navbar>
        {isImpersonating && (
          <div className="impersonateBox">
            <div style={{ marginTop: -5, marginRight: 10 }}>Impersonating:</div>
            <FormGroup controlId="formControlsSelect" className="change-user">
              <Select
                name="typeId"
                value={selectedUser ? selectedUser.id : null}
                onChange={this.selectUser}
                isLoading={staffUsers.fetching}
                options={options}
                onMenuScrollToBottom={this.loadMoreUsers}
              />
              <Button className="btn btn-danger" onClick={this.toggleUserSearchInput}>
                Cancel
              </Button>
            </FormGroup>
          </div>
        )}
        <div style={{ height: isImpersonating ? 110 : 55 }} />
      </div>
    );
  }
}

Header.propTypes = {
  auth: PropTypes.shape({
    loggedIn: PropTypes.func,
    getDomainsInformation: PropTypes.func,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  authentication: PropTypes.shape({
    user: PropTypes.object,
  }).isRequired,
  staffUsers: PropTypes.shape({
    fetching: PropTypes.bool,
    fetched: PropTypes.bool,
    results: PropTypes.array,
    error: PropTypes.string,
    pagination: PropTypes.object,
    busy: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  authentication: state.authentication,
  staffUsers: state.staffUsers,
});

export default connect(mapStateToProps)(Header);
