// default imports
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Form, FormGroup, ControlLabel, Button } from 'react-bootstrap';

// styling
import './InitialDomainConfiguration.css';

export class InitialDomainConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      domainName: '',
      domainNameError: '',
    };
    this.updateDomainName = this.updateDomainName.bind(this);
    this.validateInput = this.validateInput.bind(this);
  }

  updateDomainName(event) {
    this.setState({
      domainName: event.target.value,
      domainNameError: '',
    });
  }

  validateInput(event) {
    event.preventDefault();
    if (
      /^(?=.{1,253}\.?$)(?:(?!-|[^.]+_)[A-Za-z0-9-]{1,63}(?:\.|$)){2,}$/.test(
        this.state.domainName,
      ) &&
      !this.state.domainName.endsWith('-') &&
      this.state.domainName.indexOf('-.') === -1
    ) {
      const { domainName } = this.state;

      this.props.sendConfiguration(domainName);
    } else {
      this.setState({
        domainNameError: 'Invalid Domain Name.',
      });
    }
  }

  render() {
    return (
      <Form
        horizontal
        hidden={this.props.hideComponent}
        className="configure-first-domain text-center"
      >
        <FormGroup>
          <Col md={12}>
            <Col xs={12}>
              <FormGroup>
                <ControlLabel id="domain-name-label">
                  <input
                    name="domain-name"
                    value={this.state.domainName}
                    onChange={(e) => this.updateDomainName(e)}
                    className="field"
                    placeholder="dnsmonitor.com"
                  />
                  <span>
                    <span>Domain Name</span>
                  </span>
                </ControlLabel>
                <div className={this.props.domainSuccess ? 'success error-box' : 'error-box error'}>
                  {this.state.domainNameError
                    ? this.state.domainNameError
                    : this.props.domainSuccess}
                </div>
              </FormGroup>
            </Col>
          </Col>
        </FormGroup>
        <FormGroup>
          <Button
            type="submit"
            disabled={this.props.disabled}
            className="btn-success"
            onClick={(e) => {
              this.validateInput(e);
            }}
          >
            Configure
          </Button>
        </FormGroup>
      </Form>
    );
  }
}

InitialDomainConfiguration.propTypes = {
  disabled: PropTypes.bool,
  domainSuccess: PropTypes.bool.isRequired,
  hideComponent: PropTypes.bool,
  sendConfiguration: PropTypes.func.isRequired,
};

InitialDomainConfiguration.defaultProps = {
  disabled: false,
  hideComponent: false,
};

export default InitialDomainConfiguration;
