import * as types from 'Constants/actionTypes';

const initialSettings = {
  loading_invite_user: false,
  fetching: false,
  fetched: false,
  results: [],
  error: '',
  pagination: {},
  busy: false,
  loading_patch_invitation: false,
  deleting_invitation: false,
  resending_invitation: false,
};

const invitedUsers = (state = initialSettings, action) => {
  switch (action.type) {
    case types.INVITE_USER_INITIATED:
      return { ...state, loading_invite_user: true };
    case types.INVITE_USER_SUCCESS:
      return {
        ...state,
        loading_invite_user: false,
        results: [...state.results, action.invitation],
      };
    case types.INVITE_USER_ERROR:
      return { ...state, loading_invite_user: false };
    case types.FETCH_INVITED_USERS_INITIATED:
      return {
        ...state,
        fetching: true,
        fetched: false,
        busy: true,
        pagination: {},
      };
    case types.FETCH_INVITED_USERS_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        results: action.results,
        pagination: action.pagination,
        busy: false,
      };
    case types.FETCH_INVITED_USERS_ERROR:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.error,
      };
    case types.CHANGE_PAGE:
      return {
        ...state,
        pagination: { ...state.pagination, page: action.page },
      };
    case types.FETCH_NEXT_INIT:
      return {
        ...state,
        busy: true,
      };
    case types.FETCH_NEXT_SUCCESS:
      return {
        ...state,
        busy: false,
        results: [...state.results, ...action.results],
        pagination: {
          ...state.pagination,
          ...action.pagination,
        },
      };
    case types.FETCH_NEXT_ERROR:
      return {
        ...state,
        busy: false,
        error: action.error,
      };
    case types.PATCH_INVITATION_INITIATED:
      return { ...state, loading_patch_invitation: true };
    case types.PATCH_INVITATION_SUCCESS: {
      const index = state.results.findIndex((i) => i.id === action.invitation.id);
      const { results } = state;
      results.splice(index, 1, action.invitation);
      return {
        ...state,
        loading_patch_invitation: false,
        results,
      };
    }
    case types.PATCH_INVITATION_ERROR:
      return { ...state, loading_patch_invitation: false };
    case types.DELETE_INVITATION_INITIATED:
      return { ...state, deleting_invitation: true };
    case types.DELETE_INVITATION_SUCCESS:
      return {
        ...state,
        deleting_invitation: false,
        results: state.results.filter((i) => i.id !== action.id),
      };
    case types.DELETE_INVITATION_ERROR:
      return { ...state, deleting_invitation: false };
    case types.RESEND_INVITATION_INITIATED:
      return { ...state, resending_invitation: true };
    case types.RESEND_INVITATION_SUCCESS: {
      const { results } = state;
      const index = results.findIndex((i) => i.id === action.invitation.id);
      results.splice(index, 1, action.invitation);
      return {
        ...state,
        resending_invitation: false,
        results,
      };
    }
    case types.RESEND_INVITATION_ERROR:
      return { ...state, resending_invitation: false };
    case types.LOGOUT_USER:
      return { ...initialSettings };
    default:
      return state;
  }
};

export default invitedUsers;
