import * as types from 'Constants/actionTypes';
import * as paymentTypes from 'Constants/paymentTypes';
import AuthService from 'utils/AuthService';
import FetchService from 'utils/FetchService';
import StorageService from 'utils/StorageService';

let auth = new AuthService(
  process.env.REACT_APP_AUTH0_CLIENT_ID,
  process.env.REACT_APP_AUTH0_DOMAIN,
);

export function initAuth0() {
  return (dispatch) => {
    auth = new AuthService(
      process.env.REACT_APP_AUTH0_CLIENT_ID,
      process.env.REACT_APP_AUTH0_DOMAIN,
    );
    dispatch({ type: types.ADD_AUTHZERO, auth });
  };
}

export function paymentType(type) {
  switch (type) {
    case 'trialing':
      return paymentTypes.STRIPE_TRIAL;
    case 'active':
      return paymentTypes.STRIPE_ACTIVE;
    case 'past_due':
      return paymentTypes.STRIPE_PAST_DUE;
    case 'canceled':
      return paymentTypes.STRIPE_CANCELED;
    case 'unpaid':
      return paymentTypes.STRIPE_UNPAID;
    default:
      return undefined;
  }
}

export function login(username, password) {
  return (dispatch) => {
    dispatch({ type: types.LOGIN_INIT });
    auth.login(username, password, (message) => {
      if (message === 'success') {
        FetchService.getMe()
          .then((Response) => {
            dispatch({ type: types.USER_DATA, userData: Response });
            if (Response.manually_paying) {
              dispatch({
                type: types.LOGIN_SUCCESS,
                paymentType: paymentTypes.MANUALLY_PAYING_CUSTOMER,
              });
            } else if (Response.is_subscribed) {
              dispatch({ type: types.LOGIN_SUCCESS, paymentType: paymentTypes.STRIPE_ACTIVE });
            } else if (!Response.customer) {
              dispatch({ type: types.LOGIN_SUCCESS, paymentType: paymentTypes.NOT_A_CUSTOMER });
            } else if (Response.customer.delinquent) {
              dispatch({ type: types.LOGIN_SUCCESS, paymentType: paymentTypes.DELINQUENT });
            } else if (Response.has_free_domain) {
              dispatch({ type: types.LOGIN_SUCCESS, paymentType: paymentTypes.FREE_USER });
            } else {
              dispatch({ type: types.LOGIN_SUCCESS, paymentType: paymentTypes.NO_SUBSCRIPTION });
            }
          })
          .catch(() => {
            dispatch({ type: types.LOGIN_ERROR, error: { description: 'Unable to find user' } });
          });
      } else {
        dispatch({ type: types.LOGIN_ERROR, error: { description: message } });
      }
    });
  };
}

export function getMe() {
  return (dispatch) => {
    FetchService.getMe().then((Response) => {
      dispatch({ type: types.USER_DATA, userData: Response });
      if (Response.manually_paying) {
        dispatch({
          type: types.CHANGE_USER_PAYMENT,
          paymentType: paymentTypes.MANUALLY_PAYING_CUSTOMER,
        });
      } else if (Response.is_subscribed) {
        dispatch({ type: types.CHANGE_USER_PAYMENT, paymentType: paymentTypes.STRIPE_ACTIVE });
      } else if (!Response.customer) {
        dispatch({ type: types.CHANGE_USER_PAYMENT, paymentType: paymentTypes.NOT_A_CUSTOMER });
      } else if (Response.customer.delinquent) {
        dispatch({ type: types.CHANGE_USER_PAYMENT, paymentType: paymentTypes.DELINQUENT });
      } else if (Response.has_free_domain) {
        dispatch({ type: types.CHANGE_USER_PAYMENT, paymentType: paymentTypes.FREE_USER });
      } else {
        dispatch({ type: types.CHANGE_USER_PAYMENT, paymentType: paymentTypes.NO_SUBSCRIPTION });
      }
    });
  };
}

export function logout() {
  return (dispatch) => {
    localStorage.removeItem('id_token');
    localStorage.removeItem('domain_information');
    StorageService.setImpersonatedUser(null);
    dispatch({ type: types.LOGOUT_USER });
  };
}
