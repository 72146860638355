// default imports
import PropTypes from 'prop-types';
import React from 'react';

import DataTableCell from '../DataTableCell/DataTableCell';
// styling
import './DataTableRow.css';

function DataTableRow({
  currentConf,
  copyConf,
  currentData,
  showSerial,
  showMname,
  noChange,
  saveConfiguration,
  // eslint-disable-next-line no-unused-vars
  subscriptionType,
  utc,
}) {
  const configuration = currentConf;
  const data = currentData;
  const toggleBoolean = noChange || copyConf.udp_avail.cfg_status !== 'active';
  const udpStatus = copyConf.udp_avail.cfg_status !== 'active';
  return (
    <div className="tableRow">
      <div
        title={configuration.name}
        className="colMedium onClickCell columnStyle textLeft"
        style={{ textOverflow: 'ellipsis' }}
      >
        <DataTableCell
          configuration={configuration.udp_avail}
          data={data.udp_avail}
          displayData={configuration.name}
          copy={copyConf.udp_avail}
          no_change={noChange}
          no_click={!toggleBoolean}
          disabled={udpStatus}
          leftTableItem
          saveConfiguration={(item) => saveConfiguration({ ...copyConf, udp_avail: item })}
          utc={utc}
        />
      </div>
      <div
        title={configuration.ip}
        className="colMedium onClickCell columnStyle textLeft"
        style={{ textOverflow: 'ellipsis' }}
      >
        <DataTableCell
          configuration={configuration.udp_avail}
          data={data.udp_avail}
          displayData={configuration.ip}
          copy={copyConf.udp_avail}
          no_change={noChange}
          no_click={!toggleBoolean}
          disabled={udpStatus}
          leftTableItem
          saveConfiguration={(item) => saveConfiguration({ ...copyConf, udp_avail: item })}
          utc={utc}
        />
      </div>
      <div className="colXSmall2 onClickCell columnStyle">
        <DataTableCell
          configuration={configuration.r_time}
          data={data.r_time}
          displayData={data.r_time && data.r_time.data ? String(data.r_time.data) : null}
          copy={copyConf.r_time}
          no_change={toggleBoolean}
          disabled={udpStatus}
          leftTableItem
          saveConfiguration={(item) => saveConfiguration({ ...copyConf, r_time: item })}
          utc={utc}
        />
      </div>
      <div className="colXSmall2 columnStyle">
        <DataTableCell
          configuration={configuration.r_time}
          data={data.r_time}
          displayData={data.r_time && data.r_time.avg ? String(data.r_time.avg) : null}
          copy={copyConf.r_time}
          no_click
          no_change={toggleBoolean}
          disabled={udpStatus}
          leftTableItem
        />
      </div>
      <div className="colXSmall onClickCell columnStyle">
        <DataTableCell
          configuration={configuration.udp_avail}
          data={data.udp_avail}
          copy={copyConf.udp_avail}
          no_change={noChange}
          disabled={udpStatus}
          saveConfiguration={(item) => saveConfiguration({ ...copyConf, udp_avail: item })}
          utc={utc}
        />
      </div>
      <div className="colXSmall onClickCell columnStyle">
        <DataTableCell
          configuration={configuration.tcp_avail}
          data={data.tcp_avail}
          copy={copyConf.tcp_avail}
          no_change={toggleBoolean}
          disabled={udpStatus}
          saveConfiguration={(item) => saveConfiguration({ ...copyConf, tcp_avail: item })}
          utc={utc}
        />
      </div>
      {showSerial === 1 ? (
        <div className="colSmall onClickCell columnStyle">
          <DataTableCell
            configuration={configuration.serial}
            data={data.serial}
            displayData={data.serial.data ? String(data.serial.data) : null}
            copy={copyConf.serial}
            no_change={toggleBoolean}
            disabled={udpStatus}
            saveConfiguration={(item) => saveConfiguration({ ...copyConf, serial: item })}
            utc={utc}
            ssn
            leftTableItem
          />
        </div>
      ) : (
        <div className="colXSmall onClickCell columnStyle">
          <DataTableCell
            configuration={configuration.serial}
            data={data.serial}
            copy={copyConf.serial}
            no_change={toggleBoolean}
            disabled={udpStatus}
            saveConfiguration={(item) => saveConfiguration({ ...copyConf, serial: item })}
            utc={utc}
          />
        </div>
      )}
      {showMname === 1 ? (
        <div className="colMedium  onClickCell columnStyle">
          <DataTableCell
            configuration={configuration.mname}
            data={data.mname}
            displayData={String(data.mname.data)}
            copy={copyConf.mname}
            no_change={toggleBoolean}
            disabled={udpStatus}
            saveConfiguration={(item) => saveConfiguration({ ...copyConf, mname: item })}
            utc={utc}
            leftTableItem
          />
        </div>
      ) : (
        <div className="colXSmall onClickCell columnStyle">
          <DataTableCell
            configuration={configuration.mname}
            data={data.mname}
            copy={copyConf.mname}
            no_change={toggleBoolean}
            disabled={udpStatus}
            saveConfiguration={(item) => saveConfiguration({ ...copyConf, mname: item })}
            utc={utc}
          />
        </div>
      )}
      <div className="colXSmall onClickCell columnStyle">
        <DataTableCell
          configuration={configuration.rname}
          data={data.rname}
          copy={copyConf.rname}
          no_change={toggleBoolean}
          disabled={udpStatus}
          saveConfiguration={(item) => saveConfiguration({ ...copyConf, rname: item })}
          utc={utc}
        />
      </div>
      <div className="colXSmall onClickCell columnStyle">
        <DataTableCell
          configuration={configuration.sec_ver}
          data={data.sec_ver}
          copy={copyConf.sec_ver}
          no_change={toggleBoolean}
          disabled={udpStatus}
          saveConfiguration={(item) => saveConfiguration({ ...copyConf, sec_ver: item })}
          utc={utc}
        />
      </div>
      <div className="colXSmall onClickCell columnStyle">
        <DataTableCell
          configuration={configuration.sec_rec}
          data={data.sec_rec}
          copy={copyConf.sec_rec}
          no_change={toggleBoolean}
          disabled={udpStatus}
          saveConfiguration={(item) => saveConfiguration({ ...copyConf, sec_rec: item })}
          utc={utc}
        />
      </div>
      <div className="colXSmall onClickCell columnStyle">
        <DataTableCell
          configuration={configuration.sec_xfr}
          data={data.sec_xfr}
          copy={copyConf.sec_xfr}
          no_change={toggleBoolean}
          disabled={udpStatus}
          saveConfiguration={(item) => saveConfiguration({ ...copyConf, sec_xfr: item })}
          utc={utc}
        />
      </div>
      <div className="colXSmallASN onClickCell columnStyle">
        <DataTableCell
          configuration={configuration.asn_check}
          displayData={data.asn_check ? data.asn_check.data : ''}
          data={data.asn_check}
          copy={copyConf.asn_check}
          no_change={toggleBoolean}
          disabled={udpStatus}
          saveConfiguration={(item) => saveConfiguration({ ...copyConf, asn_check: item })}
          utc={utc}
          asn
        />
      </div>
      <div className="colXSmall onClickCell columnStyle">
        <DataTableCell
          configuration={configuration.host_val}
          data={data.host_val}
          copy={copyConf.host_val}
          no_change={toggleBoolean}
          disabled={udpStatus}
          saveConfiguration={(item) => saveConfiguration({ ...copyConf, host_val: item })}
          utc={utc}
        />
      </div>
      <div className="colXSmall onClickCell columnStyle">
        <DataTableCell
          configuration={configuration.host_ptr}
          data={data.host_ptr}
          copy={copyConf.host_ptr}
          no_change={toggleBoolean}
          disabled={udpStatus}
          saveConfiguration={(item) => saveConfiguration({ ...copyConf, host_ptr: item })}
          utc={utc}
        />
      </div>
    </div>
  );
}

DataTableRow.propTypes = {
  currentConf: PropTypes.shape().isRequired,
  currentData: PropTypes.shape().isRequired,
  copyConf: PropTypes.shape().isRequired,
  showSerial: PropTypes.number,
  showMname: PropTypes.number,
  noChange: PropTypes.bool.isRequired,
  saveConfiguration: PropTypes.func.isRequired,
};

DataTableRow.defaultProps = {
  showSerial: 0,
  showMname: 0,
};

export default DataTableRow;
