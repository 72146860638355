/* eslint-disable react/no-unused-class-component-methods */
import Auth0 from 'auth0-js';
import { Component } from 'react';

import { isTokenExpired } from './jwtHelper';

function setToken(idToken) {
  localStorage.setItem('id_token', idToken);
}

function getToken() {
  return localStorage.getItem('id_token');
}

function getDomainsInformation() {
  return localStorage.getItem('domain_information')
    ? JSON.parse(localStorage.getItem('domain_information'))
    : {};
}

function setDomainsInformation(info) {
  localStorage.setItem('domain_information', JSON.stringify(info));
}

function logout() {
  localStorage.removeItem('id_token');
  // localStorage.removeItem("profile");
  localStorage.removeItem('domain_information');
}

/**
 * Checks if a has a session token
 */
function loggedIn() {
  return !!getToken() && !isTokenExpired(getToken());
}

export default class AuthService extends Component {
  constructor(clientId, domain) {
    super();
    this.auth0 = new Auth0.WebAuth({
      clientID: clientId,
      domain,
      audience: process.env.REACT_APP_AUDIENCE,
    });
    this.domain = domain;
    this.login = this.login.bind(this);
    this.signup = this.signup.bind(this);
    this.getDomainsInformation = getDomainsInformation;
    this.setDomainsInformation = setDomainsInformation;
    this.logout = logout;
    this.loggedIn = loggedIn;
  }

  /**
   * Triggers an forgot password request in auth0 to the
   * corresponding @user. Returns result to @cb function.
   * @param {string} user
   * @param {callback function} cb
   */
  forgotPassword(user, cb) {
    this.auth0.changePassword(
      { email: user, connection: 'Username-Password-Authentication' },
      (err, response) => {
        cb(response);
      },
    );
  }

  /**
   *
   * @param {Object {username, password, user_metadata}} params
   * @param {callback function} onError
   */
  signup(params, onError) {
    this.auth0.signup(params, onError);
  }

  /**
   * Login user with @email and @pass to auth0 and
   * retreieve the user profile. Sends result to @cb function
   * @param {string} email
   * @param {string} pass
   * @param {callback function} cb
   */
  login(email, pass, cb) {
    this.auth0.client.login(
      {
        realm: 'Username-Password-Authentication',
        username: email,
        password: pass,
        scope: 'openid profile email',
        audience: process.env.REACT_APP_AUDIENCE,
      },
      (err, authResult) => {
        if (err) {
          cb(err);
        } else {
          setToken(authResult.accessToken);
          cb('success');
        }
      },
    );
  }
}
