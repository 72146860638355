// default imports
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Button } from 'react-bootstrap';

import './PaginationButton.css';

export class PaginationButton extends PureComponent {
  render() {
    return (
      <Button
        className="pagination-button btn-primary"
        onClick={this.props.onClick}
        disabled={this.props.disabled}
      >
        {this.props.symbol}
      </Button>
    );
  }
}
PaginationButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  symbol: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  disabled: PropTypes.bool,
};

PaginationButton.defaultProps = {
  disabled: false,
};

export default PaginationButton;
