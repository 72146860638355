// default imports
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// custom imports
import PaginationButton from '../../../../Components/PaginationButton/PaginationButton';
// style imports
import './SearchResults.css';

export class SearchResults extends PureComponent {
  static setAvailInteg(severity) {
    switch (severity) {
      case 0:
        return <div className="severityCircle zero" />;
      case 1:
        return <div className="severityCircle one" />;
      case 2:
        return <div className="severityCircle two" />;
      case 3:
        return <div className="severityCircle three" />;
      case 4:
        return <div className="severityCircle four" />;
      default:
        return <div className="severityCircle temp" />;
    }
  }

  constructor(props) {
    super(props);
    this.getRows = this.getRows.bind(this);
  }

  getRows() {
    const startIndex = this.props.page * 25;
    const pageContent = this.props.results.slice(startIndex, startIndex + 25);
    return pageContent.map((item) => {
      const availMsg = item.availability.rc > 0 ? `A: ${item.availability.msg}` : '';
      const integlMsg = item.integrity.rc > 0 ? `I: ${item.integrity.msg}` : '';
      return (
        <tr key={item.id}>
          <td>
            {item.date_executed ? moment(item.date_executed).format('YYYY-MM-DD HH:mm:ss') : '-'}
          </td>
          <td className="center-row">{SearchResults.setAvailInteg(item.availability.rc)}</td>
          <td className="center-row">{SearchResults.setAvailInteg(item.integrity.rc)}</td>
          <td>{item.configuration.domain}</td>
          <td>{`${item.configuration.country}, ${item.configuration.city_or_region}`}</td>
          <td>
            {availMsg}
            {availMsg ? <br /> : ''}
            {integlMsg}{' '}
          </td>
          <td className="center-row">
            {' '}
            <Link to={`history/${item.id}`}>View</Link>
          </td>
        </tr>
      );
    });
  }

  render() {
    const { page, results, busy } = this.props;
    if (!results.length) {
      return (
        <Row id="SearchResults">
          <div className="pagination">No Available results</div>
        </Row>
      );
    }
    return (
      <Row id="SearchResults">
        <Table striped bordered condensed hover>
          <thead>
            <tr>
              <th style={{ width: '144px' }}>Time</th>
              <th className="center-row">Availability</th>
              <th className="center-row">Integrity</th>
              <th>Domain</th>
              <th>Location</th>
              <th>Message</th>
              <th />
            </tr>
          </thead>
          <tbody>{this.getRows()}</tbody>
        </Table>

        <div className="pagination">
          <PaginationButton
            symbol="&#8592;"
            disabled={page === 0}
            onClick={() => this.props.changePagination(-1)}
          />
          <PaginationButton
            symbol={
              busy && page + 1 >= results.length / 25 ? (
                <i className="fas fa-spinner fa-spin" />
              ) : (
                <span>&#8594;</span>
              )
            }
            disabled={page + 1 >= results.length / 25}
            onClick={() => this.props.changePagination(1)}
          />
          <div>{page + 1}</div>
        </div>
      </Row>
    );
  }
}

SearchResults.propTypes = {
  results: PropTypes.arrayOf(PropTypes.object),
  page: PropTypes.number,
  changePagination: PropTypes.func.isRequired,
  busy: PropTypes.bool,
};

SearchResults.defaultProps = {
  results: [],
  page: 0,
  busy: false,
};

export default SearchResults;
