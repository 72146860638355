import * as types from 'Constants/actionTypes';

const initialSettings = {
  in_progress: false,
  errors: {},
  authenticated: false,
  auth: {},
  user: {},
  authServiceCreated: false,
  payment: undefined,
  payment_stripe: undefined,
  userFetched: false,
  paymentFetched: false,
};

const authentication = (state = initialSettings, action) => {
  switch (action.type) {
    case types.LOGIN_INIT:
      return { ...state, fetching: true };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        fetching: false,
        authenticated: true,
        errors: {},
        payment: action.paymentType,
        paymentFetched: true,
      };
    case types.LOGIN_ERROR:
      return {
        ...state,
        fetching: false,
        errors: action.error,
        authenticated: false,
      };
    case types.ADD_AUTHZERO:
      return {
        ...state,
        auth: action.auth,
        authServiceCreated: true,
        authenticated: action.auth.loggedIn(),
      };
    case types.LOGOUT_USER:
      return { ...initialSettings, auth: state.auth, authServiceCreated: state.authServiceCreated };
    case types.USER_IS_AUTHENTICATED:
      return { ...state, authenticated: true, in_progress: false };
    case types.USER_IS_NOT_AUTHENTICATED:
      return { ...state, authenticated: false, in_progress: false };
    case types.GET_PAYMENT_TYPE:
      return { ...state, payment_stripe: action.paymentType, paymentFetched: true };
    case types.USER_DATA:
      return { ...state, user: action.userData };
    case types.CHANGE_USER_PAYMENT:
      return { ...state, payment: action.paymentType, userFetched: true };
    default:
      return state;
  }
};

export default authentication;
