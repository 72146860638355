import {
  SUBSCRIPTION_PREMIUM,
  SUBSCRIPTION_BASIC,
  SUBSCRIPTION_FREE,
} from 'Constants/subscriptionTypes';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';

class SubscriptionBox extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showInfoModal: false,
    };
  }

  getDefaultHosts = (subscriptionType) => {
    if (subscriptionType === SUBSCRIPTION_PREMIUM) {
      return 5;
    }
    if (subscriptionType === SUBSCRIPTION_BASIC) {
      return 1;
    }
    return 0;
  };

  updateDomainSubscriptionType = (event, domain) => {
    const currentSubType = domain.subscription_type;
    const selectedSubType = Number(event.target.value);
    this.props.setChangeType(currentSubType, selectedSubType);
    this.props.selectDomain(domain, selectedSubType);
  };

  domainAdditionalHosts = (domain) => {
    const totalHosts = this.props.resourceRecords.length
      ? this.props.resourceRecords.filter((rec) => rec.domain === domain.id).length
      : 0;
    return Math.max(totalHosts - this.getDefaultHosts(domain.subscription_type), 0);
  };

  openInfoModal = () => {
    this.setState({
      showInfoModal: true,
    });
  };

  closeInfoModal = () => {
    this.setState({
      showInfoModal: false,
    });
  };

  render() {
    const domains = [];
    const defaultLocationAmount = 3;

    if (
      this.props.domainStore &&
      this.props.domainStore.domains &&
      this.props.domainStore.domains.results
    ) {
      this.props.domainStore.domains.results.forEach((domain) => {
        domains.push(
          <Row key={domain.id} className="domain-row">
            <Col xs={3}>{domain.name}</Col>
            <Col xs={3}>{domain.locations.length - defaultLocationAmount}</Col>
            <Col xs={3}>{this.domainAdditionalHosts(domain)}</Col>
            <Col className="sub-type-col" xs={3}>
              <select
                value={domain.subscription_type}
                onChange={(event) => this.updateDomainSubscriptionType(event, domain)}
              >
                <option value={SUBSCRIPTION_PREMIUM}>Premium</option>
                <option value={SUBSCRIPTION_BASIC}>Basic</option>
                <option value={SUBSCRIPTION_FREE} disabled={this.props.hasFree}>
                  Free
                </option>
              </select>
            </Col>
          </Row>,
        );
      });
    }

    return (
      <Row className="subscriptionBox">
        <Col>
          <Row className="subscription-header">
            <Col xs={4} lg={5}>
              Subscriptions
            </Col>
            <Col className="email-col">
              {this.props.userEmail}
              <i
                className=" fas fa-info-circle information-icon-white"
                onClick={this.openInfoModal}
                role="button"
                onKeyPress={this.openInfoModal}
                tabIndex="0"
              />
            </Col>
          </Row>
          <Row className="subscription-content">
            <Row className="domain-labels">
              <Col xs={3}>Domain</Col>
              <Col xs={3}>Additional Locations</Col>
              <Col xs={3}>Additional Host records</Col>
              <Col className="sub-type-col" xs={3}>
                Subscription type
              </Col>
            </Row>
            {domains}
          </Row>
        </Col>
        <Modal
          show={this.state.showInfoModal}
          onHide={this.closeInfoModal}
          className="information-modal"
        >
          <div className="modal-container">
            <div className="modal-text">
              <ul className="list-guide">
                <li className="header-list">
                  <b>Subscriptions</b>
                </li>
                <li>
                  Change subscription type for individual domains.
                  <br />
                  <b>Note 1</b>: When performing a downgrade, i.e. from a Premium subscription to
                  Basic subscription, the configuration changes will take effect immediately for the
                  selected domain. The subscription fee will be adjusted in your next invoice.
                  <br />
                </li>
                <li>
                  <b>Note 2</b>: When upgrading a domain, the configuration will take effect
                  immediately and the cost is adjusted for the time remaining until the next billing
                  date.
                </li>
              </ul>
            </div>
            <div className="modal-buttons">
              <Button onClick={this.closeInfoModal}>Close</Button>
            </div>
          </div>
        </Modal>
        <Modal
          show={this.props.showConfirmModal}
          onHide={this.props.closeConfirmModal}
          className="sub-confirm-modal"
        >
          <div className="modal-container">
            <div className="modal-text">
              {this.props.changeType === 'upgrade' ? (
                <div>
                  You have opted to upgrade your DNSmonitor service to a
                  {this.props.selectedDomain.subscription_type === SUBSCRIPTION_PREMIUM
                    ? ' Premium '
                    : ' Basic '}
                  subscription for the domain <b>{this.props.selectedDomain.name}</b>.<br />
                  Your account will be charged the difference for the remaining period.
                </div>
              ) : this.props.changeType === 'downgrade' ? (
                <div>
                  You have opted to downgrade your DNSmonitor service to a
                  {this.props.selectedDomain.subscription_type === SUBSCRIPTION_BASIC
                    ? ' Basic '
                    : ' Free '}
                  subscription for the domain <b>{this.props.selectedDomain.name}</b>.<br />
                  The configuration will take effect immediately and you will be billed according to
                  the new service at the next payment date.
                  <br />
                  The former subscription will be marked as unallocated until the next payment.
                  <br />
                  {this.props.selectedDomain.subscription_type === SUBSCRIPTION_BASIC ? (
                    <i className="modal-warning-text">
                      Downgrading this domain will remove all host records.
                    </i>
                  ) : (
                    <i className="modal-warning-text">
                      Downgrading this domain will remove all host records as free subscriptions
                      cannot allocate host records.
                    </i>
                  )}
                </div>
              ) : (
                ''
              )}
            </div>
            <Row className="button-row">
              <Col xs={6}>
                <Button
                  className="btn-success confirm-button"
                  onClick={() => {
                    this.props.handleSubscriptionChange();
                    this.props.closeConfirmModal();
                  }}
                >
                  Confirm
                </Button>
              </Col>
              <Col xs={6}>
                <Button className="btn-danger cancel-button" onClick={this.props.closeConfirmModal}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </div>
        </Modal>
      </Row>
    );
  }
}

SubscriptionBox.propTypes = {
  domainStore: PropTypes.shape({
    selected_domain: PropTypes.object,
    fetching: PropTypes.bool,
    reconfigure_in_progress: PropTypes.bool,
    fetched: PropTypes.bool,
    domains: PropTypes.object,
    reconfigure_errors: PropTypes.string.isRequired,
    delete_in_progress: PropTypes.bool,
  }).isRequired,
  handleSubscriptionChange: PropTypes.func.isRequired,
  resourceRecords: PropTypes.array.isRequired,
  userEmail: PropTypes.string.isRequired,
  hasFree: PropTypes.bool.isRequired,
  showConfirmModal: PropTypes.bool,
  closeConfirmModal: PropTypes.func.isRequired,
  changeType: PropTypes.string,
  selectDomain: PropTypes.func.isRequired,
  selectedDomain: PropTypes.object,
};

SubscriptionBox.defaultProps = {
  domainStore: {
    fetched: false,
    domains: {},
  },
};

export default SubscriptionBox;
