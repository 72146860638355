// default imports
import Switch from 'Components/Switch/Switch';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { connect } from 'react-redux';
import SymbolHelper from 'utils/SymbolHelper';

import StorageService from '../../../../utils/StorageService';
import './DomainCheck.css';

function getIcon(rc, status) {
  let symbol = '';
  let color = '';

  color = SymbolHelper.setColor(rc);
  symbol = SymbolHelper.setGlyph(rc);
  color = SymbolHelper.setActive(status, color);
  return <i className={`fas ${symbol}`} style={{ color }} />;
}

export class DomainCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    this.toggleCellMenu = this.toggleCellMenu.bind(this);
  }

  changeConfiguration() {
    this.props.copy.cfg_status = this.props.copy.cfg_status !== 'active' ? 'active' : 'disabled';
    this.forceUpdate();
    this.props.saveConfiguration(this.props.copy);
  }

  toggleCellMenu() {
    this.setState({
      show: !this.state.show,
    });
  }

  render() {
    const { configuration, data, displayData, checkInfo, noChange, busy, utc } = this.props;
    const { user } = this.props.authentication;
    const icon =
      data && configuration.cfg_status === 'active'
        ? getIcon(data.rc, configuration.cfg_status)
        : getIcon(4, configuration.cfg_status);
    const day = utc ? moment(utc).format('YYYY-MM-DD') : 'Invalid date';
    const time = utc ? moment(utc).format('HH:mm:ss') : 'Invalid Time';
    const isReadOnlyMode = user.is_user || StorageService.getReadOnlyMode();
    return (
      <div className="domainCheck">
        <OverlayTrigger
          placement="left"
          overlay={
            checkInfo ? (
              <Tooltip id="tooltip">
                <strong>{checkInfo}</strong>
              </Tooltip>
            ) : (
              <Tooltip id="tooltip" style={{ display: 'none' }}>
                <strong>{checkInfo}</strong>
              </Tooltip>
            )
          }
        >
          <div
            className="domainCheckHeaderContainer"
            onClick={(event) => this.toggleCellMenu(event)}
            role="button"
            tabIndex="0"
            onKeyPress={(event) => this.toggleCellMenu(event)}
          >
            {icon}
            <span className="domainCheckHeader">{displayData}</span>
          </div>
        </OverlayTrigger>
        <div className="CellMenuDomain" style={this.state.show ? {} : { display: 'none' }}>
          <div className="CellMenuHeader">
            <p>{day}</p>
            <p>{time}</p>
          </div>
          <div className="CellMenuBody">
            <p className="CellMenuMessage">{data ? data.msg : 'No data Available'}</p>
            {noChange || isReadOnlyMode ? (
              ''
            ) : (
              <div className="disableEnableToggle">
                <div className="toggler">
                  <Switch
                    disabled={busy}
                    onClick={() => this.changeConfiguration()}
                    on={this.props.copy.cfg_status === 'active'}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

DomainCheck.propTypes = {
  copy: PropTypes.shape({
    cfg_status: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    rc: PropTypes.number,
  }),
  configuration: PropTypes.shape().isRequired,
  displayData: PropTypes.string,
  checkInfo: PropTypes.string,
  noChange: PropTypes.bool.isRequired,
  saveConfiguration: PropTypes.func,
  busy: PropTypes.bool,
  authentication: PropTypes.shape({
    user: PropTypes.object,
  }).isRequired,
};

DomainCheck.defaultProps = {
  displayData: '',
  checkInfo: '',
  data: undefined,
  busy: false,
  saveConfiguration: () => {},
};

const mapStateToProps = (state) => ({
  authentication: state.authentication,
});

export default connect(mapStateToProps)(DomainCheck);
