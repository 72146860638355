// default imports
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Row, Col, Modal } from 'react-bootstrap';
import FetchService from 'utils/FetchService';

// custom components
// styling
import './Pagerduty.css';

const prettyPrintAlertLevel = {
  error: 'Error',
  warning: 'Warning',
  critical: 'Critical',
  no_alert: 'No Alert',
};

const PAGERDUTY_URL =
  'https://app.pagerduty.com/install/integration?app_id=P8NUF12&redirect_url=http%3A%2F%2Fdashboard.dnsmonitor.com%2Fconnect&version=2';

export class Pagerduty extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showModal: false,
      showEditModal: false,
      serviceName: '',
      errorMessage: '',
      addProcessSymbol: '',
      disabledButtons: false,
    };
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.generateConnectedDomains = this.generateConnectedDomains.bind(this);
    this.removeService = this.removeService.bind(this);
  }

  getPagerDutyConf() {
    if (this.props.information.count) {
      const serviceList = [];
      this.props.information.results.forEach((service) => {
        const connectedDomains = [];
        this.props.alertRules.forEach((rule) => {
          if (rule.service_key === service.service_key) {
            connectedDomains.push(rule);
          }
        });
        serviceList.push(
          <Col
            xs={12}
            title="View Service"
            key={service.service_key}
            onClick={() =>
              this.open('specific', service.service_name, connectedDomains, service.service_key)
            }
            className="domain-box"
          >
            <Col xs={4} className="box-line">
              <div className="box-sub-header">Service Name</div>
              <div className="box-sub-text">{service.service_name}</div>
            </Col>
            <Col xs={4}>
              <div className="box-sub-header">Account</div>
              <div className="box-sub-text">{service.account}</div>
            </Col>
            <Col xs={3}>
              <div className="box-sub-header">Usage</div>
              <div className="box-sub-text">{connectedDomains.length}</div>
            </Col>
            <Col xs={1}>
              <div className="box-sub-header">
                <i className="fas fa-pencil edit-service-icon" />
              </div>
            </Col>
          </Col>,
        );
      });
      serviceList.push(
        <Col xs={12} key="add_button" className="alert-buttons">
          <div>
            <i className={this.state.addProcessSymbol} />
          </div>
          <Button className="btn btn-primary add-service-button" href={PAGERDUTY_URL}>
            Add Service
          </Button>
        </Col>,
      );
      return (
        <Row>
          <Col xs={12} className="pagerduty-services">
            {serviceList}
          </Col>
        </Row>
      );
    }

    return (
      <Row>
        <Col xs={12}>
          <Row>
            <Col xs={12} className="pagerduty-text no-rules">
              You can manage your alerts with your PagerDuty account. <br />
              Click the button below to integrate with PagerDuty.
            </Col>
            <Col xs={12} className="pagerduty-image">
              <a href={PAGERDUTY_URL}>
                <img
                  alt=""
                  src="https://pagerduty.com/wp-content/uploads/integration-guide-assets-v1/pd_connect_button.png"
                />
              </a>
            </Col>
          </Row>
          <Row className="error-message">
            <Col xs={10}>{this.props.errorMessage}</Col>
          </Row>
        </Col>
      </Row>
    );
  }

  open(modalType, name = '', key = '') {
    switch (modalType) {
      case 'information':
        this.setState({
          showModal: true,
        });
        break;
      case 'specific':
        this.setState({
          showEditModal: true,
          serviceName: name || 'Service',
          serviceKey: key,
          errorMessage: '',
          disabledButtons: false,
          addProcessSymbol: '',
        });
        break;
      default:
        break;
    }
  }

  close() {
    this.setState({
      showModal: false,
      showEditModal: false,
    });
  }

  generateConnectedDomains() {
    const list = [];
    if (this.props.alertRules.length) {
      this.props.alertRules.forEach((rule, index) => {
        list.push(
          <Row
            key={rule.id}
            style={index % 2 !== 0 ? { backgroundColor: 'rgba(236, 238, 240, 0.52)' } : {}}
            onClick={() => {
              this.close();
              this.props.displayDomain(rule.domain);
            }}
            title="View Domain"
          >
            <Col xs={4}>{rule.domain}</Col>
            <Col xs={4}>{prettyPrintAlertLevel[rule.availability_level]}</Col>
            <Col xs={3}>{prettyPrintAlertLevel[rule.integrity_level]}</Col>
            <Col xs={1}>
              <i className="fas fa-pencil view-domain-icon" />
            </Col>
          </Row>,
        );
      });
    }
    return list;
  }

  removeService() {
    this.setState({
      errorMessage: '',
      disabledButtons: true,
      addProcessSymbol: 'fas fa-spin fa-spinner in-progress',
    });
    FetchService.deleteService(this.state.serviceKey)
      .then(() => {
        this.setState({
          addProcessSymbol: 'fas fa-check success',
          errorMessage: '',
        });
        this.props.listUpdated();
        this.close();
      })
      .catch(() => {
        this.setState({
          errorMessage: "Service couldn't be removed",
          disabledButtons: false,
          addProcessSymbol: 'fas fa-times error',
        });
      });
  }

  render() {
    const pagerduty = this.getPagerDutyConf();
    return (
      <div>
        <Col xs={12} className="sub-config-header">
          PagerDuty Integration
          <i
            className=" fas fa-info-circle information-icon-white"
            onClick={() => this.open('information')}
            role="button"
            onKeyPress={() => this.open('information')}
            tabIndex="0"
          />
        </Col>
        <Modal show={this.state.showModal} onHide={this.close} className="information-modal">
          <div className="modal-container">
            <div className="modal-text">
              <ul className="list-guide">
                <li className="header-list">
                  <b>What is PagerDuty?</b>
                </li>
                <li>
                  PagerDuty is an incident management platform that provides reliable incident
                  notifications via email, push, SMS, and phone, as well as automatic escalations,
                  on-call scheduling, and other functionality to help teams detect and fix
                  infrastructure problems quickly. You can read more about PagerDuty on their
                  <a
                    className="term-link"
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.pagerduty.com/"
                  >
                    <b>&nbsp;web page</b>
                  </a>
                  .
                </li>
              </ul>
            </div>
            <div className="modal-buttons">
              <Button onClick={this.close}>Close</Button>
            </div>
          </div>
        </Modal>
        <Col xs={12} className="config-content config-alert">
          {pagerduty}
        </Col>
        <Modal show={this.state.showEditModal} onHide={this.close} className="information-modal">
          <Row>
            <Col xs={12} className="sub-config-header">
              {this.state.serviceName}
              <i
                className="fas fa-times edit-modal-icon"
                onClick={this.close}
                role="button"
                onKeyPress={this.close}
                tabIndex="-1"
              />
            </Col>
            <Col xs={12} className="modal-edit-content">
              <Col xs={12} className="table-header">
                <Col xs={4}>Name</Col>
                <Col xs={4}>Availbility Level</Col>
                <Col xs={4}>Integrity Level</Col>
              </Col>
              <Col xs={12} className="table-body">
                {this.generateConnectedDomains()}
              </Col>
              <Row>
                <Col xs={12} className="modal-edit-buttons-no-border">
                  <div className="error error-box">
                    {this.state.errorMessage} <i className={this.state.addProcessSymbol} />
                  </div>
                  <Button
                    onClick={this.removeService}
                    disabled={this.state.disabledButtons}
                    className="btn btn-danger"
                  >
                    Delete
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

Pagerduty.propTypes = {
  listUpdated: PropTypes.func.isRequired,
  displayDomain: PropTypes.func.isRequired,
  alertRules: PropTypes.arrayOf(PropTypes.object).isRequired,
  errorMessage: PropTypes.string,
  information: PropTypes.shape({
    count: PropTypes.number,
    results: PropTypes.array,
  }).isRequired,
};

Pagerduty.defaultProps = {
  errorMessage: '',
};

export default Pagerduty;
