import * as types from 'Constants/actionTypes';
import FetchService from 'utils/FetchService';

export function getDetailedHistoryData(id) {
  return (dispatch) => {
    dispatch({ type: types.HISTORY_DETAILED_INIT });
    FetchService.getDetailedHistory(id).then((response) => {
      if (response.error) dispatch({ type: types.HISTORY_DETAILED_ERROR, error: response.error });
      const parsedObject = {
        ...response,
        host: response.configuration.host,
        data: {
          dns: response.dns,
          domain_check: response.domain_check,
        },
        dns: response.configuration.dns,
        domain_check: response.configuration.domain_check,
        copy_conf: {
          dns: response.configuration.dns,
          domain_check: response.configuration.domain_check,
          config_id: response.configuration.config_id,
        },
        record_data: response.configuration.record_data,
      };

      dispatch({ type: types.HISTORY_DETAILED_SUCCESS, data: parsedObject });
    });
  };
}

export default {
  getDetailedHistoryData,
};
