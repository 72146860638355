import DataTableCell from 'Components/DataTable/Components/DataTableCell/DataTableCell';
import PropTypes from 'prop-types';
import React from 'react';

// styling
import './RecourceRecordRow.css';

function ResourceRecordRow({ domainName, recordData, hostConf, copyConf, saveConfiguration, utc }) {
  return (
    <div className="tableRow">
      <div
        title={`${hostConf.name && `${hostConf.name}.`}${domainName}`}
        className="colMedium columnStyle resourceRecordNameCell"
      >
        {hostConf.name && `${hostConf.name}.`}
        {domainName}
      </div>
      <div title={hostConf.ip} className="colMedium columnStyle resourceRecordNameCell">
        {hostConf.ip}
      </div>
      <div className="colXSmall onClickCell columnStyle">
        <DataTableCell
          configuration={hostConf.host_val}
          data={recordData.host_val}
          copy={copyConf.host_val}
          disabled={hostConf.host_val.cfg_status !== 'active'}
          no_change={false}
          no_click={false}
          saveConfiguration={(item) => saveConfiguration({ ...copyConf, host_val: item })}
          utc={utc}
        />
      </div>
      <div className="colXSmall onClickCell columnStyle">
        <DataTableCell
          configuration={hostConf.host_ptr}
          data={recordData.host_ptr}
          copy={copyConf.host_ptr}
          disabled={hostConf.host_ptr.cfg_status !== 'active'}
          no_change={false}
          displayData={hostConf.host_ptr.cfg_status !== 'unavailable' ? null : 'N/A'}
          no_click={false}
          saveConfiguration={(item) => saveConfiguration({ ...copyConf, host_ptr: item })}
          utc={utc}
        />
      </div>
    </div>
  );
}

ResourceRecordRow.propTypes = {
  recordData: PropTypes.shape().isRequired,
  domainName: PropTypes.string.isRequired,
  hostConf: PropTypes.shape().isRequired,
  saveConfiguration: PropTypes.func.isRequired,
  copyConf: PropTypes.shape().isRequired,
};

export default ResourceRecordRow;
