import * as types from 'Constants/actionTypes';

const initialSettings = {
  fetching: false,
  plans_fetched: false,
  errors: {},
  locations: {},
  plans: [],
  auth: {},
};

const init = (state = initialSettings, action) => {
  switch (action.type) {
    case types.INITIATE_DATA:
      return { ...state, auth: action.auth };
    case types.FETCH_PLANS_INIT:
      return { ...state, fetching: true };
    case types.FETCH_PLANS_SUCCESS:
      return {
        ...state,
        fetching: false,
        plans_fetched: true,
        plans: action.Response,
      };
    case types.FETCH_PLANS_ERROR:
      return {
        ...state,
        fetching: false,
        plans_fetched: false,
        errors: action.Response.error,
      };
    default:
      return state;
  }
};

export default init;
