// default imports
import ResourceRecordRow from 'Components/ResourceRecordRow/ResourceRecordRow';
// custom components
import checkInformation from 'Constants/check_information.json';
import { SUBSCRIPTION_PREMIUM } from 'Constants/subscriptionTypes';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import DataTableRow from './Components/DataTableRow/DataTableRow';
import DomainCheck from './Components/DomainCheck/DomainCheck';
// styling
import './DataTable.css';

function DataTable({
  locationConfig,
  copyConf,
  domainName,
  noChange,
  displayInformation,
  saveConfiguration,
  busy,
  subscriptionType,
}) {
  const NameServers = [];
  const showSerial = parseInt(locationConfig.data.show_serial, 10);
  const showMname = parseInt(locationConfig.data.show_mname, 10);
  if (subscriptionType === SUBSCRIPTION_PREMIUM) {
    Object.keys(locationConfig.dns).forEach((index) => {
      const currentConf = locationConfig.dns[index];
      const currentData =
        locationConfig.data && locationConfig.data.dns ? locationConfig.data.dns[index] : {};
      let copyObject = {};
      if (copyConf.dns) {
        _.forOwn(copyConf.dns, (value) => {
          if (value.ip === currentConf.ip) {
            copyObject = value;
          }
        });
      }
      if (currentData !== undefined && currentConf !== undefined) {
        NameServers.push(
          <DataTableRow
            key={currentConf.ip}
            currentConf={currentConf}
            copyConf={copyObject}
            currentData={currentData}
            showSerial={showSerial}
            showMname={showMname}
            noChange={noChange}
            subscriptionType={subscriptionType}
            utc={locationConfig.data.date_executed}
            saveConfiguration={(item) => {
              saveConfiguration(
                {
                  ...copyConf,
                  dns: {
                    ..._.forOwn(copyConf.dns, (value) => {
                      if (value.ip === currentConf.ip) {
                        return item;
                      }
                      return value;
                    }),
                  },
                },
                copyConf,
              );
            }}
          />,
        );
      }
    });
  }
  const Hosts = [];
  _.forOwn(_.sortBy(locationConfig.host, 'name'), (val) => {
    const currentConf = val;
    let currentData = {};
    if (locationConfig.record_data) {
      _.forOwn(locationConfig.record_data, (value) => {
        if (value.host_name === val.name) {
          currentData = value;
        }
      });
    }
    let copyObject = {};
    if (copyConf.host) {
      _.forOwn(copyConf.host, (value) => {
        if (value.ip === currentConf.ip) {
          copyObject = value;
        }
      });
    }
    if (currentData !== undefined && currentConf !== undefined) {
      Hosts.push(
        <ResourceRecordRow
          key={currentConf.host_id}
          utc={locationConfig.data.date_executed}
          domainName={domainName}
          recordData={currentData}
          hostConf={currentConf}
          copyConf={copyObject}
          saveConfiguration={(item) => {
            saveConfiguration(
              {
                ...copyConf,
                host: {
                  ..._.forOwn(copyConf.host, (value) => {
                    if (value.ip === currentConf.ip) {
                      return item;
                    }
                    return value;
                  }),
                },
              },
              copyConf,
            );
          }}
        />,
      );
    }
  });
  let bottomContainerWidth = 1125;
  if (showSerial) {
    bottomContainerWidth += 50;
  }
  if (showMname) {
    bottomContainerWidth += 150;
  }
  if (subscriptionType === SUBSCRIPTION_PREMIUM) {
    return (
      <div>
        {locationConfig.data.dns ? (
          ''
        ) : (
          <div className="noData">
            New configuration data is loading <i className="fas fa-spinner fa-spin fa-fw" />
          </div>
        )}
        <div className="nameServerTable">
          {subscriptionType === SUBSCRIPTION_PREMIUM && (
            <div>
              <div className="tableRow">
                <div className="colLarge colHeader tableCol columnStyle">Name server</div>
                <div
                  className="colSmall2 colHeader tableCol columnStyle"
                  title="Response time is shown in milliseconds"
                >
                  Response time
                </div>
              </div>
              <div className="tableRow">
                <div className="colMedium colHeader tableCol columnStyle">Name</div>
                <div className="colMedium colHeader tableCol columnStyle">IP address</div>
                <OverlayTrigger
                  placement="left"
                  overlay={
                    displayInformation ? (
                      <Tooltip id="tooltip">
                        <strong>{checkInformation.r_time}</strong>
                      </Tooltip>
                    ) : (
                      <Tooltip id="tooltip" style={{ display: 'none' }} />
                    )
                  }
                >
                  <div className="colXSmall2 colHeader tableCol columnStyle">Last.</div>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="left"
                  overlay={
                    displayInformation ? (
                      <Tooltip id="tooltip">
                        <strong>{checkInformation.r_time_avg}</strong>
                      </Tooltip>
                    ) : (
                      <Tooltip id="tooltip" style={{ display: 'none' }} />
                    )
                  }
                >
                  <div className="colXSmall2 colHeader tableCol columnStyle">Avg.</div>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="left"
                  overlay={
                    displayInformation ? (
                      <Tooltip id="tooltip">
                        <strong>{checkInformation.udp_avail}</strong>
                      </Tooltip>
                    ) : (
                      <Tooltip id="tooltip" style={{ display: 'none' }} />
                    )
                  }
                >
                  <div className="colXSmall colHeaderTilted">
                    <p>UDP Availability</p>
                  </div>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="left"
                  overlay={
                    displayInformation ? (
                      <Tooltip id="tooltip">
                        <strong>{checkInformation.tcp_avail}</strong>
                      </Tooltip>
                    ) : (
                      <Tooltip id="tooltip" style={{ display: 'none' }} />
                    )
                  }
                >
                  <div className="colXSmall colHeaderTilted">
                    <p>TCP Availability</p>
                  </div>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="left"
                  overlay={
                    displayInformation ? (
                      <Tooltip id="tooltip">
                        <strong>{checkInformation.serial}</strong>
                      </Tooltip>
                    ) : (
                      <Tooltip id="tooltip" style={{ display: 'none' }} />
                    )
                  }
                >
                  {showSerial === 1 ? (
                    <div className="colSmall colHeaderTilted">
                      <p>Serial Number Validation</p>
                    </div>
                  ) : (
                    <div className="colXSmall colHeaderTilted">
                      <p>Serial Number Validation</p>
                    </div>
                  )}
                </OverlayTrigger>
                <OverlayTrigger
                  placement="left"
                  overlay={
                    displayInformation ? (
                      <Tooltip id="tooltip">
                        <strong>{checkInformation.mname}</strong>
                      </Tooltip>
                    ) : (
                      <Tooltip id="tooltip" style={{ display: 'none' }} />
                    )
                  }
                >
                  {showMname === 1 ? (
                    <div className="colMedium colHeaderTilted">
                      <p>MNAME validation</p>{' '}
                    </div>
                  ) : (
                    <div className="colXSmall colHeaderTilted">
                      <p>MNAME validation</p>{' '}
                    </div>
                  )}
                </OverlayTrigger>
                <OverlayTrigger
                  placement="left"
                  overlay={
                    displayInformation ? (
                      <Tooltip id="tooltip">
                        <strong>{checkInformation.rname}</strong>
                      </Tooltip>
                    ) : (
                      <Tooltip id="tooltip" style={{ display: 'none' }} />
                    )
                  }
                >
                  <div className="colXSmall colHeaderTilted">
                    <p>Mailbox validation</p>{' '}
                  </div>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="left"
                  overlay={
                    displayInformation ? (
                      <Tooltip id="tooltip">
                        <strong>{checkInformation.sec_ver}</strong>
                      </Tooltip>
                    ) : (
                      <Tooltip id="tooltip" style={{ display: 'none' }} />
                    )
                  }
                >
                  <div className="colXSmall colHeaderTilted">
                    <p>
                      Security check <br />
                      -version query
                    </p>
                  </div>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="left"
                  overlay={
                    displayInformation ? (
                      <Tooltip id="tooltip">
                        <strong>{checkInformation.sec_rec}</strong>
                      </Tooltip>
                    ) : (
                      <Tooltip id="tooltip" style={{ display: 'none' }} />
                    )
                  }
                >
                  <div className="colXSmall colHeaderTilted">
                    <p>
                      Security check <br />
                      -recursion attempt
                    </p>
                  </div>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="left"
                  overlay={
                    displayInformation ? (
                      <Tooltip id="tooltip">
                        <strong>{checkInformation.sec_xfr}</strong>
                      </Tooltip>
                    ) : (
                      <Tooltip id="tooltip" style={{ display: 'none' }} />
                    )
                  }
                >
                  <div className="colXSmall colHeaderTilted">
                    <p>
                      Security check <br />
                      -zone transfer attempt
                    </p>{' '}
                  </div>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="left"
                  overlay={
                    displayInformation ? (
                      <Tooltip id="tooltip">
                        <strong>{checkInformation.asn_check}</strong>
                      </Tooltip>
                    ) : (
                      <Tooltip id="tooltip" style={{ display: 'none' }} />
                    )
                  }
                >
                  <div className="colXSmallASN colHeaderTilted">
                    <p>ASN</p>
                  </div>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="left"
                  overlay={
                    displayInformation ? (
                      <Tooltip id="tooltip">
                        <strong>{checkInformation.host_val}</strong>
                      </Tooltip>
                    ) : (
                      <Tooltip id="tooltip" style={{ display: 'none' }} />
                    )
                  }
                >
                  <div className="colXSmall colHeaderTilted">
                    <p>Host name validation </p>
                  </div>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="left"
                  overlay={
                    displayInformation ? (
                      <Tooltip id="tooltip">
                        <strong>{checkInformation.host_ptr}</strong>
                      </Tooltip>
                    ) : (
                      <Tooltip id="tooltip" style={{ display: 'none' }} />
                    )
                  }
                >
                  <div className="colXSmall colHeaderTilted">
                    <p>PTR validation</p>
                  </div>
                </OverlayTrigger>
              </div>
              {NameServers}
            </div>
          )}
          <div className="dataTableDomainBottomContainer" style={{ width: bottomContainerWidth }}>
            <div className="locationConfigMetaContainer">
              {locationConfig.domain_check.mx_val.cfg_status === 'unavailable' ? (
                ''
              ) : (
                <div className="onClickDomain">
                  <DomainCheck
                    noChange={noChange}
                    busy={busy}
                    checkInfo={displayInformation ? checkInformation.mx_val : ''}
                    configuration={locationConfig.domain_check.mx_val}
                    data={
                      locationConfig.data.domain_check
                        ? locationConfig.data.domain_check.mx_val
                        : {}
                    }
                    displayData={locationConfig.domain_check.mx_val.check_name}
                    copy={copyConf.domain_check.mx_val}
                    saveConfiguration={(item) =>
                      saveConfiguration({
                        ...copyConf,
                        domain_check: { ...copyConf.domain_check, mx_val: item },
                      })
                    }
                    utc={locationConfig.data.date_executed}
                  />
                </div>
              )}

              {locationConfig.domain_check.mx_res.cfg_status === 'unavailable' ? (
                ''
              ) : (
                <div className="onClickDomain">
                  <DomainCheck
                    noChange={noChange}
                    busy={busy}
                    checkInfo={displayInformation ? checkInformation.mx_res : ''}
                    configuration={locationConfig.domain_check.mx_res}
                    data={
                      locationConfig.data.domain_check
                        ? locationConfig.data.domain_check.mx_res
                        : {}
                    }
                    displayData={locationConfig.domain_check.mx_res.check_name}
                    copy={copyConf.domain_check.mx_res}
                    saveConfiguration={(item) =>
                      saveConfiguration(
                        {
                          ...copyConf,
                          domain_check: { ...copyConf.domain_check, mx_res: item },
                        },
                        copyConf,
                      )
                    }
                    utc={locationConfig.data.date_executed}
                  />
                </div>
              )}
              {locationConfig.domain_check.delegation.cfg_status === 'unavailable' ? (
                ''
              ) : (
                <div className="onClickDomain">
                  <DomainCheck
                    noChange={noChange}
                    busy={busy}
                    checkInfo={displayInformation ? checkInformation.delegation : ''}
                    configuration={locationConfig.domain_check.delegation}
                    data={
                      locationConfig.data.domain_check
                        ? locationConfig.data.domain_check.delegation
                        : {}
                    }
                    displayData={locationConfig.domain_check.delegation.check_name}
                    copy={copyConf.domain_check.delegation}
                    saveConfiguration={(item) =>
                      saveConfiguration(
                        {
                          ...copyConf,
                          domain_check: { ...copyConf.domain_check, delegation: item },
                        },
                        copyConf,
                      )
                    }
                    utc={locationConfig.data.date_executed}
                  />
                </div>
              )}
              {locationConfig.domain_check.asn_sum.cfg_status === 'unavailable' ? (
                ''
              ) : (
                <div className="onClickDomain">
                  <DomainCheck
                    noChange={noChange}
                    busy={busy}
                    checkInfo={displayInformation ? checkInformation.asn_sum : ''}
                    configuration={locationConfig.domain_check.asn_sum}
                    data={
                      locationConfig.data.domain_check
                        ? locationConfig.data.domain_check.asn_sum
                        : {}
                    }
                    displayData={locationConfig.domain_check.asn_sum.check_name}
                    copy={copyConf.domain_check.asn_sum}
                    saveConfiguration={(item) =>
                      saveConfiguration(
                        {
                          ...copyConf,
                          domain_check: { ...copyConf.domain_check, asn_sum: item },
                        },
                        copyConf,
                      )
                    }
                    utc={locationConfig.data.date_executed}
                  />
                </div>
              )}
              {locationConfig.expirationStatus === 'unavailable' ||
              locationConfig.expirationStatus === 'unsupported' ||
              !locationConfig.expirationData ||
              locationConfig.expirationStatus === undefined ? (
                ''
              ) : (
                <div className="onClickDomain">
                  <DomainCheck
                    noChange
                    busy={busy}
                    checkInfo={displayInformation ? checkInformation.expiration : ''}
                    configuration={{
                      ...locationConfig.expirationData,
                      cfg_status: locationConfig.expirationStatus,
                    }}
                    data={
                      locationConfig.expirationData
                        ? {
                            ...locationConfig.expirationData,
                            cfg_status: locationConfig.expirationStatus,
                          }
                        : {}
                    }
                    displayData={locationConfig.domain_check.expiration.check_name}
                    copy={{
                      ...locationConfig.expirationData,
                      cfg_status: locationConfig.expirationStatus,
                    }}
                    utc={locationConfig.data.date_executed}
                  />
                </div>
              )}
            </div>
            {locationConfig.host[0] ? (
              <div className="resourceRecordTable">
                <div className="tableRow">
                  <div className="colMedium colHeader columnStyle resourceRecordNameCell">Name</div>
                  <div className="colMedium colHeader columnStyle resourceRecordNameCell">
                    IP or CNAME
                  </div>
                </div>
                {Hosts}
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>
      </div>
    );
  }
  if (locationConfig.host[0]) {
    return (
      <div className="nameServerTable">
        <div className="dataTableDomainBottomContainer" style={{ width: bottomContainerWidth }}>
          <div className="resourceRecordTable basicResourceRecord">
            <div className="tableRow">
              <div className="colMedium colHeader columnStyle resourceRecordNameCell">Name</div>
              <div className="colMedium colHeader columnStyle resourceRecordNameCell">
                IP or CNAME
              </div>
              <div className="colXSmall colHeaderTilted">
                <p>Host name validation </p>
              </div>
              <div className="colXSmall colHeaderTilted">
                <p>PTR validation</p>
              </div>
            </div>
            {Hosts}
          </div>
        </div>
      </div>
    );
  }
  return <div />;
}

DataTable.propTypes = {
  locationConfig: PropTypes.shape(PropTypes.Object).isRequired,
  copyConf: PropTypes.shape(PropTypes.Object).isRequired,
  domainName: PropTypes.string.isRequired,
  noChange: PropTypes.bool,
  displayInformation: PropTypes.bool,
  saveConfiguration: PropTypes.func,
  busy: PropTypes.bool,
  subscriptionType: PropTypes.number.isRequired,
};

DataTable.defaultProps = {
  noChange: false,
  displayInformation: false,
  busy: false,
  saveConfiguration: () => {},
};

export default DataTable;
