// default imports
import { getDomainConfiguration } from 'Actions/domains';
import {
  deleteInvitation,
  listInvitedUsers,
  patchInvitation,
  resendInvitation,
} from 'Actions/invitedUsers';
// actions
import { listUsers, changePage, deleteManageUser, patchManageUser } from 'Actions/users';
import InformationModal from 'Components/InformationModal/InformationModal';
// custom imports
import Loading from 'Components/Loading/Loading';
import StatusIcon from 'Components/StatusIcon';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Button, Col, ControlLabel, Grid, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import Select from 'react-select';

import InviteUser from './Components/InviteUser/InviteUser';
import ListInvitedUsersResults from './Components/ListInvitedUsersResults/ListInvitedUsersResults';
import ListUsersResults from './Components/ListUsersResults/ListUsersResults';
// styles
import './Users.css';

export class Users extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showEditUserModal: false,
      modalUser: null,
      modalInvitedUser: null,
      userDomain: null,
      userSaveError: '',
      showRemoveUserModal: false,
      removeUserError: '',
      showRemoveInvitedUserModal: false,
      removeInvitedUserError: '',
      invitedUserDomain: null,
      showEditInvitedUserModal: false,
      editInvitedUserError: '',
      showResendInvitationModal: false,
      resendInvitationError: '',
    };
    this.changePagination = this.changePagination.bind(this);
  }

  componentWillMount() {
    if (!this.props.domains.fetched) {
      this.props.dispatch(getDomainConfiguration());
    }
    if (!this.props.users.fetched) {
      this.props.dispatch(listUsers());
    }
    if (!this.props.invitedUsers.fetched) {
      this.props.dispatch(listInvitedUsers());
    }
  }

  saveUser = async () => {
    const { modalUser, userDomain } = this.state;
    const domains = userDomain ? userDomain.split(',') : [];
    const response = await this.props.dispatch(patchManageUser(modalUser.id, domains));
    this.setState({ userSaveError: response.error });
    if (!response.error) {
      this.closeSaveUserModal();
    }
  };

  closeSaveUserModal = () => {
    this.setState({ showEditUserModal: false, userSaveError: '' });
  };

  removeUser = async () => {
    const { id } = this.state.modalUser;
    const response = await this.props.dispatch(deleteManageUser(id));
    this.setState({ removeUserError: response.error });
    if (!response.error) {
      this.closeRemoveUserModal();
    }
  };

  closeRemoveUserModal = () => {
    this.setState({ showRemoveUserModal: false, removeUserError: '' });
  };

  removeInvitedUser = async () => {
    const { id } = this.state.modalInvitedUser;
    const response = await this.props.dispatch(deleteInvitation(id));
    this.setState({ removeInvitedUserError: response.error });
    if (!response.error) {
      this.closeRemoveInvitedUserModal();
    }
  };

  closeRemoveInvitedUserModal = () => {
    this.setState({ showRemoveInvitedUserModal: false, removeInvitedUserError: '' });
  };

  editInvitedUser = async () => {
    const { modalInvitedUser, invitedUserDomain } = this.state;
    const domains = invitedUserDomain ? invitedUserDomain.split(',') : [];
    const response = await this.props.dispatch(patchInvitation(modalInvitedUser.id, domains));
    this.setState({ editInvitedUserError: response.error });
    if (!response.error) {
      this.closeEditInvitedUserModal();
    }
  };

  closeEditInvitedUserModal = () => {
    this.setState({ showEditInvitedUserModal: false, editInvitedUserError: '' });
  };

  closeResendInvitationModal = () =>
    this.setState({ showResendInvitationModal: false, resendInvitationError: '' });

  resendInvitation = async () => {
    const { id } = this.state.modalInvitedUser;
    const response = await this.props.dispatch(resendInvitation(id));
    this.setState({ resendInvitationError: response.error });
    if (!response.error) {
      this.closeResendInvitationModal();
    }
  };

  changePagination(page, data) {
    this.props.dispatch(changePage(page, data));
  }

  disableUserModalSaveButton() {
    return (
      !this.state.modalUser ||
      this.state.modalUser.allowed_domain_ids.join(',') === this.state.userDomain
    );
  }

  disableEditInvitedUserModalSaveButton() {
    return (
      !this.state.modalInvitedUser ||
      this.state.modalInvitedUser.allowed_domains.join(',') === this.state.invitedUserDomain
    );
  }

  render() {
    const { invitedUsers, domains, users } = this.props;
    const domainOptions = domains.fetched
      ? domains.domains.results.map((i) => ({ value: i.id, label: i.name }))
      : [];
    return (
      <Grid id="UsersPage">
        {users.fetched || users.fetching ? (
          <Col xs={12}>
            <Row className="filter-header">
              <Col xs={12}>Users</Col>
            </Row>
            <Row className="filter-content">
              {domains.fetched && users.fetched ? (
                <ListUsersResults
                  results={users.results}
                  page={users.pagination.page}
                  changePagination={(page) => this.changePagination(page, this.props.users)}
                  busy={users.busy}
                  onEditItem={(modalUser) =>
                    this.setState({
                      showEditUserModal: true,
                      modalUser,
                      userDomain: modalUser.allowed_domains.map((i) => i.id).join(','),
                    })
                  }
                  onRemoveItem={(modalUser) =>
                    this.setState({
                      modalUser,
                      showRemoveUserModal: true,
                    })
                  }
                />
              ) : (
                <Loading loadingText="Loading results..." />
              )}
            </Row>
          </Col>
        ) : (
          ''
        )}
        <Col xs={12}>
          <Row className="filter-header">
            <Col xs={12}>Invite user</Col>
          </Row>
          <Row className="filter-content">
            {domains.fetched ? (
              <InviteUser domains={domains.domains.results} />
            ) : (
              <Loading loadingText="Loading domains..." />
            )}
          </Row>
        </Col>
        {invitedUsers.fetched || invitedUsers.fetching ? (
          <Col xs={12}>
            <Row className="filter-header">
              <Col xs={12}>Invitations</Col>
            </Row>
            <Row className="filter-content">
              {domains.fetched && invitedUsers.fetched ? (
                <ListInvitedUsersResults
                  results={invitedUsers.results}
                  page={invitedUsers.pagination.page}
                  changePagination={(page) => this.changePagination(page, this.props.invitedUsers)}
                  busy={invitedUsers.busy}
                  onEditItem={(modalInvitedUser) =>
                    this.setState({
                      modalInvitedUser,
                      showEditInvitedUserModal: true,
                      invitedUserDomain: modalInvitedUser.allowed_domains
                        .map((i) => i.id)
                        .join(','),
                    })
                  }
                  onRemoveItem={(modalInvitedUser) =>
                    this.setState({
                      modalInvitedUser,
                      showRemoveInvitedUserModal: true,
                    })
                  }
                  onResend={(modalInvitedUser) =>
                    this.setState({
                      modalInvitedUser,
                      showResendInvitationModal: true,
                    })
                  }
                />
              ) : (
                <Loading loadingText="Loading results..." />
              )}
            </Row>
          </Col>
        ) : (
          ''
        )}
        <InformationModal
          showModal={this.state.showEditUserModal}
          closeCallback={this.closeSaveUserModal}
          modalHeader="Edit user"
        >
          <div className="modal-container">
            {domains.fetched ? (
              <div className="modal-text">
                <ControlLabel>Domains:</ControlLabel>
                <Select
                  multi
                  onChange={(userDomain) => this.setState({ userDomain })}
                  options={domainOptions}
                  placeholder="Select domains"
                  closeOnSelect={false}
                  simpleValue
                  value={this.state.userDomain}
                />
              </div>
            ) : (
              <Loading loadingText="Loading domains..." />
            )}
            <div className="modal-buttons">
              {users.patching_manage_user && <StatusIcon inProgress />}
              {this.state.userSaveError && <StatusIcon error />}
              <Button
                onClick={this.saveUser}
                className="btn-success"
                disabled={users.patching_manage_user || this.disableUserModalSaveButton()}
              >
                Save
              </Button>
              <Button onClick={this.closeSaveUserModal}>Close</Button>
              {this.state.userSaveError && (
                <div className="error error-box error-message">{this.state.userSaveError}</div>
              )}
            </div>
          </div>
        </InformationModal>
        <InformationModal
          showModal={this.state.showRemoveUserModal}
          closeCallback={this.closeRemoveUserModal}
          modalHeader="Remove user"
        >
          <div className="modal-container">
            <div className="modal-text">Are you sure you want to remove this user?</div>
            <div className="modal-buttons">
              {users.deleting_manage_user && <StatusIcon inProgress />}
              {this.state.removeUserError && <StatusIcon error />}
              <Button className="btn-primary" onClick={this.closeRemoveUserModal}>
                No
              </Button>
              <Button disabled={users.deleting_manage_user} onClick={this.removeUser}>
                Yes
              </Button>
              {this.state.removeUserError && (
                <div className="error error-box error-message">{this.state.removeUserError}</div>
              )}
            </div>
          </div>
        </InformationModal>
        <InformationModal
          showModal={this.state.showRemoveInvitedUserModal}
          closeCallback={this.closeRemoveInvitedUserModal}
          modalHeader="Remove invited user"
        >
          <div className="modal-container">
            <div className="modal-text">Are you sure you want to remove this invitation?</div>
            <div className="modal-buttons">
              {invitedUsers.deleting_invitation && <StatusIcon inProgress />}
              {this.state.removeInvitedUserError && <StatusIcon error />}
              <Button className="btn-primary" onClick={this.closeRemoveInvitedUserModal}>
                No
              </Button>
              <Button disabled={invitedUsers.deleting_invitation} onClick={this.removeInvitedUser}>
                Yes
              </Button>
              {this.state.removeInvitedUserError && (
                <div className="error error-box error-message">
                  {this.state.removeInvitedUserError}
                </div>
              )}
            </div>
          </div>
        </InformationModal>
        <InformationModal
          showModal={this.state.showEditInvitedUserModal}
          closeCallback={this.closeEditInvitedUserModal}
          modalHeader="Edit invited user"
        >
          <div className="modal-container">
            {domains.fetched ? (
              <div className="modal-text">
                <ControlLabel>Domains:</ControlLabel>
                <Select
                  multi
                  onChange={(invitedUserDomain) => this.setState({ invitedUserDomain })}
                  options={domainOptions}
                  placeholder="Select domains"
                  closeOnSelect={false}
                  simpleValue
                  value={this.state.invitedUserDomain}
                />
              </div>
            ) : (
              <Loading loadingText="Loading domains..." />
            )}
            <div className="modal-buttons">
              {invitedUsers.loading_patch_invitation && <StatusIcon inProgress />}
              {this.state.editInvitedUserError && <StatusIcon error />}
              <Button
                onClick={this.editInvitedUser}
                className="btn-success"
                disabled={
                  invitedUsers.loading_patch_invitation ||
                  this.disableEditInvitedUserModalSaveButton()
                }
              >
                Save
              </Button>
              <Button onClick={this.closeEditInvitedUserModal}>Close</Button>
              {this.state.editInvitedUserError && (
                <div className="error error-box error-message">
                  {this.state.editInvitedUserError}
                </div>
              )}
            </div>
          </div>
        </InformationModal>
        <InformationModal
          showModal={this.state.showResendInvitationModal}
          closeCallback={this.closeResendInvitationModal}
          modalHeader="Resend invitation"
        >
          <div className="modal-container">
            <div className="modal-text">
              Are you sure you want to resend an invitation to{' '}
              {this.state.modalInvitedUser && <b>{this.state.modalInvitedUser.email}</b>}?
            </div>
            <div className="modal-buttons">
              {invitedUsers.resending_invitation && <StatusIcon inProgress />}
              {this.state.resendInvitationError && <StatusIcon error />}
              <Button className="btn-primary" onClick={this.closeResendInvitationModal}>
                No
              </Button>
              <Button disabled={invitedUsers.resending_invitation} onClick={this.resendInvitation}>
                Yes
              </Button>
              {this.state.resendInvitationError && (
                <div className="error error-box error-message">
                  {this.state.resendInvitationError}
                </div>
              )}
            </div>
          </div>
        </InformationModal>
      </Grid>
    );
  }
}

Users.propTypes = {
  domains: PropTypes.shape({
    fetched: PropTypes.bool,
    domains: PropTypes.object,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  users: PropTypes.shape({
    fetched: PropTypes.bool,
    fetching: PropTypes.bool,
    busy: PropTypes.bool,
  }).isRequired,
  invitedUsers: PropTypes.shape({
    fetched: PropTypes.bool,
    fetching: PropTypes.bool,
    busy: PropTypes.bool,
  }).isRequired,
};

function mapStateToProps(store) {
  return {
    domains: store.domains,
    users: store.users,
    invitedUsers: store.invitedUsers,
  };
}

export default connect(mapStateToProps)(Users);
