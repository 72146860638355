import PropTypes from 'prop-types';
import React from 'react';

import './Switch.css';

function Switch({ on, onClick, onDisabledClick, enabled, className, disabled }) {
  const classes = ['switch', className, on ? 'on ' : '', enabled ? '' : 'disabled '].join(' ');
  return (
    <div
      className={classes}
      onClick={(e) => !disabled && (enabled ? onClick(e) : onDisabledClick(e))}
      role="button"
      tabIndex="0"
      style={disabled ? { pointerEvents: 'none', cursor: 'none', opacity: 0.4 } : {}}
      onKeyPress={(e) => !disabled && (enabled ? onClick(e) : onDisabledClick(e))}
    >
      <div className="switch-toggle" />
    </div>
  );
}

Switch.propTypes = {
  on: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  enabled: PropTypes.bool,
  className: PropTypes.string,
  onDisabledClick: PropTypes.func,
  disabled: PropTypes.bool,
};

Switch.defaultProps = {
  enabled: true,
  className: '',
  onDisabledClick: () => {},
  disabled: false,
};

export default Switch;
