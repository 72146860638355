function setStorage(prefix, id, state) {
  localStorage.setItem(prefix + id, state);
}

function showItem(prefix, id) {
  return localStorage.getItem(prefix + id) === 'true';
}

function getUser() {
  const profile = JSON.parse(localStorage.getItem('profile'));
  return profile ? profile.user_id : '';
}

function setReadOnlyMode(value) {
  if (typeof value === 'boolean') {
    localStorage.setItem('readOnlyMode', value);
  } else if (typeof value === 'string') {
    localStorage.setItem('readOnlyMode', value === 'true');
  } else {
    localStorage.setItem('readOnlyMode', false);
  }
}

function getReadOnlyMode() {
  const ro = localStorage.getItem('readOnlyMode');
  if (typeof ro === 'boolean') {
    return ro;
  }
  if (typeof ro === 'string') {
    return ro === 'true';
  }
  return false;
}

function setImpersonatedUser(value) {
  setReadOnlyMode(value != null);
  localStorage.setItem('impersonatedUser', value ? JSON.stringify(value) : null);
}

function getImpersonatedUser() {
  const impersonatedUser = localStorage.getItem('impersonatedUser');
  return impersonatedUser ? JSON.parse(impersonatedUser) : null;
}

function getAlertDismissTime() {
  return localStorage.getItem('alertDismissed');
}

function setAlertDismissTime(time) {
  localStorage.setItem('alertDismissed', time);
}

function getProfile() {
  const profile = localStorage.getItem('profile');
  return profile ? JSON.parse(localStorage.profile) : {};
}

function setProfile(profile) {
  localStorage.setItem('profile', JSON.stringify(profile));
}

function setUserMetadata(newData) {
  const profile = this.getProfile();
  if (profile) {
    profile.user_metadata = newData;
    this.setProfile(profile);
  }
}

function getAccessToken() {
  const token = localStorage.getItem('id_token');
  return token || '';
}

function setSorting(name) {
  localStorage.setItem('sort', name);
}

function sorting() {
  return localStorage.getItem('sort') ? localStorage.getItem('sort') : 'severity';
}

function setConfigureTicket(ticket, domain) {
  const tickets = localStorage.getItem('configureTicket')
    ? JSON.parse(localStorage.getItem('configureTicket'))
    : {};
  if (tickets[this.getUser()]) {
    tickets[this.getUser()].push({ ticket, domain });
  } else {
    tickets[this.getUser()] = [{ ticket, domain }];
  }
  localStorage.setItem('configureTicket', JSON.stringify(tickets));
}

function getConfigureTicket() {
  return localStorage.getItem('configureTicket')
    ? JSON.parse(localStorage.getItem('configureTicket'))
    : {};
}

function logout() {
  localStorage.removeItem('id_token');
  localStorage.removeItem('profile');
  localStorage.removeItem('domain_information');
  if (window.location.pathname !== '/login') {
    window.location.replace('/login');
  }
}

const StorageService = {
  setStorage,
  logout,
  showItem,
  getUser,
  getAccessToken,
  getProfile,
  setProfile,
  setUserMetadata,
  sorting,
  setSorting,
  setConfigureTicket,
  getConfigureTicket,
  setReadOnlyMode,
  getReadOnlyMode,
  setImpersonatedUser,
  getImpersonatedUser,
  getAlertDismissTime,
  setAlertDismissTime,
};
export default StorageService;
