import * as types from 'Constants/actionTypes';

const initialSettings = {
  fetching: false,
  fetched: false,
  results: [],
  error: {},
  busy: false,
  creating_api_key: false,
  deleting_api_key: false,
  pagination: {},
};

const users = (state = initialSettings, action) => {
  switch (action.type) {
    case types.FETCH_API_KEYS_INITIATED:
      return {
        ...state,
        fetching: true,
        fetched: false,
        busy: true,
        pagination: {},
        error: {},
      };
    case types.FETCH_API_KEYS_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        results: action.results,
        pagination: action.pagination,
        busy: false,
      };
    case types.FETCH_API_KEYS_ERROR:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: { get: action.error },
      };
    case types.CHANGE_PAGE:
      return {
        ...state,
        pagination: { ...state.pagination, page: action.page },
      };
    case types.FETCH_NEXT_INIT:
      return {
        ...state,
        busy: true,
        error: {},
      };
    case types.FETCH_NEXT_SUCCESS:
      return {
        ...state,
        busy: false,
        results: [...state.results, ...action.results],
        pagination: {
          ...state.pagination,
          ...action.pagination,
        },
      };
    case types.FETCH_NEXT_ERROR:
      return {
        ...state,
        busy: false,
        error: { page: action.error },
      };
    case types.CREATE_API_KEYS_INITIATED:
      return { ...state, creating_api_key: true, error: {} };
    case types.CREATE_API_KEYS_SUCCESS: {
      const { results } = state;
      const { key } = action;
      results.push(key);
      return {
        ...state,
        creating_api_key: false,
        results,
      };
    }
    case types.CREATE_API_KEYS_ERROR:
      return { ...state, creating_api_key: false, error: { add: action.error } };
    case types.DELETE_API_KEYS_INITIATED:
      return { ...state, deleting_api_key: true, error: {} };
    case types.DELETE_API_KEYS_SUCCESS:
      return {
        ...state,
        deleting_api_key: false,
        results: state.results.filter((i) => i.id !== action.id),
      };
    case types.DELETE_API_KEYS_ERROR:
      return { ...state, deleting_api_key: false, error: { delete: action.error } };
    case types.LOGOUT_USER:
      return { ...initialSettings };
    default:
      return state;
  }
};

export default users;
