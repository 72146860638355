import * as types from 'Constants/actionTypes';
import FetchService from 'utils/FetchService';
import 'whatwg-fetch';

export function listUsers() {
  return (dispatch) => {
    dispatch({ type: types.FETCH_USERS_INITIATED });
    FetchService.getManageUsers().then((response) => {
      if (response.error) {
        dispatch({ type: types.FETCH_USERS_ERROR, error: response.error });
      } else {
        dispatch({
          type: types.FETCH_USERS_SUCCESS,
          results: response.results,
          pagination: { next: response.next, previous: response.previous, page: 0 },
        });
      }
    });
  };
}

export function changePage(pageChange, data) {
  return (dispatch) => {
    const page =
      data.pagination.page + pageChange <= data.results.length / 25
        ? data.pagination.page + pageChange
        : data.pagination.page;
    dispatch({ type: types.CHANGE_PAGE, page });
    if (page * 25 >= data.results.length - 75 && data.pagination.next && !data.busy) {
      dispatch({ type: types.FETCH_NEXT_INIT });
      FetchService.fetchNextHistory(data.pagination.next).then((response) => {
        if (response.error) {
          dispatch({ type: types.FETCH_NEXT_ERROR, error: response.error });
        } else {
          dispatch({
            type: types.FETCH_NEXT_SUCCESS,
            results: response.results,
            pagination: { next: response.next, previous: response.previous },
          });
        }
      });
    }
  };
}

export const patchManageUser = (id, allowedDomains) => async (dispatch) => {
  try {
    dispatch({ type: types.PATCH_MANAGE_USER_INITIATED });
    const user = await FetchService.patchManageUser(id, allowedDomains);
    return dispatch({ type: types.PATCH_MANAGE_USER_SUCCESS, user });
  } catch (error) {
    let err = String(error);
    if (error.allowed_domain_ids) {
      [err] = error.allowed_domain_ids;
    }
    return dispatch({ type: types.PATCH_MANAGE_USER_ERROR, error: err });
  }
};

export const deleteManageUser = (id) => async (dispatch) => {
  try {
    dispatch({ type: types.DELETE_MANAGE_USER_INITIATED });
    await FetchService.deleteManageUser(id);
    return dispatch({ type: types.DELETE_MANAGE_USER_SUCCESS, id });
  } catch (error) {
    return dispatch({ type: types.DELETE_MANAGE_USER_ERROR, error: String(error) });
  }
};

const users = {
  listUsers,
  changePage,
  deleteManageUser,
  patchManageUser,
};
export default users;
