// default imports
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Col, Glyphicon } from 'react-bootstrap';
import StorageService from 'utils/StorageService';

// custom components
// styling
import './ResourceRecordListItem.css';

export class ResourceRecordListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { domain, resourceRecord, onRemove } = this.props;
    const readonly = StorageService.getReadOnlyMode();
    return (
      <Row className="resource-record-list-item">
        <Col xs={2} className="resource-record-id">
          {resourceRecord.id}
        </Col>
        <Col className="resource-record-name">
          {resourceRecord.name && `${resourceRecord.name}.`}
          {domain.name}
        </Col>
        {!readonly && (
          <Col xs={1}>
            <Glyphicon
              glyph="trash"
              onClick={() => onRemove(resourceRecord)}
              className="glyphicon-button warning"
            />
          </Col>
        )}
      </Row>
    );
  }
}

ResourceRecordListItem.propTypes = {
  domain: PropTypes.shape().isRequired,
  resourceRecord: PropTypes.shape().isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default ResourceRecordListItem;
