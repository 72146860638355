import { getPaymentMethods, deletePaymentMethod, updatePaymentMethod } from 'Actions/paymentMethod';
// Actions
import { getInvoices } from 'Actions/purchases';
import Payment from 'Components/Payment/Payment';
// Constants
import * as paymentTypes from 'Constants/paymentTypes';
import stripeLogo from 'assets/images/powered_by_stripe.svg';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Button, Row, Col, FormGroup, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import Select from 'react-select';
import FetchService from 'utils/FetchService';

// Components
import InvoiceList from '../InvoiceList/InvoiceList';

class PaymentBox extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showInfoModal: false,
      selectedPaymentMethod: null,
      cardCol: '',
      progress: '',
      showCardDeleteModal: false,
      showPlanModal: false,
      newPlan: '',
    };
  }

  componentDidMount() {
    this.props.dispatch(getPaymentMethods());
    this.props.dispatch(getInvoices());
  }

  getProgress = (closed, paid, attempted) => {
    if (closed && paid && attempted) {
      return <td className="success">Paid</td>;
    }
    if (closed && !paid && attempted) {
      return <td className="error">Unpaid</td>;
    }
    if (!closed && !paid && !attempted) {
      return <td>Upcoming</td>;
    }
    if (!closed && !paid && attempted) {
      return <td className="in-progress">Ongoing</td>;
    }
    if (closed && !paid && !attempted) {
      return <td>Closed</td>;
    }
    return <td>-</td>;
  };

  getDefaultPaymentMethod() {
    const { paymentMethods } = this.props.paymentMethodData;
    if (paymentMethods.results) {
      const defaultPaymentMethod = paymentMethods.results.find((payment) => payment.is_default);
      if (defaultPaymentMethod) {
        return `xxxx-xxxx-xxxx-${defaultPaymentMethod.card.last4}`;
      }
    }
    return 'No active card';
  }

  setDefaultPaymentMethod = () => {
    const { selectedPaymentMethod } = this.state;
    this.props.dispatch(updatePaymentMethod(selectedPaymentMethod, { set_default: true }));
  };

  getPaymentSources = () => {
    const { paymentMethods } = this.props.paymentMethodData;
    if (paymentMethods.results) {
      return paymentMethods.results.map((paymentDetails) => ({
        label: `xxxx-xxxx-xxxx-${paymentDetails.card.last4}`,
        value: paymentDetails.id,
      }));
    }
    return [];
  };

  getColumn(col) {
    switch (col) {
      case 'add':
        return (
          <div>
            <div className="box-sub-header">Add Card</div>
            <FormGroup controlId="formControlsSelect" className="change-card add-card">
              <Payment
                dispatch={this.props.dispatch}
                buttonText="Add"
                FetchService={FetchService}
              />
            </FormGroup>
          </div>
        );
      case 'change':
        return (
          <div>
            <div className="box-sub-header">Change Card</div>
            <FormGroup controlId="formControlsSelect" className="change-card">
              <Select
                options={this.getPaymentSources()}
                placeholder="Select Card"
                onChange={this.selectPaymentMethod}
                simpleValue
                value={this.state.selectedPaymentMethod}
              />
              <Button
                className="btn btn-primary"
                onClick={this.setDefaultPaymentMethod}
                disabled={!this.state.selectedPaymentMethod}
              >
                Change
              </Button>
              <div className="card-progress">
                <i className={this.state.progress} />
              </div>
            </FormGroup>
          </div>
        );
      case 'delete':
        return (
          <div>
            <div className="box-sub-header">Delete Card</div>
            <FormGroup controlId="formControlsSelect" className="change-card">
              <Select
                options={this.getPaymentSources()}
                placeholder="Select Card"
                onChange={this.selectPaymentMethod}
                simpleValue
                value={this.state.selectedPaymentMethod}
              />
              <Button
                className="btn btn-danger"
                onClick={() =>
                  this.setState({
                    showCardDeleteModal: true,
                  })
                }
                disabled={!this.state.selectedPaymentMethod}
              >
                Delete
              </Button>
              <div className="card-progress">
                <i className={this.state.progress} />
              </div>
            </FormGroup>
          </div>
        );
      default:
        return null;
    }
  }

  openInfoModal = () => {
    this.setState({
      showInfoModal: true,
    });
  };

  closeInfoModal = () => {
    this.setState({
      showInfoModal: false,
    });
  };

  openPlanChangeModal = (event) => {
    this.setState({
      newPlan: event.target.value,
      showPlanModal: true,
    });
  };

  confirmPlanChange = () => {
    this.setState(
      {
        showPlanModal: false,
      },
      () => this.props.setPlan(this.state.newPlan),
    );
  };

  selectPaymentMethod = (selectedPaymentMethod) => {
    this.setState({
      selectedPaymentMethod,
    });
  };

  changeCardCol = (cardCol) => {
    this.setState({
      cardCol,
      selectedPaymentMethod: null,
      progress: '',
    });
  };

  deleteSelectedPaymentMethod = () => {
    this.props.dispatch(deletePaymentMethod(this.state.selectedPaymentMethod));
    this.setState({
      showCardDeleteModal: false,
    });
  };

  closeDeleteModal = () => {
    this.setState({
      showCardDeleteModal: false,
    });
  };

  closePlanModal = () => {
    this.setState({
      showPlanModal: false,
    });
  };

  render() {
    return (
      <Row className="paymentBox">
        <Col>
          <Row className="subscription-header">
            <Col xs={12}>
              Payment details
              <i
                className=" fas fa-info-circle information-icon-white"
                onClick={this.openInfoModal}
                role="button"
                onKeyPress={this.openInfoModal}
                tabIndex="0"
              />
            </Col>
          </Row>
          <Row className="subscription-content">
            <Row className="box-sub-header">
              <Col xs={4}>Payment Frequency</Col>
              <Col className="payment-date" xs={4}>
                Next payment
              </Col>
              <Col className="amount-col" xs={4}>
                Total amount
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <select
                  value={this.props.subscriptionData.billing_interval}
                  onChange={(event) => this.openPlanChangeModal(event)}
                  disabled={this.props.subscriptionData.status === 'unpaid'}
                >
                  <option value="yearly">Yearly</option>
                  <option value="monthly">Monthly</option>
                </select>
              </Col>
              <Col className="payment-date" xs={4}>
                {this.props.subscriptionData && this.props.subscriptionData.current_period_end ? (
                  moment(this.props.subscriptionData.current_period_end).format('YYYY-MM-DD')
                ) : (
                  <i>No date</i>
                )}
              </Col>
              <Col className="amount-col" xs={4}>
                ${this.props.totalCost}
              </Col>
            </Row>
            <Row>
              <Col className="box-sub-header card-header" xs={12}>
                Active Card
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="card-row">
                <Col className="card-number" xs={3}>
                  {this.getDefaultPaymentMethod()}
                </Col>
                {!this.props.isReadOnlyMode ? (
                  <Col className="card-buttons" xs={3}>
                    <button
                      type="button"
                      disabled={this.props.paymentStatus === paymentTypes.DELINQUENT}
                      className="button-as-link"
                      onClick={() => this.changeCardCol('add')}
                    >
                      Add
                    </button>
                    <button
                      type="button"
                      className="button-as-link"
                      onClick={() => this.changeCardCol('change')}
                    >
                      Change
                    </button>
                    <button
                      type="button"
                      className="button-as-link"
                      onClick={() => this.changeCardCol('delete')}
                    >
                      Delete
                    </button>
                  </Col>
                ) : (
                  <Col xs={3} />
                )}
                <Col className="card-event-box" xs={4}>
                  {this.getColumn(this.state.cardCol)}
                </Col>
                <Col xs={2} className="logo-col">
                  <img className="stripe-logo" src={stripeLogo} alt="" />
                </Col>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                Invoices
                <InvoiceList
                  page={this.state.page}
                  changePagination={this.state.changePage}
                  results={this.props.invoices.data}
                  isReadOnlyMode={this.props.isReadOnlyMode}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} />
            </Row>
          </Row>
        </Col>
        <Modal
          show={this.state.showInfoModal}
          onHide={this.closeInfoModal}
          className="information-modal"
        >
          <div className="modal-container">
            <div className="modal-text">
              <ul className="list-guide">
                <li className="header-list">
                  <b>Payment details</b>
                </li>
                <li>
                  Changes to billing period will take effect at the next payment date.
                  <br />
                  Always make sure that the active card is valid or you risk being downgraded to a
                  free account, which will automatically end monitoring of all domains but your
                  first, which will be downgraded to a free subscription.
                </li>
              </ul>
            </div>
            <div className="modal-buttons">
              <Button onClick={this.closeInfoModal}>Close</Button>
            </div>
          </div>
        </Modal>
        <Modal
          show={this.state.showCardDeleteModal}
          onHide={this.closeDeleteModal}
          className="sub-confirm-modal"
        >
          <div className="modal-container">
            <div className="modal-text">
              Without a valid credit card the service could be interrupted at next payment date.
            </div>
            <Row className="button-row">
              <Col xs={6}>
                <Button
                  className="btn-success confirm-button"
                  onClick={this.deleteSelectedPaymentMethod}
                >
                  Confirm
                </Button>
              </Col>
              <Col xs={6}>
                <Button className="btn-danger cancel-button" onClick={this.closeDeleteModal}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </div>
        </Modal>
        <Modal
          show={this.state.showPlanModal}
          onHide={this.closePlanModal}
          className="sub-confirm-modal"
        >
          <div className="modal-container">
            <div className="modal-text">
              The payment frequency change will take effect at next scheduled payment.
            </div>
            <Row className="button-row">
              <Col xs={6}>
                <Button className="btn-success confirm-button" onClick={this.confirmPlanChange}>
                  Confirm
                </Button>
              </Col>
              <Col xs={6}>
                <Button className="btn-danger cancel-button" onClick={this.closePlanModal}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </div>
        </Modal>
      </Row>
    );
  }
}

PaymentBox.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isReadOnlyMode: PropTypes.bool.isRequired,
  invoices: PropTypes.shape({
    fetching: PropTypes.bool,
    fetched: PropTypes.bool,
    data: PropTypes.array,
  }).isRequired,
  paymentMethodData: PropTypes.shape({
    fetching: PropTypes.bool,
    fetched: PropTypes.bool,
    adding: PropTypes.bool,
    updating: PropTypes.bool,
    errors: PropTypes.object,
    paymentMethods: PropTypes.shape({
      count: PropTypes.number,
      results: PropTypes.arrayOf(PropTypes.object),
    }),
    busy: PropTypes.bool,
  }).isRequired,
  setPlan: PropTypes.func.isRequired,
  subscriptionData: PropTypes.shape({}).isRequired,
  totalCost: PropTypes.number.isRequired,
};

PaymentBox.defaultProps = {
  invoices: {
    fetching: false,
    fetched: false,
    data: [],
  },
  totalCost: 0,
};

function mapStateToProps(state) {
  return {
    invoices: state.purchases.invoices,
    paymentStatus: state.authentication.payment,
    paymentMethodData: state.paymentMethod,
  };
}

export default connect(mapStateToProps)(PaymentBox);
