// default imports
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SymbolHelper from 'utils/SymbolHelper';

import MenuPopUp from '../MenuPopUp/MenuPopUp';
import './DataTableCell.css';

function getIcon(rc, status) {
  let symbol = '';
  let color = '';

  color = SymbolHelper.setColor(rc);
  symbol = SymbolHelper.setGlyph(rc);
  color = SymbolHelper.setActive(status, color);
  return <i className={`fas ${symbol}`} style={{ color }} />;
}

export class DataTableCell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    this.toggleCellMenu = this.toggleCellMenu.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.state.show && this.node && !this.node.contains(event.target)) {
      this.setState({
        show: false,
      });
    }
  };

  toggleCellMenu() {
    this.setState({
      show: !this.state.show,
      showASN: false,
    });
  }

  render() {
    const { data, configuration, displayData, disabled, utc } = this.props;
    let icon = '';
    if (data) {
      icon = displayData ? '' : getIcon(data.rc, disabled ? 'disabled' : configuration.cfg_status);
    } else {
      icon = this.getIcon(4, disabled ? 'disabled' : configuration.cfg_status);
    }

    let { rc } = data;
    if (!disabled && (this.props.leftTableItem || this.props.asn)) {
      rc = 5;
    } else if (this.props.ssn && data.rc !== 0) {
      rc = 3;
    }

    return (
      <div
        ref={(node) => {
          this.node = node;
        }}
      >
        <div
          className={this.state.show ? 'tableCol open' : 'tableCol'}
          onClick={(event) => {
            if (!this.props.no_click) this.toggleCellMenu(event);
          }}
          role="button"
          tabIndex="0"
          onKeyPress={(event) => {
            if (!this.props.no_click) this.toggleCellMenu(event);
          }}
          onMouseOver={this.props.asn && (() => this.setState({ showASN: true }))}
          onMouseOut={this.props.asn && (() => this.setState({ showASN: false }))}
          onFocus={this.props.asn && (() => this.setState({ showASN: true }))}
          onBlur={this.props.asn && (() => this.setState({ showASN: false }))}
        >
          {icon}
          <span
            className={
              this.props.asn
                ? 'align asn'
                : `align ${this.props.copy.cfg_status === 'unavailable' ? 'unavailable' : ''}`
            }
            style={{
              color: SymbolHelper.setActive(
                disabled ? 'disabled' : configuration.cfg_status,
                SymbolHelper.setTextColor(rc, this.props.ssn),
              ),
            }}
          >
            {displayData}
          </span>
        </div>
        {this.props.asn && displayData.includes(' ') && this.state.showASN && (
          <div className="asn-list">
            <h6>AS Numbers</h6>
            <div className="asn-container">
              <span>{displayData.split(' ').join(', ')}</span>
            </div>
          </div>
        )}
        <MenuPopUp
          menuInfo={{ ...data, utc }}
          show={this.state.show}
          configuration={this.props.copy}
          no_change={this.props.no_change}
          saveConfiguration={(item) => {
            this.props.saveConfiguration(item);
          }}
          leftTableItem={this.props.leftTableItem}
        />
      </div>
    );
  }
}

DataTableCell.propTypes = {
  leftTableItem: PropTypes.bool,
  configuration: PropTypes.shape({
    cfg_status: PropTypes.string,
  }),
  data: PropTypes.shape(),
  disabled: PropTypes.bool.isRequired,
  displayData: PropTypes.string,
  no_click: PropTypes.bool,
  no_change: PropTypes.bool.isRequired,
  copy: PropTypes.shape({
    cfg_status: PropTypes.string,
  }),
  saveConfiguration: PropTypes.func,
  asn: PropTypes.bool,
  ssn: PropTypes.bool,
};

DataTableCell.defaultProps = {
  leftTableItem: false,
  configuration: { cfg_status: 'unavailable' },
  displayData: '',
  data: { utc: undefined, msg: '-' },
  no_click: false,
  copy: { cfg_status: 'unavailable' },
  saveConfiguration: () => {},
  asn: false,
  ssn: false,
};

export default DataTableCell;
