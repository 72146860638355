// default imports
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Button, Row, Table } from 'react-bootstrap';

// custom imports
import PaginationButton from '../../../../Components/PaginationButton/PaginationButton';
// style imports
import './ListInvitedUsersResults.css';

export class ListInvitedUsersResults extends PureComponent {
  static setAvailInteg(severity) {
    switch (severity) {
      case 0:
        return <div className="severityCircle zero" />;
      case 1:
        return <div className="severityCircle one" />;
      case 2:
        return <div className="severityCircle two" />;
      case 3:
        return <div className="severityCircle three" />;
      case 4:
        return <div className="severityCircle four" />;
      default:
        return <div className="severityCircle temp" />;
    }
  }

  constructor(props) {
    super(props);
    this.getRows = this.getRows.bind(this);
  }

  getRows() {
    const startIndex = this.props.page * 25;
    const pageContent = this.props.results.slice(startIndex, startIndex + 25);
    return pageContent.map((item, index) => {
      const sentDate = Moment(item.date_sent);
      return (
        <tr key={`${index}${item.id}`}>
          <td>{sentDate.isValid() ? sentDate.format('YYYY-MM-DD') : 'Not sent'}</td>
          <td>{Moment(item.date_expired).format('YYYY-MM-DD')}</td>
          <td>{item.email}</td>
          <td>{item.allowed_domains ? item.allowed_domains.map((i) => i.name).join(', ') : ''}</td>
          <td className="min">
            <Button
              className="btn btn-primary"
              style={{ marginRight: 8 }}
              onClick={() => this.props.onEditItem(item)}
            >
              Edit
            </Button>
            <Button
              className="btn btn-danger"
              style={{ marginRight: 8 }}
              onClick={() => this.props.onRemoveItem(item)}
            >
              Remove
            </Button>
            <Button className="btn btn-info" onClick={() => this.props.onResend(item)}>
              Resend invitation
            </Button>
          </td>
        </tr>
      );
    });
  }

  render() {
    const { page, results, busy } = this.props;
    if (!results.length) {
      return (
        <Row id="ListInvitedUsersResults">
          <div className="pagination">No Available results</div>
        </Row>
      );
    }
    return (
      <Row id="ListInvitedUsersResults">
        <Table striped bordered condensed hover>
          <thead>
            <tr>
              <th>Sent</th>
              <th style={{ minWidth: 120 }}>Expiration date</th>
              <th>Email</th>
              <th>Domains</th>
              <th colSpan={2}>Actions</th>
            </tr>
          </thead>
          <tbody>{this.getRows()}</tbody>
        </Table>

        <div className="pagination">
          <PaginationButton
            symbol="&#8592;"
            disabled={page === 0}
            onClick={() => this.props.changePagination(-1)}
          />
          <PaginationButton
            symbol={
              busy && page + 1 >= results.length / 25 ? (
                <i className="fas fa-spinner fa-spin" />
              ) : (
                <span>&#8594;</span>
              )
            }
            disabled={page + 1 >= results.length / 25}
            onClick={() => this.props.changePagination(1)}
          />
          <div>{page + 1}</div>
        </div>
      </Row>
    );
  }
}

ListInvitedUsersResults.propTypes = {
  results: PropTypes.arrayOf(PropTypes.object),
  page: PropTypes.number,
  changePagination: PropTypes.func.isRequired,
  busy: PropTypes.bool,
  onEditItem: PropTypes.func,
  onRemoveItem: PropTypes.func,
};

ListInvitedUsersResults.defaultProps = {
  results: [],
  page: 0,
  busy: false,
  onEditItem: () => ({}),
  onRemoveItem: () => ({}),
};

export default ListInvitedUsersResults;
