import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';

class ItemsBox extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showInfoModal: false,
    };
  }

  openInfoModal = () => {
    this.setState({
      showInfoModal: true,
    });
  };

  closeInfoModal = () => {
    this.setState({
      showInfoModal: false,
    });
  };

  render() {
    const subData = this.props.subscriptionData;
    const basicDomainSurplus = subData.basic_domains.quantity - subData.basic_domains.allocated;
    const unassignedBasicDomains = Math.max(0, basicDomainSurplus);
    const unassignedDomains =
      subData.domains.quantity - subData.domains.allocated + Math.min(0, basicDomainSurplus);
    const unassignedLocations =
      subData.extra_locations.quantity - subData.extra_locations.allocated;
    const unassignedHosts =
      subData.extra_resource_records.quantity - subData.extra_resource_records.allocated;

    return (
      <Row className="itemsBox">
        <Col>
          <Row className="subscription-header">
            <Col xs={12}>
              Unallocated items
              <i
                className=" fas fa-info-circle information-icon-white"
                onClick={this.openInfoModal}
                role="button"
                onKeyPress={this.openInfoModal}
                tabIndex="0"
              />
            </Col>
          </Row>
          <Row className="subscription-content">
            <Row className="item-row">
              <Col xs={3}>Premium Domains</Col>
              <Col xs={2}>{unassignedDomains}</Col>
            </Row>
            <Row className="item-row">
              <Col xs={3}>Basic Domains</Col>
              <Col xs={2}>{unassignedBasicDomains}</Col>
            </Row>
            <Row className="item-row">
              <Col xs={3}>Locations</Col>
              <Col xs={2}>{unassignedLocations}</Col>
            </Row>
            <Row className="item-row">
              <Col xs={3}>Host records</Col>
              <Col xs={2}>{unassignedHosts}</Col>
            </Row>
          </Row>
        </Col>
        <Modal
          show={this.state.showInfoModal}
          onHide={this.closeInfoModal}
          className="information-modal"
        >
          <div className="modal-container">
            <div className="modal-text">
              <ul className="list-guide">
                <li className="header-list">
                  <b>Unallocated items</b>
                </li>
                <li>
                  Unallocated items are items included in your subscriptions that you have not
                  allocated.
                  <br />
                  Any items left unallocated at the next scheduled payment will be removed from your
                  subscription.
                  <br />
                  The total amount below is calculated based on your allocated items.
                </li>
              </ul>
            </div>
            <div className="modal-buttons">
              <Button onClick={this.closeInfoModal}>Close</Button>
            </div>
          </div>
        </Modal>
      </Row>
    );
  }
}

ItemsBox.propTypes = {
  subscriptionData: PropTypes.shape({
    id: PropTypes.string,
    billing_interval: PropTypes.string,
    description: PropTypes.string,
    cancel_at_period_end: PropTypes.bool,
    canceled_at: PropTypes.string,
    current_period_end: PropTypes.string,
    current_period_start: PropTypes.string,
    days_until_due: PropTypes.string,
    ended_at: PropTypes.string,
    status: PropTypes.string,
    trial_end: PropTypes.string,
    trial_start: PropTypes.string,
    domains: PropTypes.shape({
      quantity: PropTypes.number,
      allocated: PropTypes.number,
    }),
    basic_domains: PropTypes.shape({
      quantity: PropTypes.number,
      allocated: PropTypes.number,
    }),
    extra_locations: PropTypes.shape({
      quantity: PropTypes.number,
      allocated: PropTypes.number,
    }),
    extra_resource_records: PropTypes.shape({
      quantity: PropTypes.number,
      allocated: PropTypes.number,
    }),
  }).isRequired,
};

export default ItemsBox;
