// default imports
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Grid, Row } from 'react-bootstrap';
import { connect } from 'react-redux';

// actions
import { getDetailedHistoryData } from '../../Actions/historyDetailed';
import DataTable from '../../Components/DataTable/DataTable';
// custom imports
import Loading from '../../Components/Loading/Loading';
// styles
import './HistoryDashboard.css';

export class HistoryDashboard extends PureComponent {
  constructor(props) {
    super(props);
    this.getData = this.getData.bind(this);
  }

  componentWillMount() {
    this.getData(this.props.match.params.domain_id);
  }

  static setAvailInteg(severity, letter = '') {
    switch (severity) {
      case 0:
        return <div className="severityCircle zero">{letter}</div>;
      case 1:
        return <div className="severityCircle one">{letter}</div>;
      case 2:
        return <div className="severityCircle two">{letter}</div>;
      case 3:
        return <div className="severityCircle three">{letter}</div>;
      case 4:
        return <div className="severityCircle four">{letter}</div>;
      default:
        return <div className="severityCircle temp">{letter}</div>;
    }
  }

  getData(id) {
    this.props.dispatch(getDetailedHistoryData(id));
  }

  render() {
    const { fetching, fetched, error, results } = this.props.historyData;
    return (
      <Grid id="HistoryDashboardPage">
        {fetching && <Loading loadingText="Loading Table..." />}
        {!fetching && !fetched && error && (
          <Row className="error-container">
            Something went wrong when loading your configuration. <br />
            <button
              type="button"
              className="try-again"
              onClick={() => this.getData(this.props.match.params.config_id)}
            >
              Try Again
            </button>
          </Row>
        )}
        {!fetching && fetched && (
          <Row>
            <div className="locationConfigToggle">
              <div className="availabilityIntegrityContainer">
                <h4 className="severity availability">
                  {HistoryDashboard.setAvailInteg(results.availability.rc, 'A')}
                </h4>
                <h4 className="severity integrity">
                  {HistoryDashboard.setAvailInteg(results.integrity.rc, 'I')}
                </h4>
              </div>
              <h5>{`${results.configuration.domain}`}</h5>
              <h5>{`${results.configuration.city_or_region},  ${results.configuration.country}`}</h5>
            </div>
            <div className="locationConfigContainer">
              <div className="date">
                {results.date_executed
                  ? moment(results.date_executed).format('YYYY-MM-DD HH:mm:ss')
                  : '-'}
              </div>
              <DataTable
                noChange
                domainName={results.configuration.domain}
                locationConfig={{
                  ...results,
                  expirationStatus: 'unavailable',
                }}
                copyConf={results.copy_conf}
              />
            </div>
          </Row>
        )}
      </Grid>
    );
  }
}

function mapStateToProps(store) {
  return {
    historyData: store.historyDetailed,
  };
}

HistoryDashboard.propTypes = {
  dispatch: PropTypes.func.isRequired,
  historyData: PropTypes.shape({
    fetching: PropTypes.bool,
    fetched: PropTypes.bool,
    error: PropTypes.any,
    results: PropTypes.object,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      domain_id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default connect(mapStateToProps)(HistoryDashboard);
