// default imports
import GlyphButton from 'Components/GlyphButton/GlyphButton';
import InformationModal from 'Components/InformationModal/InformationModal';
// custom components
import SelectLocations from 'Components/SelectLocations/SelectLocations';
import StatusIcon from 'Components/StatusIcon';
import { SUBSCRIPTION_FREE } from 'Constants/subscriptionTypes';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Button, Row, Col, Modal } from 'react-bootstrap';

import StorageService from '../../../../utils/StorageService';
// styling
import './DomainInformation.css';

export class DomainInformation extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      saveConfigIcon: '',
      showModal: false,
      showRemoveDomainModal: false,
      hasChanged: false,
      nrOfConfiguredLocations: props.domain.locations.length,
      locationsError: '',
      activeLocations: props.domain.locations,
      error: '',
      extraLocationsAmount: null,
      showAddCostModal: false,
    };
    this.submitNewLocations = this.submitNewLocations.bind(this);
    this.addLocations = this.addLocations.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.domain !== this.props.domain) {
      this.setState({
        saveConfigIcon: '',
        showModal: false,
        showRemoveDomainModal: false,
        hasChanged: false,
        nrOfConfiguredLocations: nextProps.domain.locations.length,
        locationsError: '',
        activeLocations: nextProps.domain.locations,
        error: '',
        extraLocationsAmount: null,
        showAddCostModal: false,
      });
    }
  }

  toggleReconfigure = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  toggleDeleteModal = () => {
    this.setState({
      showRemoveDomainModal: !this.state.showRemoveDomainModal,
    });
  };

  cancelCostModal = () => {
    this.setState({
      showAddCostModal: false,
      saveConfigIcon: '',
    });
  };

  updateCost = (activeLocations, validNrOfLocations) => {
    this.setState({
      hasChanged: true,
      validNrOfLocations,
      activeLocations,
    });
  };

  retrieveNameServers = () => {
    const { locations } = this.props.domain;
    if (!locations[0] || !locations[0].configuration || !locations[0].configuration.dns.length) {
      return [<li key="no-server">No name servers available for domain.</li>];
    }
    return _.map(locations[0].configuration.dns, (nameServer) => (
      <li key={nameServer.ip}>
        {' '}
        {nameServer.name} / {nameServer.ip}{' '}
      </li>
    ));
  };

  async addLocations() {
    const { activeLocations } = this.state;
    const { FetchService, domain, getDomainConfiguration } = this.props;
    const info = {
      location_ids: _.map(activeLocations, (loc) => loc.location_id),
      name: domain.name,
      subscription_type: domain.subscription_type,
    };

    await FetchService.addLocation(domain.id, info)
      .then((resp) => {
        if (resp.error) {
          this.setState({
            saveConfigIcon: 'fas fa-times error',
            error: resp.error,
          });
        } else {
          this.setState(
            {
              saveConfigIcon: 'fas fa-check success',
            },
            () => {
              setTimeout(() => {
                getDomainConfiguration();
              }, 500);
            },
          );
        }
      })
      .catch(() => {
        this.setState({
          saveConfigIcon: 'fas fa-times error',
          error: 'Something went wrong. Try again later.',
        });
      });
  }

  submitNewLocations() {
    this.setState({
      saveConfigIcon: 'fas fa-spinner fa-spin fa-fw in-progress',
      error: '',
    });
    const { activeLocations, nrOfConfiguredLocations } = this.state;
    const { subscription } = this.props;
    const freeDomain = this.props.domain.subscription_type === SUBSCRIPTION_FREE;

    if (
      activeLocations.length === 3 ||
      activeLocations.length <= nrOfConfiguredLocations ||
      this.props.authentication.payment === 'MANUALLY_PAYING_CUSTOMER'
    ) {
      // inside the 3 included locations or still the same number of locations
      this.addLocations();
    } else if (!freeDomain) {
      const freeSlots =
        subscription.extra_locations.quantity - subscription.extra_locations.allocated;
      const extraLocationsAmount = activeLocations.length - freeSlots - nrOfConfiguredLocations;
      if (extraLocationsAmount > 0) {
        // Stripe customer with insufficient amount of locations slots
        this.setState({ extraLocationsAmount, showAddCostModal: true });
      } else {
        // Invoice customer or stripe with sufficient amount of location slots
        this.addLocations();
      }
    } else {
      this.setState({
        saveConfigIcon: 'fas fa-times error',
        error:
          'A free domain subscription includes 3 locations. Please upgrade your subscription to add more locations.',
      });
    }
  }

  addExtraLocations = () => {
    const { subscription, FetchService } = this.props;
    const { extraLocationsAmount } = this.state;
    this.setState({ showAddCostModal: false, error: '' });
    FetchService.updateSubscription(subscription.id, {
      domains: {
        quantity: subscription.domains.quantity,
      },
      basic_domains: {
        quantity: subscription.basic_domains.quantity,
      },
      extra_locations: {
        quantity: extraLocationsAmount + subscription.extra_locations.quantity,
      },
      extra_resource_records: {
        quantity: subscription.extra_resource_records.quantity,
      },
      billing_interval: subscription.billing_interval,
    })
      .then((resp) => {
        if (resp.error) {
          this.setState({
            saveConfigIcon: 'fas fa-times error',
            error: resp.error,
          });
        } else if (
          resp.extra_locations.quantity ===
          subscription.extra_locations.quantity + extraLocationsAmount
        ) {
          this.addLocations();
          this.setState({ extraLocationsAmount: null });
        } else {
          this.setState({
            saveConfigIcon: 'fas fa-times error',
            error: 'There was a problem adding the location to subscription. Try again later.',
          });
        }
      })
      .catch(() => {
        this.setState({
          saveConfigIcon: 'fas fa-times error',
          error: 'Something went wrong. Try again later.',
        });
      });
  };

  deleteDomain = async () => {
    const { id } = this.props.domain;
    const response = await this.props.deleteDomain(id);
    const { error } = response;
    if (error) {
      this.toggleDeleteModal();
    }
  };

  render() {
    const { domain, reconfigureErrors, isDeleting, reconfigureInProgress, plans, subscription } =
      this.props;
    const { activeLocations, nrOfConfiguredLocations, hasChanged, saveConfigIcon } = this.state;
    const isReadOnlyMode = StorageService.getReadOnlyMode();
    const changedNrOfLocations = hasChanged ? activeLocations.length - nrOfConfiguredLocations : 0;
    const locationPlan = plans.find((plan) => plan.name.substring(0, 8) === 'location') || {};
    return (
      <div>
        <Row>
          <Col md={6}>
            <Row>
              <Col xs={12} className="col-left-padded">
                <p className="configureSubHeading">Name Servers</p>
                <ul className="name-server-list">{this.retrieveNameServers()}</ul>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="col-left-padded col-configuration">
                {/* Domain ID is the Domain Configuration ID */}
                <p className="configureSubHeading">Domain ID</p>
                <Col xs={10} className="text-config-id">
                  {domain.domain_id}
                </Col>
                <Col xs={2} className="col-clipboard">
                  <GlyphButton
                    glyph="copy"
                    tooltip="Copy Domain ID"
                    glyphOnClick={() => navigator.clipboard.writeText(domain.domain_id)}
                    classNames="glyphicon-button glyph-copy text-info"
                  />
                </Col>
                {!isReadOnlyMode && (
                  <Button
                    className="btn btn-primary"
                    disabled={reconfigureInProgress}
                    onClick={this.toggleReconfigure}
                  >
                    Reconfigure
                  </Button>
                )}
                {reconfigureInProgress && (
                  <div className="reconfigure-icon">
                    <i className="fas fa-spinner fa-spin fa-fw in-progress" />
                  </div>
                )}
                {!!reconfigureErrors && (
                  <div className="error-box error" style={{ marginTop: '5px', marginLeft: '0' }}>
                    {reconfigureErrors}
                  </div>
                )}
                <Modal
                  show={this.state.showModal}
                  onHide={this.toggleReconfigure}
                  className="danger-modal"
                >
                  <div className="modal-container">
                    <div className="modal-text">
                      Are you sure you want to reconfigure this domain?
                    </div>
                    <div className="modal-buttons">
                      <Button bsStyle="primary" onClick={this.toggleReconfigure}>
                        Close
                      </Button>
                      <Button
                        bsStyle="danger"
                        onClick={() => {
                          this.toggleReconfigure();
                          this.props.reconfigureDomain();
                        }}
                      >
                        Reconfigure
                      </Button>
                    </div>
                  </div>
                </Modal>
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <SelectLocations
              locationMessage={
                changedNrOfLocations > 0
                  ? `Add ${changedNrOfLocations} locations`
                  : `${
                      changedNrOfLocations < 0
                        ? `Remove ${changedNrOfLocations * -1} locations`
                        : ''
                    }`
              }
              disabled={this.props.disabled}
              locations={this.props.locations.locations}
              activeLocations={this.state.activeLocations}
              updateLocations={this.updateCost}
              locationsError={this.state.locationsError}
            />
          </Col>
        </Row>
        {!isReadOnlyMode && (
          <Row className="save-locations">
            <Col md={3}>
              <Button className="btn-danger remove-config" onClick={this.toggleDeleteModal}>
                Delete domain
              </Button>
            </Col>
            <Col md={6} className="save-config">
              {this.state.error && (
                <div
                  className="error error-box error-message"
                  style={{ display: 'inline-block', whiteSpace: 'normal' }}
                >
                  {this.state.error}
                </div>
              )}
            </Col>
            <Col md={3} className="save-config">
              <div className="save-config-icon">
                <i className={saveConfigIcon} />
              </div>
              <Button
                disabled={!this.state.validNrOfLocations || !this.state.hasChanged}
                className="btn-success save-config"
                onClick={this.submitNewLocations}
              >
                Save
              </Button>
            </Col>
          </Row>
        )}
        <InformationModal
          showModal={this.state.showRemoveDomainModal}
          closeCallback={this.toggleDeleteModal}
          modalHeader="Remove domain"
        >
          <div className="modal-container">
            <div className="modal-text">
              You are about to terminate the monitoring of this domain. All monitoring data for this
              domain will be lost. After the domain has been deleted please make sure to adjust the
              number of purchased domains in the Manage Subscription menu.
            </div>
            <div
              className="modal-buttons"
              style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
            >
              {isDeleting && (
                <div className="text-left">
                  Deleting the domain will take up to 30 seconds to complete, so please be patient.
                </div>
              )}
              <div>
                <StatusIcon inProgress={isDeleting} error={!!this.state.error} />
              </div>
              <Button
                className="btn-primary"
                disabled={isDeleting}
                onClick={this.toggleDeleteModal}
              >
                No
              </Button>
              <Button bsStyle="danger" disabled={isDeleting} onClick={this.deleteDomain}>
                Yes
              </Button>
              {this.state.error && (
                <div className="error error-box error-message">{this.state.error}</div>
              )}
            </div>
          </div>
        </InformationModal>

        <InformationModal
          showModal={this.state.showAddCostModal}
          closeCallback={this.cancelCostModal}
          modalHeader="Update subscription"
        >
          <div className="modal-container">
            <div className="modal-text">
              <span style={{ display: 'block' }}>
                Each additional location will add an additional $
                {subscription.billing_interval === 'yearly'
                  ? (Number(locationPlan.yearly_price) / 12).toFixed(2)
                  : locationPlan.monthly_price}{' '}
                / month to your account.
              </span>
              <span style={{ display: 'block' }}>Are you sure?</span>
            </div>
            <div className="modal-buttons">
              <Button onClick={this.cancelCostModal} className="btn-danger">
                No
              </Button>
              <Button bsStyle="primary" onClick={this.addExtraLocations} className="btn-success">
                Yes
              </Button>
              {this.state.error && (
                <div className="error error-box error-message">{this.state.error}</div>
              )}
            </div>
          </div>
        </InformationModal>
      </div>
    );
  }
}

DomainInformation.propTypes = {
  authentication: PropTypes.shape().isRequired,
  domain: PropTypes.shape({
    locations: PropTypes.array,
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.array,
  }).isRequired,
  reconfigureDomain: PropTypes.func.isRequired,
  FetchService: PropTypes.shape({
    getSubscriptions: PropTypes.func,
    updateSubscription: PropTypes.func,
    addLocation: PropTypes.func,
  }).isRequired,
  reconfigureInProgress: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  reconfigureErrors: PropTypes.string.isRequired,
  getDomainConfiguration: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool,
  deleteDomain: PropTypes.func.isRequired,
  subscription: PropTypes.shape({
    id: PropTypes.string,
    domains: PropTypes.shape({
      allocated: PropTypes.number.isRequired,
      quantity: PropTypes.number.isRequired,
    }),
    basic_domains: PropTypes.shape({
      allocated: PropTypes.number.isRequired,
      quantity: PropTypes.number.isRequired,
    }),
    extra_locations: PropTypes.shape({
      allocated: PropTypes.number.isRequired,
      quantity: PropTypes.number.isRequired,
    }),
    extra_resource_records: PropTypes.shape({
      allocated: PropTypes.number.isRequired,
      quantity: PropTypes.number.isRequired,
    }),
    billing_interval: PropTypes.string,
  }).isRequired,
  plans: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

DomainInformation.defaultProps = {
  disabled: true,
  isDeleting: false,
};

export default DomainInformation;
