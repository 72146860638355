import 'whatwg-fetch';

import StorageService from './StorageService';

export const baseAPI = process.env.REACT_APP_BASE_API;

// ////////////////////////////////////////////////////////////////////
// ///////////                                         ////////////////
// ///////////                General                  ////////////////
// ///////////                                         ////////////////
// ////////////////////////////////////////////////////////////////////

async function checkStatus(response) {
  if (response.status === 204) {
    return Promise.resolve(response);
  }
  if (response.status === 401) {
    // TODO: Fix Better Check
    StorageService.logout();
  } else if (response.status === 500) {
    response.error = 'Server error';
    return Promise.resolve(response);
  } else {
    try {
      const data = await response.json();
      if (response.status >= 200 && response.status < 400) {
        return Promise.resolve(data);
      }
      const error = new Error();
      error.response = data;
      return Promise.reject(data);
    } catch (err) {
      const error = new Error(response.statusText);
      error.response = response;
      return Promise.reject(response.statusText);
    }
  }
  return Promise.reject(response.statusText);
}

// ////////////////////////////////////////////////////////////////////
// ///////////                                         ////////////////
// ///////////                Base API                 ////////////////
// ///////////                                         ////////////////
// ////////////////////////////////////////////////////////////////////

export const baseFetch = async (url, { headers, method, ignoreImpersonate, ...options }) => {
  const newHeaders = headers;
  if (!ignoreImpersonate) {
    const impersonatedUser = StorageService.getImpersonatedUser();
    if (impersonatedUser) {
      newHeaders['IP-ID'] = impersonatedUser.id;
    }
  }
  const response = await fetch(url, {
    headers: newHeaders,
    method,
    ...options,
  });
  const data = await checkStatus(response);
  return data;
};

export function getBaseAPI(path, headers = {}) {
  const data = baseFetch(baseAPI + path, {
    method: 'GET',
    headers,
  });
  return data;
}

export function updateBaseAPI(path, type, data, headers) {
  const func = baseFetch(baseAPI + path, {
    method: type,
    headers,
    body: JSON.stringify(data),
  });
  return func;
}

export function deleteBaseAPI(path, headers) {
  const data = baseFetch(baseAPI + path, {
    method: 'DELETE',
    headers,
  });
  return data;
}

export function get(url, headers) {
  const data = baseFetch(url, {
    method: 'GET',
    headers,
  });
  return data;
}

const httpService = { getBaseAPI, updateBaseAPI, deleteBaseAPI };
export default httpService;
