// default components
// Actions
import { login } from 'Actions/authentication';
// custom components
import SSLSeal from 'Components/SSLSeal/SSLSeal';
// styling
import logo from 'assets/images/logo_color.svg';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Component } from 'react';
import {
  Button,
  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import './Login.css';

export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: '',
      password: '',
      process: '',
      errorMsg: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);
  }

  componentWillMount() {
    if (this.props.authenticated) {
      this.props.history.push('/dashboard');
    }
    const query = queryString.parse(this.props.location.search);
    if (query) {
      this.setState({
        user: query.user ? query.user : '',
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.authenticated) {
      this.props.history.push('/dashboard');
    }
    if (Object.keys(nextProps.errors).length > 0) {
      this.setState({
        process: 'fas fa-times error',
        errorMsg: nextProps.errors.description.description,
      });
    }
  }

  handleUserChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({
      process: 'fas fa-spinner fa-spin in-progress',
      errorMsg: '',
    });
    this.props.dispatch(login(this.state.user, this.state.password));
  }

  render() {
    return (
      <Grid id="login">
        <Row>
          <Col md={4} mdOffset={4} xs={12} className="sign-up-content">
            <img src={logo} alt="DNS monitor signup" className="signUpLogo" />
            <Form horizontal onSubmit={this.handleSubmit}>
              <Row>
                <Col xs={12}>
                  <FormGroup controlId="user">
                    <Col componentClass={ControlLabel} id="control-label" xs={10} xsOffset={1}>
                      E-mail:
                    </Col>
                    <Col xs={10} xsOffset={1}>
                      <FormControl
                        type="email"
                        name="user"
                        placeholder="name@example.com"
                        value={this.state.user}
                        onChange={this.handleUserChange}
                        autoComplete="username"
                      />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FormGroup controlId="password">
                    <Col componentClass={ControlLabel} id="control-label" xs={10} xsOffset={1}>
                      Password:
                    </Col>
                    <Col xs={10} xsOffset={1}>
                      <FormControl
                        type="password"
                        name="password"
                        placeholder="*******"
                        value={this.state.password}
                        onChange={this.handleUserChange}
                        autoComplete="current-password"
                      />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="error-message">
                <Col xs={11}>{this.state.errorMsg}</Col>
              </Row>
              <Row className="submit-buttons">
                <Col xs={11}>
                  <Button type="submit" className="btn btn-primary loginBtn">
                    Login
                  </Button>
                </Col>
                <Col xs={1} style={{ padding: '0px', textAlign: 'left', marginTop: '7px' }}>
                  <i className={this.state.process} />
                </Col>
              </Row>
            </Form>
            <Row className="agreement">
              <i>
                Don&apos;t remember your password?&nbsp;
                <Link to={{ pathname: 'forgot-password', search: `?user=${this.state.user}` }}>
                  <b className="term-link">Click here!</b>
                </Link>
              </i>
            </Row>
          </Col>
        </Row>
        <Row className="sign-up" style={{ marginTop: '3rem' }}>
          <i>Don&apos;t have an account?</i>
          <Button
            className="btn btn-primary"
            style={{ marginLeft: '1rem' }}
            onClick={() => this.props.history.push('/sign-up')}
          >
            Sign-up
          </Button>
        </Row>
        <Row>
          <Col className="sslSeal">
            <SSLSeal />
          </Col>
        </Row>
      </Grid>
    );
  }
}

Login.propTypes = {
  errors: PropTypes.shape({ description: PropTypes.shape({ description: PropTypes.string }) }),
  authenticated: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  location: PropTypes.shape({ search: PropTypes.string.isRequired }).isRequired,
};

Login.defaultProps = {
  errors: { description: { description: '' } },
};

const mapStateToProps = (state) => ({
  errors: state.authentication.errors,
  authenticated: state.authentication.authenticated,
});

export default connect(mapStateToProps)(Login);
