// default imports
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Col, Row, FormControl, FormGroup, ControlLabel } from 'react-bootstrap';
// custom components
import GenerateUserMetadata from 'utils/GenerateUserMetadata';

// styling
import './AddDomain.css';

export class AddDomain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      domainName: '',
      errorMessage: '',
      processIcon: '',
      processing: false,
      subscriptionType: '0',
    };

    this.updateDomainName = this.updateDomainName.bind(this);
    this.createNewDomain = this.createNewDomain.bind(this);
    this.generateNewDomain = this.generateNewDomain.bind(this);
  }

  updateDomainName(event) {
    this.setState({
      domainName: event.target.value,
    });
  }

  generateNewDomain(update = '') {
    const { domainName } = this.state;
    const { locations } = this.props;

    this.props.FetchService.addDomain({
      name: domainName,
      location_ids: GenerateUserMetadata.defaultLocations(locations.locations),
      subscription_type: this.state.subscriptionType,
    })
      .then((resp) => {
        if (resp.error) {
          if (update) {
            this.props.history.push('/payment');
          } else {
            resp.error.response
              .json()
              .then((err) => {
                this.setState({
                  errorMessage: err.domain ? err.domain : "Domain couldn't get configured",
                  processIcon: 'fas fa-times error',
                  processing: false,
                });
              })
              .catch(() => {
                this.setState({
                  errorMessage: "Domain couldn't be configured",
                  processIcon: 'fas fa-times error',
                  processing: false,
                });
              });
          }
        } else {
          this.setState({
            errorMessage: '',
            processIcon: 'fas fa-check success',
            processing: false,
          });
          this.props.domainAdded(resp.name, resp);
        }
      })
      .catch((error) => {
        if (update) {
          this.props.history.push('/payment');
        } else if (error.response !== undefined) {
          error.response
            .json()
            .then((err) => {
              this.setState({
                errorMessage: err.domain ? err.domain : "Domain couldn't be configured",
                processIcon: 'fas fa-times error',
                processing: false,
              });
            })
            .catch(() => {
              this.setState({
                errorMessage: "Domain couldn't be configured",
                processIcon: 'fas fa-times error',
                processing: false,
              });
            });
        } else if (error !== undefined) {
          let message = '';
          if (error.detail) {
            message = error.detail;
          } else if (error.name) {
            message = error.name;
          } else if (error.non_field_errors) {
            message = error.non_field_errors;
          } else {
            message = "Domain couldn't be configured";
          }

          this.setState({
            errorMessage: message,
            processIcon: 'fas fa-times error',
            processing: false,
          });
        } else {
          this.setState({
            errorMessage: "Domain couldn't be configured",
            processIcon: 'fas fa-times error',
            processing: false,
          });
        }
      });
  }

  createNewDomain(event) {
    event.preventDefault();
    this.setState({
      processIcon: 'fas fa-spinner fa-spin fa-fw in-progress',
      errorMessage: '',
      processing: true,
    });
    if (this.props.manuallyPaying) {
      this.generateNewDomain();
      return;
    }
    this.props.FetchService.getSubscriptions().then((resp) => {
      if (resp.error) {
        this.setState({
          processIcon: 'fas fa-cross error',
          errorMessage:
            "We couldn't update your current subscription. Please contact support@dnsmonitor.com",
          processing: false,
        });
      } else if (this.state.subscriptionType !== '-1') {
        const enoughDomains =
          this.state.subscriptionType === '0'
            ? resp.results[0].domains.quantity > resp.results[0].domains.allocated
            : true;
        const enoughBasicDomains =
          this.state.subscriptionType === '1'
            ? resp.results[0].basic_domains.quantity > resp.results[0].basic_domains.allocated
            : true;
        if (
          (enoughDomains && this.state.subscriptionType === '0') ||
          (enoughBasicDomains && this.state.subscriptionType === '1')
        ) {
          this.generateNewDomain();
        } else {
          const purchaseList = [];
          resp.results.forEach((item) => {
            if (item.status === 'active' || item.status === 'trialing') {
              purchaseList.push(item);
            }
          });
          if (purchaseList.length === 1) {
            const domainInfo = purchaseList[0];
            const nrDomains = enoughDomains
              ? resp.results[0].domains.quantity
              : resp.results[0].domains.allocated + 1;
            const nrBasicDomains = enoughBasicDomains
              ? resp.results[0].basic_domains.quantity
              : resp.results[0].basic_domains.allocated + 1;
            this.props.FetchService.updateSubscription(domainInfo.id, {
              domains: {
                quantity: nrDomains,
              },
              basic_domains: {
                quantity: nrBasicDomains,
              },
              extra_locations: {
                quantity: 0,
                ...this.props.subscriptionInformation.extra_locations,
              },
              extra_resource_records: {
                quantity: 0,
                ...this.props.subscriptionInformation.extra_resource_records,
              },
              billing_interval: this.props.subscriptionInformation.billing_interval,
            }).then((response) => {
              if (response.error) {
                this.setState({
                  processIcon: 'fas fa-cross error',
                  errorMessage:
                    "We couldn't update your current subscription. Please contact support@dnsmonitor.com",
                  processing: false,
                });
              }
              this.generateNewDomain();
            });
          } else if (purchaseList.length > 1) {
            const error = new Error({ status: 500 });
            error.response = resp;
            throw error;
          }
        }
      }
    });
  }

  validDomain() {
    const validName = this.validDomainName();
    const domainNotAlreadyOwned = true;
    const selectedSubscriptionType = this.state.subscriptionType !== '-1';
    return validName && domainNotAlreadyOwned && selectedSubscriptionType;
  }

  validDomainName() {
    if (
      /^(?=.{1,253}\.?$)(?:(?!-|[^.]+_)[A-Za-z0-9-]{1,63}(?:\.|$)){2,}$/.test(
        this.state.domainName,
      ) &&
      !this.state.domainName.endsWith('-') &&
      this.state.domainName.indexOf('-.') === -1
    ) {
      return true;
    }
    return false;
  }

  userHasAvailableDomainSlots() {
    if (this.props.manuallyPaying) return true;
    const domainQuantity = this.props.subscriptionInformation.domains.quantity;
    const allocatedDomains = this.props.subscriptionInformation.domains.allocated;
    return domainQuantity > allocatedDomains;
  }

  userHasAvailableBasicDomainSlots() {
    if (this.props.manuallyPaying) return true;
    const basicDomainQuantity = this.props.subscriptionInformation.basic_domains.quantity;
    const allocatedBasicDomains = this.props.subscriptionInformation.basic_domains.allocated;
    return basicDomainQuantity > allocatedBasicDomains;
  }

  render() {
    const { subscriptionInformation, hasFree, plans, manuallyPaying } = this.props;

    const domainPlan = plans.find((plan) => plan.name.substring(0, 14) === 'domain') || {};
    const basicDomainPlan =
      plans.find((plan) => plan.name.substring(0, 12) === 'basic_domain') || {};
    return (
      <Col xs={12}>
        <Col md={12}>
          <Row>
            <Col xs={12} className="form-content">
              <Row>
                <form className="add-domain-row" onSubmit={this.createNewDomain}>
                  <Col xs={6}>
                    <FormGroup className="domain-name">
                      <Col componentClass={ControlLabel}>Domain Name:</Col>
                      <FormControl
                        type="text"
                        placeholder="dnsmonitor.com"
                        className="input-domain"
                        value={this.state.domainName}
                        onChange={(e) => this.updateDomainName(e)}
                        autoFocus
                      />
                    </FormGroup>
                  </Col>
                  {!manuallyPaying && (
                    <Col xs={4}>
                      <FormGroup controlId="form-select">
                        <Col componentClass={ControlLabel}>Subscription type:</Col>
                        <FormControl
                          componentClass="select"
                          value={this.state.subscriptionType}
                          onChange={(event) =>
                            this.setState({
                              subscriptionType: event.target.value,
                            })
                          }
                        >
                          <option value="0">Premium</option>
                          <option value="1">Basic</option>
                          <option value="2" disabled={hasFree}>
                            Free
                          </option>
                        </FormControl>
                      </FormGroup>
                    </Col>
                  )}
                  <Col xs={2} className="inline">
                    <Button type="submit" disabled={!this.validDomain()} className="btn-success">
                      Apply
                    </Button>
                    <div className="save-config-icon">
                      <i className={this.state.processIcon} />
                    </div>
                  </Col>
                </form>
              </Row>
              <Row>
                <Col xs={12} sm={8} md={8}>
                  {!manuallyPaying &&
                    ((!this.userHasAvailableDomainSlots() &&
                      this.state.subscriptionType === '0' && (
                        <p>
                          Adding this domain will increase your subscription by $
                          {subscriptionInformation.billing_interval === 'yearly'
                            ? domainPlan.yearly_price / 12
                            : domainPlan.monthly_price}{' '}
                          / month
                        </p>
                      )) ||
                      (!this.userHasAvailableBasicDomainSlots() &&
                        this.state.subscriptionType === '1' && (
                          <p>
                            Adding this domain will increase your subscription by $
                            {subscriptionInformation.billing_interval === 'yearly'
                              ? basicDomainPlan.yearly_price / 12
                              : basicDomainPlan.monthly_price}{' '}
                            / month
                          </p>
                        )))}
                </Col>
                {!!this.state.processing && (
                  <Col xs={6} sm={3} smOffset={1} md={3} mdOffset={1}>
                    <span>Configuring domain. This may take up to 30 seconds.</span>
                  </Col>
                )}
              </Row>
              <div className="error error-box error-message">{this.state.errorMessage}</div>
            </Col>
          </Row>
        </Col>
      </Col>
    );
  }
}

AddDomain.propTypes = {
  FetchService: PropTypes.shape({
    getSubscriptions: PropTypes.func,
    addDomain: PropTypes.func,
    updateSubscription: PropTypes.func,
  }).isRequired,
  domainAdded: PropTypes.func.isRequired,
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.array,
  }).isRequired,
  subscriptionInformation: PropTypes.shape({
    domains: PropTypes.shape({
      quantity: PropTypes.number,
      allocated: PropTypes.number,
    }),
    basic_domains: PropTypes.shape({
      quantity: PropTypes.number,
      allocated: PropTypes.number,
    }),
    extra_locations: PropTypes.shape({
      quantity: PropTypes.number,
      allocated: PropTypes.number,
    }),
    extra_resource_records: PropTypes.shape({
      quantity: PropTypes.number,
      allocated: PropTypes.number,
    }),
    billing_interval: PropTypes.string,
  }).isRequired,
  plans: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  manuallyPaying: PropTypes.bool.isRequired,
  hasFree: PropTypes.bool.isRequired,
};

export default AddDomain;
