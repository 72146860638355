// default components
// custom components
// styling
import logo from 'assets/images/logo_color.svg';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Button,
  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './ForgotPassword.css';

class Signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: '',
      errorMessage: '',
    };

    this.handleUserChange = this.handleUserChange.bind(this);
    this.getUserValidationState = this.getUserValidationState.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.responseEmail = this.responseEmail.bind(this);
  }

  componentWillMount() {
    const { location } = this.props;
    const query = new URLSearchParams(location.search); // eslint-disable-line no-undef
    if (query) {
      this.setState({
        user: query.get('user') ? query.get('user') : '',
      });
    }
  }

  handleUserChange(e) {
    this.setState({
      user: e.target.value,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.getUserValidationState() === 'success') {
      const { auth } = this.props;
      const { user } = this.state;
      auth.forgotPassword(user, this.responseEmail);
    } else {
      this.setState({
        errorMessage: 'Invalid email',
      });
    }
  }

  getUserValidationState() {
    const { user } = this.state;
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (user.length > 0) {
      if (re.test(user)) {
        return 'success';
      }
      return 'error';
    }
    return 'error';
  }

  responseEmail(text) {
    this.setState({
      errorMessage: '',
    });
    // eslint-disable-next-line no-alert
    window.alert(text);
  }

  render() {
    const { user, errorMessage } = this.state;
    return (
      <Grid id="forgot-password">
        <Row>
          <Col md={4} mdOffset={4} xs={12} className="sign-up-content">
            <img src={logo} alt="DNS monitor forgot password" className="signUpLogo" />
            <Form horizontal onSubmit={this.handleSubmit}>
              <Row>
                <Col xs={12}>
                  <FormGroup controlId="user">
                    <Col componentClass={ControlLabel} id="control-label" xs={10} xsOffset={1}>
                      E-mail:
                    </Col>
                    <Col xs={10} xsOffset={1}>
                      <FormControl
                        type="text"
                        placeholder="name@example.com"
                        value={user}
                        onChange={this.handleUserChange}
                      />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>

              <Row className="error-message">
                <Col xs={11}>{errorMessage}</Col>
              </Row>
              <Row className="submit-buttons">
                <Col xs={11}>
                  <Link
                    style={{ color: '#4c4c4c', textDecoration: 'none' }}
                    to={{ pathname: 'login', search: `?user=${user}` }}
                  >
                    <Button className="btn ">Cancel</Button>
                  </Link>
                  <Button type="submit" className="btn btn-primary">
                    Reset
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Grid>
    );
  }
}

Signup.propTypes = {
  auth: PropTypes.shape({ forgotPassword: PropTypes.func.isRequired }).isRequired,
  location: PropTypes.shape({ search: PropTypes.string.isRequired }).isRequired,
};

export default Signup;
