// default imports
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Row, Col } from 'react-bootstrap';

export class Loading extends PureComponent {
  render() {
    return (
      <Row>
        <Col xs={12} style={{ textAlign: 'center', fontSize: '20px' }}>
          <i className="fas fa-spinner fa-spin fa-fw" />
        </Col>
        <Col
          xs={12}
          style={{
            paddingTop: '5px',
            fontWeight: '700',
            paddingLeft: '25px',
            textAlign: 'center',
          }}
        >
          {this.props.loadingText}
        </Col>
      </Row>
    );
  }
}
Loading.propTypes = {
  loadingText: PropTypes.string,
};
Loading.defaultProps = {
  loadingText: '',
};
export default Loading;
