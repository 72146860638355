import * as types from 'Constants/actionTypes';
import { SUBSCRIPTION_PREMIUM } from 'Constants/subscriptionTypes';
import FetchService from 'utils/FetchService';
import StorageService from 'utils/StorageService';

export function getDomainMonitorData(id) {
  return FetchService.getDomainDashboardData(id)
    .then((Response) => {
      if (Response.error) {
        return {
          error: Response.error,
        };
      }
      const item = { ...Response };
      let highestIntegrityRC = -1;
      let highestAvailabilityRC = -1;
      let lowestPercentageIntegrity = 999999;
      let lowestPercentageAvailability = 99999;
      item.locations.forEach((location) => {
        if (location.data[0]) {
          location.data = Object.assign({}, location.data[0]); // eslint-disable-line
          if (
            highestAvailabilityRC < location.data.availability.rc &&
            lowestPercentageAvailability > location.data.availability.percent
          ) {
            item.availability = location.data.availability;
            lowestPercentageAvailability = location.data.availability.percent;
            highestAvailabilityRC = location.data.availability.rc;
          }
          if (
            highestIntegrityRC < location.data.integrity.rc &&
            lowestPercentageIntegrity > location.data.integrity.percent
          ) {
            highestIntegrityRC = location.data.integrity.rc;
            lowestPercentageIntegrity = location.data.integrity.percent;
            item.integrity = location.data.integrity;
          }
          if (
            item.subscription_type === SUBSCRIPTION_PREMIUM &&
            location.data.domain_check &&
            location.domain_check.expiration.cfg_status === 'active'
          ) {
            item.expiration = {
              ...location.domain_check.expiration,
              ...location.data.domain_check.expiration,
            };
          }
        }
      });

      if (item.availability && item.integrity) {
        item.severity =
          item.availability.rc > item.integrity.rc ? item.availability : item.integrity;
        item.severity.type =
          item.availability.rc === item.integrity.rc
            ? `${item.availability.rc > 0 && 'Availability '}${
                item.integrity.rc > 0 && 'Integrity'
              }`
            : `${item.availability.rc > item.integrity.rc ? 'Availability' : 'Integrity'}`;
      }
      return item;
    })
    .catch((err) => ({ error: err }));
}

export function sendNewConfiguration(dnsList, hostList, domainCheck, id) {
  return (dispatch) => {
    const obj = { dns: dnsList, host: hostList, domain_check: domainCheck };
    dispatch({ type: types.UPDATE_DOMAIN_CONFIGURATION, newConf: obj });
    FetchService.sendNewConfiguration(obj, id)
      .then((Response) => {
        dispatch({ type: types.UPDATE_DOMAIN_CONFIGURATION_FINISHED, id, newConf: Response });
      })
      .catch(() => {
        dispatch({ type: types.UPDATE_DOMAIN_CONFIGURATION_FINISHED, id: null });
      });
  };
}

export function updateSort(sort) {
  return (dispatch) => {
    StorageService.setSorting(sort);
    dispatch({ type: types.UPDATE_DASHBOARD_SORTING, sort });
  };
}
