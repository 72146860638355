import * as types from 'Constants/actionTypes';

const initialSettings = {
  fetching: false,
  fetched: false,
  results: [],
  error: '',
  pagination: {},
  busy: false,
};

const history = (state = initialSettings, action) => {
  switch (action.type) {
    case types.INIT_HISTORY_SEARCH:
      return {
        ...state,
        fetching: true,
        fetched: false,
        busy: true,
        pagination: {},
      };
    case types.INIT_HISTORY_SEARCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        results: action.results,
        pagination: action.pagination,
        busy: false,
      };
    case types.INIT_HISTORY_SEARCH_ERROR:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.error,
      };
    case types.CHANGE_PAGE:
      return {
        ...state,
        pagination: { ...state.pagination, page: action.page },
      };
    case types.FETCH_NEXT_INIT:
      return {
        ...state,
        busy: true,
      };
    case types.FETCH_NEXT_SUCCESS:
      return {
        ...state,
        busy: false,
        results: [...state.results, ...action.results],
        pagination: {
          ...state.pagination,
          ...action.pagination,
        },
      };
    case types.FETCH_NEXT_ERROR:
      return {
        ...state,
        busy: false,
        error: action.error,
      };
    case types.LOGOUT_USER:
      return { ...initialSettings };
    default:
      return state;
  }
};

export default history;
