// default imports
import { getApiKeys, createApiKey, deleteApiKey, changePage } from 'Actions/apiKeys';
import GlyphButton from 'Components/GlyphButton/GlyphButton';
import InformationModal from 'Components/InformationModal/InformationModal';
// custom components
import Loading from 'Components/Loading/Loading';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Grid, Row, Col, Button, FormControl, FormGroup, ControlLabel } from 'react-bootstrap';
import { connect } from 'react-redux';

import StorageService from '../../utils/StorageService';
// styling
import './ConfigureApiKeys.css';

export class ConfigureApiKeys extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      apiName: '',
      error: '',
      deleteId: '',
      showDeleteModal: false,
    };
    props.getApiKeys();

    this.updateApiName = this.updateApiName.bind(this);
    this.createNewApiKey = this.createNewApiKey.bind(this);
    this.generateNewApiKey = this.generateNewApiKey.bind(this);
    this.removeApiKey = this.removeApiKey.bind(this);
  }

  updateApiName(event) {
    this.setState({
      apiName: event.target.value,
    });
  }

  createNewApiKey(event) {
    event.preventDefault();
    this.generateNewApiKey();
  }

  generateNewApiKey() {
    const { apiName } = this.state;

    this.props.createApiKey(apiName).then((res) => {
      if (!res.error) this.setState({ apiName: '' });
    });
  }

  toggleDeleteModal = (id) => {
    this.setState({
      showDeleteModal: !this.state.showDeleteModal,
      deleteId: id,
    });
  };

  removeApiKey() {
    const { deleteId } = this.state;
    this.setState({ error: '' });
    this.props.deleteApiKey(deleteId).then((res) => {
      if (res.error) this.setState({ error: res.error });
      if (!res.error) this.setState({ showDeleteModal: false, deleteId: '' });
    });
  }

  render() {
    const { apiKeys } = this.props;
    const { apiName, error, showDeleteModal } = this.state;
    const isReadOnlyMode = StorageService.getReadOnlyMode();

    return (
      <Grid>
        <Row>
          <Col lg={8}>
            <div className="sub-config-header">Configured API Keys</div>
            <div className="config-content">
              {apiKeys.fetching && <Loading loadingText="Loading API Keys..." />}
              {!apiKeys.fetching && apiKeys.results.length === 0 && (
                <div>You have no API keys configured</div>
              )}
              {!apiKeys.fetching && apiKeys.results.length > 0 && (
                <div>
                  <div className="key-table">
                    <Row className="key-table-header key-table-entry">
                      <Col xs={4} md={3}>
                        Name:
                      </Col>
                      <Col xs={6} md={7}>
                        Key:
                      </Col>
                    </Row>
                    {apiKeys.results.map((key) => (
                      <Row key={key.id} className="key-table-entry">
                        <Col xs={12}>
                          <Row className="key-details" key={key.id}>
                            <Col xs={3} md={3}>
                              {key.name}
                            </Col>
                            <Col xs={6} md={6}>
                              {key.id}
                            </Col>
                            <Col xs={3} md={3}>
                              <Row>
                                <Col
                                  xs={!isReadOnlyMode ? 6 : 12}
                                  className="col-clipboard row-glyphs"
                                >
                                  <GlyphButton
                                    glyph="copy"
                                    tooltip="Copy key"
                                    glyphOnClick={() => navigator.clipboard.writeText(key.id)}
                                    classNames="glyphicon-button glyph-copy text-info"
                                  />
                                </Col>
                                {!isReadOnlyMode && (
                                  <Col xs={6} className="row-glyphs">
                                    <GlyphButton
                                      glyph="trash"
                                      tooltip="Delete key"
                                      glyphOnClick={() => this.toggleDeleteModal(key.id)}
                                      classNames="glyphicon-button glyph-trash warning"
                                    />
                                  </Col>
                                )}
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    ))}
                  </div>
                  {apiKeys.pagination && apiKeys.pagination.page >= 0 && (
                    <div>
                      <div className="pagination">
                        <Button
                          type="button"
                          className="pagination-button"
                          disabled={apiKeys.pagination.next === null}
                          onClick={() => this.props.changePage(1, apiKeys)}
                        >
                          Load more
                        </Button>
                      </div>
                      {apiKeys.error.page && (
                        <div className="error error-box error-message">{apiKeys.error.page}</div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </Col>
          <Col lg={4}>
            <div className="sub-config-header">Add Key</div>
            <Col xs={12} className="config-content">
              <Col xs={12} className="form-content">
                <form className="add-key-row" onSubmit={this.createNewApiKey}>
                  <Col xs={12} md={8}>
                    <FormGroup className="api-key-name">
                      <Col componentClass={ControlLabel}>Key Name:</Col>
                      <FormControl
                        type="text"
                        placeholder=""
                        className="input-api"
                        value={apiName}
                        onChange={(e) => this.updateApiName(e)}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6} sm={6} smOffset={3} md={4} mdOffset={0} className="inline">
                    <Button
                      type="submit"
                      disabled={(apiName.length === 0 && apiName.length > 255) || isReadOnlyMode}
                      className="btn-success"
                    >
                      Generate
                    </Button>
                  </Col>
                </form>
                {apiKeys.error.add && (
                  <Col xs={12} className="error error-box error-message">
                    {apiKeys.error.add}
                  </Col>
                )}
              </Col>
            </Col>
          </Col>
        </Row>
        <InformationModal
          showModal={showDeleteModal}
          closeCallback={() => this.toggleDeleteModal('')}
          modalHeader="Delete API Key"
        >
          <div className="modal-container">
            <div className="modal-text">Are you sure you want to delete the API Key?</div>
            <div className="modal-buttons">
              <Button className="btn-primary" onClick={() => this.toggleDeleteModal('')}>
                No
              </Button>
              <Button bsStyle="danger" onClick={() => this.removeApiKey()}>
                Yes
              </Button>
            </div>
            {error && <div className="error error-box error-message">{error}</div>}
          </div>
        </InformationModal>
      </Grid>
    );
  }
}
ConfigureApiKeys.propTypes = {
  apiKeys: PropTypes.shape({
    fetching: PropTypes.bool,
    fetched: PropTypes.bool,
    results: PropTypes.array,
    error: PropTypes.object,
    busy: PropTypes.bool,
    creating_api_key: PropTypes.bool,
    deleting_api_key: PropTypes.bool,
    pagination: PropTypes.object,
  }).isRequired,
  getApiKeys: PropTypes.func.isRequired,
  createApiKey: PropTypes.func.isRequired,
  deleteApiKey: PropTypes.func.isRequired,
  changePage: PropTypes.func.isRequired,
};

ConfigureApiKeys.defaultProps = {};

function mapStateToProps(state) {
  return {
    apiKeys: state.apiKeys,
  };
}

export default connect(mapStateToProps, {
  getApiKeys,
  createApiKey,
  deleteApiKey,
  changePage,
})(ConfigureApiKeys);
