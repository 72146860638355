// default imports
import React from 'react';

// styling
import './PageNotFound.css';

function PageNotFound() {
  return (
    <div className="root">
      <h2>Page not found</h2>
    </div>
  );
}

export default PageNotFound;
