import * as types from 'Constants/actionTypes';
import FetchService from 'utils/FetchService';

export function getSubscriptions() {
  return (dispatch) => {
    dispatch({ type: types.INITIATE_GET_SUBSCRIPTIONS });
    FetchService.getSubscriptions().then((Response) => {
      if (Response.error) {
        dispatch({ type: types.ERROR_GET_SUBSCRIPTIONS, Response });
      } else {
        dispatch({ type: types.SUCCESS_GET_SUBSCRIPTIONS, Response });
      }
    });
  };
}

export function getSubscriptionInformation() {
  return FetchService.getSubscriptions()
    .then((Response) => {
      if (Response.error) {
        return {
          error: Response.error,
        };
      }
      if (Response.count === 0) {
        return {};
      }
      return Response.results ? Response.results[0] : {};
    })
    .catch((err) => ({
      error: err,
    }));
}

export function getSubscription(subscriptionId) {
  return (dispatch) => {
    dispatch({ type: types.INITIATE_GET_SUBSCRIPTION });
    FetchService.getSubscription(subscriptionId)
      .then((Response) => {
        dispatch({ type: types.SUCCESS_GET_SUBSCRIPTION, subscription: Response.results });
      })
      .catch((error) => {
        dispatch({ type: types.ERROR_GET_SUBSCRIPTION, errors: error });
      });
  };
}

export function deleteSubscription(subscriptionId) {
  return (dispatch) => {
    dispatch({ type: types.INITIATE_DELETE_SUBSCRIPTION });
    FetchService.deleteSubscription(subscriptionId)
      .then(() => {
        dispatch({ type: types.SUCCESS_DELETE_SUBSCRIPTION });
        dispatch(getSubscriptions());
      })
      .catch(() => {
        dispatch({ type: types.ERROR_DELETE_SUBSCRIPTION, errors: 'error' });
      });
  };
}

export function createSubscription(subscription) {
  return (dispatch) => {
    dispatch({ type: types.INITIATE_CREATE_SUBSCRIPTION });
    FetchService.createSubscription(subscription)
      .then(() => {
        dispatch({ type: types.SUCCESS_CREATE_SUBSCRIPTION });
        dispatch(getSubscriptions());
      })
      .catch(() => {
        dispatch({ type: types.ERROR_CREATE_SUBSCRIPTION, errors: 'error' });
      });
  };
}

export function updateSubscription(subscriptionId, subscription) {
  return async (dispatch) => {
    dispatch({ type: types.INITIATE_UPDATE_SUBSCRIPTION });
    await FetchService.updateSubscription(subscriptionId, subscription)
      .then(() => {
        dispatch({ type: types.SUCCESS_UPDATE_SUBSCRIPTION });
        dispatch(getSubscriptions());
      })
      .catch(() => {
        dispatch({ type: types.ERROR_UPDATE_SUBSCRIPTION, errors: 'error' });
      });
  };
}
