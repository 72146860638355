// default imports
import Loading from 'Components/Loading/Loading';
// custom components
// Constants
import {
  SUBSCRIPTION_PREMIUM,
  SUBSCRIPTION_BASIC,
  SUBSCRIPTION_FREE,
} from 'Constants/subscriptionTypes';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Panel, ListGroup, ListGroupItem } from 'react-bootstrap';

// styling
import './SidebarNavigation.css';

export class SidebarNavigation extends Component {
  constructor(props) {
    super(props);

    this.getLink = this.getLink.bind(this);
  }

  getLink(e, data = '') {
    e.preventDefault();
    this.props.set(data);
  }

  render() {
    const links = [];
    let button = '';
    if (this.props.links) {
      this.props.links.forEach((link) => {
        if (link.component === 'Add a domain') {
          button = (
            <div
              className={this.props.current ? 'list-group-bottom' : 'list-group-bottom active'}
              style={{
                borderBottomRightRadius: '8px',
                borderBottomLeftRadius: '8px',
                color: '#201751',
              }}
              key={link.component}
              onClick={(e) => this.getLink(e, link.component)}
              role="button"
              tabIndex="0"
              onKeyPress={(e) => this.getLink(e, link.component)}
            >
              <b>{link.component}</b>
            </div>
          );
          if (this.props.not_loaded) {
            links.push(
              <ListGroupItem key="loader" onClick={() => {}}>
                <Loading loadingText="Loading domains..." />
              </ListGroupItem>,
            );
          }
        } else {
          links.push(
            <ListGroupItem
              className={
                this.props.current === link.component || this.props.current.name === link.component
                  ? 'active'
                  : ''
              }
              key={link.component}
              onClick={(e) => this.getLink(e, link.component)}
              style={
                this.props.current === link.component || this.props.current.name === link.component
                  ? {
                      borderInlineStart: '5px solid #201751',
                    }
                  : {}
              }
            >
              {link.component}
              <span>
                {link.subscription === SUBSCRIPTION_PREMIUM
                  ? '[P]'
                  : link.subscription === SUBSCRIPTION_BASIC
                  ? '[B]'
                  : link.subscription === SUBSCRIPTION_FREE
                  ? '[F]'
                  : ''}
              </span>
            </ListGroupItem>,
          );
        }
      });
    }

    return (
      <div className="sidebarNavigation">
        <Panel header={this.props.navheader}>
          <ListGroup fill>{links}</ListGroup>
          {button || false}
        </Panel>
      </div>
    );
  }
}

SidebarNavigation.propTypes = {
  navheader: PropTypes.string,
  set: PropTypes.func.isRequired,
  links: PropTypes.arrayOf(PropTypes.object).isRequired,
  current: PropTypes.string.isRequired,
  not_loaded: PropTypes.bool,
};

SidebarNavigation.defaultProps = {
  navheader: '',
  not_loaded: undefined,
};

export default SidebarNavigation;
