import * as types from 'Constants/actionTypes';

const initialSettings = {
  fetching: false,
  adding: false,
  updating: false,
  errors: {},
  subscriptions: {
    results: [],
  },
  busy: false,
};

const subscription = (state = initialSettings, action) => {
  switch (action.type) {
    case types.INITIATE_GET_SUBSCRIPTIONS:
      return { ...state, fetching: true };
    case types.SUCCESS_GET_SUBSCRIPTIONS:
      return {
        ...state,
        fetching: false,
        subscriptions: action.Response,
      };
    case types.ERROR_GET_SUBSCRIPTIONS:
      return {
        ...state,
        fetching: false,
        errors: action.Response.errors,
      };

    case types.INITIATE_GET_SUBSCRIPTION:
      return { ...state, fetching: true };
    case types.SUCCESS_GET_SUBSCRIPTION:
      return {
        ...state,
        fetching: false,
        subscriptions: action.subscriptions,
      };
    case types.ERROR_GET_SUBSCRIPTION:
      return {
        ...state,
        fetching: false,
        errors: action.errors,
      };

    case types.INITIATE_DELETE_SUBSCRIPTION:
      return {
        ...state,
        busy: true,
        errors: '',
      };
    case types.SUCCESS_DELETE_SUBSCRIPTION:
      return {
        ...state,
        busy: false,
      };
    case types.ERROR_DELETE_SUBSCRIPTION:
      return {
        ...state,
        busy: false,
        errors: action.errors,
      };

    case types.INITIATE_CREATE_SUBSCRIPTION:
      return {
        ...state,
        adding: true,
        errors: '',
      };
    case types.SUCCESS_CREATE_SUBSCRIPTION:
      return {
        ...state,
        adding: false,
      };
    case types.ERROR_CREATE_SUBSCRIPTION:
      return {
        ...state,
        adding: false,
        errors: action.errors,
      };

    case types.INITIATE_UPDATE_SUBSCRIPTION:
      return {
        ...state,
        updating: true,
        errors: '',
      };
    case types.SUCCESS_UPDATE_SUBSCRIPTION:
      return {
        ...state,
        updating: false,
      };
    case types.ERROR_UPDATE_SUBSCRIPTION:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      };

    case types.LOGOUT_USER:
      return { ...initialSettings };
    default:
      return state;
  }
};

export default subscription;
