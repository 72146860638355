// default imports
import { getMe } from 'Actions/authentication';
import { getDomainConfiguration, updateDomain } from 'Actions/domains';
import { listResourceRecords } from 'Actions/resourceRecords';
// Actions
import { getSubscriptionInformation } from 'Actions/subscriptions';
// custom components
import Loading from 'Components/Loading/Loading';
// Constants
import {
  SUBSCRIPTION_FREE,
  SUBSCRIPTION_BASIC,
  SUBSCRIPTION_PREMIUM,
} from 'Constants/subscriptionTypes';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Grid, Row, Col, Button } from 'react-bootstrap';
// import Select from 'react-select';
import { connect } from 'react-redux';
import GenerateUserMetadata from 'utils/GenerateUserMetadata';

import StorageService from '../../utils/StorageService';
// styling
import './ConfigureSubscriptions.css';
import ItemsBox from './components/ItemsBox/ItemsBox';
import PaymentBox from './components/PaymentBox/PaymentBox';
import SubscriptionBox from './components/SubscriptionBox/SubscriptionBox';

function getPlanDetails(selectedPlan, plans) {
  return plans.find((plan) => plan.name === selectedPlan) || {};
}

export class ConfigureSubscriptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchingSubscription: true,
      plan: 'yearly',
      error: undefined,
      subscriptionData: {},
      // SubscriptionBox
      selectedDomain: undefined,
      showSubscriptionModal: false,
      showConfirmModal: false,
      update_in_progress: false,
      update_errors: '',
    };
    this.handleSubscriptionChange = this.handleSubscriptionChange.bind(this);
    this.setChangeSubscriptionMode = this.setChangeSubscriptionMode.bind(this);
  }

  componentDidMount() {
    this.fetchSubscriptionInformation();
    this.props.dispatch(getDomainConfiguration());
    this.props.dispatch(listResourceRecords());
  }

  // SubscriptionBox functions
  setAmount = (name, value) => {
    if (name === 'domains') {
      this.setState({
        subscriptionData: {
          ...this.state.subscriptionData,
          domains: {
            ...this.state.subscriptionData.domains,
            quantity: value,
          },
        },
      });
    }
    if (name === 'basic_domains') {
      this.setState({
        subscriptionData: {
          ...this.state.subscriptionData,
          basic_domains: {
            ...this.state.subscriptionData.basic_domains,
            quantity: value,
          },
        },
      });
    }
    if (name === 'locations') {
      this.setState({
        subscriptionData: {
          ...this.state.subscriptionData,
          extra_locations: {
            ...this.state.subscriptionData.extra_locations,
            quantity: value,
          },
        },
      });
    }
    if (name === 'records') {
      this.setState({
        subscriptionData: {
          ...this.state.subscriptionData,
          extra_resource_records: {
            ...this.state.subscriptionData.extra_resource_records,
            quantity: value,
          },
        },
      });
    }
  };

  setChangeSubscriptionMode = (currentSub, newSub) => {
    if (currentSub === SUBSCRIPTION_PREMIUM) {
      this.setState({
        subTypeChange: newSub !== SUBSCRIPTION_PREMIUM ? 'downgrade' : 'nothing',
      });
    } else if (currentSub === SUBSCRIPTION_BASIC) {
      this.setState({
        subTypeChange:
          newSub === SUBSCRIPTION_PREMIUM
            ? 'upgrade'
            : newSub === SUBSCRIPTION_FREE
            ? 'downgrade'
            : 'nothing',
      });
    } else {
      this.setState({
        subTypeChange: newSub !== SUBSCRIPTION_FREE ? 'upgrade' : 'nothing',
      });
    }
  };

  fetchSubscriptionInformation = () => {
    this.setState({
      updatingSubscription: false,
      updateError: undefined,
    });
    getSubscriptionInformation()
      .then((Response) => {
        this.setState({
          fetchingSubscription: false,
          subscriptionData: !Response.error
            ? {
                ...Response,
                domains: Response.domains || {},
                basic_domains: Response.basic_domains || {},
                extra_locations: Response.extra_locations || {},
                extra_resource_records: Response.extra_resource_records || {},
              }
            : {},
          error: Response.error,
          plan: Response.billing_interval,
        });
      })
      .catch((error) => {
        this.setState({
          fetchingSubscription: false,
          error,
        });
      });
  };

  saveSubscription = () => {
    this.setState({
      updatingSubscription: true,
      updateError: undefined,
    });
    const { subscriptionData, plan } = this.state;
    this.props.FetchService.updateSubscription(subscriptionData.id, {
      domains: {
        quantity: subscriptionData.domains.quantity,
      },
      basic_domains: {
        quantity: subscriptionData.basic_domains.quantity,
      },
      extra_locations: {
        quantity: subscriptionData.extra_locations.quantity,
      },
      extra_resource_records: {
        quantity: subscriptionData.extra_resource_records.quantity,
      },
      billing_interval: plan,
    })
      .then((Response) => {
        this.setState({
          subscriptionData: !Response.error
            ? {
                ...Response,
                domains: Response.domains || {},
                basic_domains: Response.basic_domains || {},
                extra_locations: Response.extra_locations || {},
                extra_resource_records: Response.extra_resource_records || {},
              }
            : {},
          plan: Response.billing_interval,
          updatingSubscription: false,
          updateError: undefined,
        });
      })
      .catch((error) => {
        this.setState({
          updatingSubscription: false,
          updateError: error,
        });
      });
  };

  setPlan = (value) => {
    this.setState(
      {
        plan: value,
        subscriptionData: {
          ...this.state.subscriptionData,
          billing_interval: value,
        },
      },
      () => this.saveSubscription(),
    );
  };

  handleSubscriptionChange = () => {
    const domain = this.state.selectedDomain;
    if (this.state.subTypeChange === 'upgrade') {
      const premiumDomains = this.state.subscriptionData.domains;
      const basicDomains = this.state.subscriptionData.basic_domains;
      const unallocPremium = premiumDomains.quantity - premiumDomains.allocated;
      const unallocBasic = unallocPremium + (basicDomains.quantity - basicDomains.allocated);

      if (domain.subscription_type === SUBSCRIPTION_PREMIUM && !unallocPremium) {
        this.setAmount('domains', premiumDomains.quantity + 1);
        this.saveSubscription();
      } else if (domain.subscription_type === SUBSCRIPTION_BASIC && !unallocBasic) {
        this.setAmount('basic_domains', basicDomains.quantity + 1);
        this.saveSubscription();
      }
    }
    const location_ids = GenerateUserMetadata.extractLocations(domain.locations);
    delete domain.locations;
    this.props
      .dispatch(
        updateDomain({
          ...domain,
          location_ids,
        }),
      )
      .then(this.fetchSubscriptionInformation)
      .then(() => {
        this.props.dispatch(getMe());
      });
  };

  render() {
    if (this.state.fetchingSubscription) {
      return (
        <Grid>
          <Loading loadingText="Loading Subscriptions..." />
        </Grid>
      );
    }
    if (this.state.error) {
      return (
        <Grid>
          <Row>
            <Col xs={12}>
              <h3>Something went wrong when trying to load your subscription data.</h3>
              <Button className="btn btn-primary" onClick={this.fetchSubscriptionInformation}>
                Try again!
              </Button>
            </Col>
          </Row>
        </Grid>
      );
    }
    const userSubscribed = this.props.auth.user.is_subscribed;
    if (userSubscribed && !!this.state.subscriptionData.domains) {
      const { subscriptionData } = this.state;

      const { plans, domainStore } = this.props;
      const domainCost =
        getPlanDetails('domain', plans)[`${subscriptionData.billing_interval}_price`] *
          subscriptionData.domains.allocated || 0;
      const basicDomainCost =
        getPlanDetails('basic_domain', plans)[`${subscriptionData.billing_interval}_price`] *
          subscriptionData.basic_domains.allocated || 0;
      const locationCost =
        getPlanDetails('location', plans)[`${subscriptionData.billing_interval}_price`] *
          subscriptionData.extra_locations.allocated || 0;
      const resourceCost =
        getPlanDetails('record', plans)[`${subscriptionData.billing_interval}_price`] *
          subscriptionData.extra_resource_records.allocated || 0;
      const totalCost = domainCost + basicDomainCost + locationCost + resourceCost;

      const isReadOnlyMode = StorageService.getReadOnlyMode();
      return (
        <Grid>
          <SubscriptionBox
            isReadOnlyMode={isReadOnlyMode}
            domainStore={domainStore}
            handleSubscriptionChange={this.handleSubscriptionChange}
            resourceRecords={this.props.resourceRecords.resource_records}
            userEmail={this.props.auth.user.email}
            openInfoModal={this.openInfoModal}
            showConfirmModal={this.state.showConfirmModal}
            closeConfirmModal={() => {
              this.setState({
                showConfirmModal: false,
              });
            }}
            setChangeType={this.setChangeSubscriptionMode}
            changeType={this.state.subTypeChange}
            selectDomain={(domain, subType) => {
              this.setState({
                selectedDomain: {
                  ...domain,
                  subscription_type: Number(subType),
                },
                showConfirmModal: true,
              });
            }}
            selectedDomain={this.state.selectedDomain}
            hasFree={this.props.auth.user.has_free_domain}
          />
          <ItemsBox
            subscriptionData={this.state.subscriptionData}
            openInfoModal={this.openInfoModal}
            showInfoModal={this.state.showItemsModal}
            closeInfoModal={this.closeInfoModals}
          />
          <PaymentBox
            subscriptionData={this.state.subscriptionData}
            isReadOnlyMode={isReadOnlyMode}
            totalCost={totalCost}
            setPlan={this.setPlan}
            dispatch={this.props.dispatch}
          />
        </Grid>
      );
    }
    const { subscriptionData } = this.state;

    const { plans } = this.props;
    const domainCost =
      getPlanDetails('domain', plans)[`${subscriptionData.billing_interval}_price`] *
        subscriptionData.domains.quantity || 0;
    const basicDomainCost =
      getPlanDetails('basic_domain', plans)[`${subscriptionData.billing_interval}_price`] *
        subscriptionData.basic_domains.quantity || 0;
    const locationCost =
      getPlanDetails('location', plans)[`${subscriptionData.billing_interval}_price`] *
        subscriptionData.extra_locations.quantity || 0;
    const resourceCost =
      getPlanDetails('record', plans)[`${subscriptionData.billing_interval}_price`] *
        subscriptionData.extra_resource_records.quantity || 0;
    const totalCost = domainCost + basicDomainCost + locationCost + resourceCost;

    const isReadOnlyMode = StorageService.getReadOnlyMode();
    return (
      <Grid>
        <PaymentBox
          subscriptionData={this.state.subscriptionData}
          isReadOnlyMode={isReadOnlyMode}
          totalCost={totalCost}
          setPlan={this.setPlan}
          dispatch={this.props.dispatch}
        />
      </Grid>
    );
  }
}

ConfigureSubscriptions.propTypes = {
  plans: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  domainStore: PropTypes.shape({
    selected_domain: PropTypes.object,
    fetching: PropTypes.bool,
    reconfigure_in_progress: PropTypes.bool,
    fetched: PropTypes.bool,
    domains: PropTypes.object,
    reconfigure_errors: PropTypes.string.isRequired,
    delete_in_progress: PropTypes.bool,
  }).isRequired,
  invoices: PropTypes.shape({
    fetching: PropTypes.bool,
    fetched: PropTypes.bool,
    data: PropTypes.array,
  }).isRequired,
  paymentStatus: PropTypes.string,
  FetchService: PropTypes.shape().isRequired,
  paymentMethodData: PropTypes.shape({
    fetching: PropTypes.bool,
    fetched: PropTypes.bool,
    adding: PropTypes.bool,
    updating: PropTypes.bool,
    errors: PropTypes.object,
    paymentMethods: PropTypes.shape({
      count: PropTypes.number,
      results: PropTypes.arrayOf(PropTypes.object),
    }),
    busy: PropTypes.bool,
  }).isRequired,
  resourceRecords: PropTypes.shape({
    resource_records: PropTypes.array,
    fetching: PropTypes.bool,
    fetched: PropTypes.bool,
    delete_in_progress: PropTypes.bool,
    add_in_progress: PropTypes.bool,
  }).isRequired,
};

ConfigureSubscriptions.defaultProps = {
  invoices: {
    fetching: true,
    fetched: false,
    data: [],
  },
  resourceRecords: {
    fetching: false,
    fetched: false,
    resource_records: [],
  },
  auth: {
    user: {
      email: '',
    },
  },
};

function mapStateToProps(state) {
  return {
    auth: state.authentication,
    domainStore: state.domains,
    invoices: state.purchases.invoices,
    paymentStatus: state.authentication.payment,
    paymentMethodData: state.paymentMethod,
    plans: state.init.plans,
    resourceRecords: state.resourceRecords,
  };
}

export default connect(mapStateToProps)(ConfigureSubscriptions);
