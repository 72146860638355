import PropTypes from 'prop-types';
import React from 'react';
import { Glyphicon } from 'react-bootstrap';
import TetherComponent from 'react-tether';

// styling
import './GlyphButton.css';

export class GlyphButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      glyphStyle: '',
    };
  }

  render() {
    const { isOpen } = this.state;

    return (
      <TetherComponent
        attachment="bottom center"
        constraints={[
          {
            to: 'scrollParent',
            attachment: 'together',
          },
        ]}
        className="glyphbutton"
      >
        {/* First child: This is what the item will be tethered to */}
        <Glyphicon
          glyph={this.props.glyph}
          onMouseOver={() => {
            this.setState({ isOpen: true });
          }}
          onMouseOut={() => {
            this.setState({ isOpen: false });
          }}
          className={this.props.classNames}
          onClick={this.props.glyphOnClick}
        />
        {/* Second child: If present, this item will be tethered to the the first child */}
        {isOpen && <div className="tooltip-box">{this.props.tooltip}</div>}
      </TetherComponent>
    );
  }
}

GlyphButton.propTypes = {
  glyph: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  clickTooltip: PropTypes.string,
  classNames: PropTypes.string,
  glyphOnClick: PropTypes.func.isRequired,
};

GlyphButton.defaultProps = {};

export default GlyphButton;
