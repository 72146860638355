import * as types from 'Constants/actionTypes';
import FetchService from 'utils/FetchService';
import 'whatwg-fetch';

export const getStaffUsers = (email) => async (dispatch) => {
  try {
    dispatch({ type: types.FETCH_STAFF_USERS_INITIATED });
    const response = await FetchService.getStaffUsers(email);
    dispatch({
      type: types.FETCH_STAFF_USERS_SUCCESS,
      results: response.results,
      pagination: { next: response.next, previous: response.previous, page: 0 },
    });
  } catch (error) {
    dispatch({ type: types.FETCH_STAFF_USERS_ERROR, error: String(error) });
  }
};

export function fetchMoreStaffUsers(data) {
  return (dispatch) => {
    dispatch({ type: types.FETCH_NEXT_INIT });
    FetchService.fetchNextHistory(data.pagination.next).then((response) => {
      if (response.error) {
        dispatch({ type: types.FETCH_NEXT_ERROR, error: response.error });
      } else {
        dispatch({
          type: types.FETCH_NEXT_SUCCESS,
          results: response.results,
          pagination: { next: response.next, previous: response.previous },
        });
      }
    });
  };
}

const staffUsers = {
  fetchMoreStaffUsers,
  getStaffUsers,
};
export default staffUsers;
