// default imports
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
// custom components
import FetchService from 'utils/FetchService';

import AlertList from '../AlertList/AlertList';
// styling
import './EditAlertLevel.css';

export class EditAlertLevel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availability_level: this.props.activeService.availability_level,
      integrity_level: this.props.activeService.integrity_level,
      errorMessage: '',
      addProcessSymbol: '',
      disableButtons: false,
    };
    this.removeServiceFromDomain = this.removeServiceFromDomain.bind(this);
    this.saveConfig = this.saveConfig.bind(this);
    this.updateAlertLevel = this.updateAlertLevel.bind(this);
  }

  removeServiceFromDomain() {
    this.setState({
      addProcessSymbol: 'fas fa-spinner fa-spin in-progress',
      errorMessage: '',
      disableButtons: true,
    });
    FetchService.deleteAlertRules(this.props.activeService.id)
      .then(() => {
        this.setState({
          addProcessSymbol: 'fas fa-check success',
          errorMessage: '',
        });
        this.props.close('delete');
      })
      .catch(() => {
        this.setState({
          addProcessSymbol: 'fas fa-times error',
          disableButtons: false,
          errorMessage: "Service couldn't be deleted.",
        });
      });
  }

  updateAlertLevel(avail, key) {
    if (avail === 'availabilitySelections') {
      this.setState({
        availability_level: key,
      });
    } else {
      this.setState({
        integrity_level: key,
      });
    }
  }

  saveConfig() {
    this.setState({
      addProcessSymbol: 'fas fa-spinner fa-spin in-progress',
      disableButtons: true,
      errorMessage: '',
    });
    FetchService.updateAlertRules(this.props.activeService.id, {
      availability_level: this.state.availability_level,
      integrity_level: this.state.integrity_level,
    })
      .then(() => {
        this.setState({
          addProcessSymbol: 'fas fa-check success',
          errorMessage: '',
        });
        this.props.close('update');
      })
      .catch(() => {
        this.setState({
          disableButtons: false,
          addProcessSymbol: 'fas fa-times error',
          errorMessage: "Service couldn't get updated.",
        });
      });
  }

  render() {
    return (
      <div>
        <div className="sub-config-header">
          {this.props.activeService.domain}
          <i
            className="fas fa-times edit-modal-icon"
            onClick={this.props.close}
            role="button"
            tabIndex="0"
            onKeyPress={this.props.close}
          />
        </div>
        <div className="modal-edit-content">
          <AlertList
            availability={this.props.activeService.availability_level}
            integrity={this.props.activeService.integrity_level}
            updateAlertLevel={this.updateAlertLevel}
          />
          <div className="modal-edit-buttons">
            <div className="error error-box">
              {' '}
              {this.state.errorMessage} <i className={this.state.addProcessSymbol} />
            </div>
            <Button
              className="btn btn-danger"
              disabled={this.state.disableButtons}
              onClick={this.removeServiceFromDomain}
            >
              Delete
            </Button>
            <Button
              className="btn btn-success"
              disabled={this.state.disableButtons}
              onClick={this.saveConfig}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

EditAlertLevel.propTypes = {
  activeService: PropTypes.shape({
    service_name: PropTypes.string,
    availability_level: PropTypes.string,
    integrity_level: PropTypes.string,
    id: PropTypes.number,
    domain: PropTypes.string,
  }).isRequired,
  close: PropTypes.func.isRequired,
};

export default EditAlertLevel;
