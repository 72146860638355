import * as types from 'Constants/actionTypes';
import FetchService from 'utils/FetchService';

export function getInvoices() {
  return (dispatch) => {
    dispatch({ type: types.INITIALIZE_GET_INVOICES });
    FetchService.getInvoices()
      .then((Response) => {
        dispatch({ type: types.SUCCESS_GET_INVOICES, invoices: Response.results });
      })
      .catch((error) => {
        dispatch({ type: types.ERROR_GET_INVOICES, errors: error });
      });
  };
}

export function payInvoice(invoice) {
  return (dispatch) => {
    dispatch({ type: types.INITIALIZE_PAY_INVOICE });
    FetchService.payInvoice(invoice.stripe_id, invoice)
      .then(() => {
        dispatch(getInvoices());
        dispatch({
          type: types.SUCCESS_PAY_INVOICE,
          message:
            'Initiated invoice payment. It can take some time before the purchase goes through.',
        });
      })
      .catch((error) => {
        dispatch({ type: types.ERROR_PAY_INVOICE, errors: error.invoice });
      });
  };
}
