// default imports
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Col, ControlLabel } from 'react-bootstrap';

// custom components
// styling
import './AlertList.css';

export class AlertList extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      integritySelections: this.props.integrity,
      availabilitySelections: this.props.availability,
    };
    this.checkAlert = this.checkAlert.bind(this);
    this.updateCheckbox = this.updateCheckbox.bind(this);
  }

  updateCheckbox(event, avail, key) {
    event.preventDefault();
    this.setState({
      [avail]: key,
    });
    this.props.updateAlertLevel(avail, key);
  }

  checkAlert() {
    // If needed do checks here
    this.props.submitAlert(this.state.integritySelections, this.state.availabilitySelections);
  }

  render() {
    return (
      <Row className="alert-list">
        <Col xs={12}>
          <p className="configureSubHeading">Alert level</p>
          <Col xs={12}>
            <Row className="availability-selections">
              <Col xs={12} className="alert-list-sub-header">
                Availability
              </Col>
              <Col xs={12} className="display-inline">
                <div
                  className="checkbox checkbox-success checkbox-circle"
                  onClick={(e) => this.updateCheckbox(e, 'availabilitySelections', 'critical')}
                  role="button"
                  onKeyPress={(e) => this.updateCheckbox(e, 'availabilitySelections', 'critical')}
                  tabIndex="0"
                >
                  <input
                    type="checkbox"
                    checked={this.state.availabilitySelections === 'critical'}
                    onChange={(e) => e.preventDefault()}
                  />
                  <ControlLabel htmlFor="checkbox">Critical</ControlLabel>
                </div>
                <div
                  className="checkbox checkbox-success checkbox-circle"
                  onClick={(e) => this.updateCheckbox(e, 'availabilitySelections', 'error')}
                  role="button"
                  onKeyPress={(e) => this.updateCheckbox(e, 'availabilitySelections', 'error')}
                  tabIndex="-1"
                >
                  <input
                    type="checkbox"
                    checked={this.state.availabilitySelections === 'error'}
                    onChange={(e) => e.preventDefault()}
                  />
                  <ControlLabel htmlFor="checkbox">Error</ControlLabel>
                </div>
                <div
                  className="checkbox checkbox-success checkbox-circle"
                  onClick={(e) => this.updateCheckbox(e, 'availabilitySelections', 'warning')}
                  role="button"
                  onKeyPress={(e) => this.updateCheckbox(e, 'availabilitySelections', 'warning')}
                  tabIndex="-2"
                >
                  <input
                    type="checkbox"
                    checked={this.state.availabilitySelections === 'warning'}
                    onChange={(e) => e.preventDefault()}
                  />
                  <ControlLabel htmlFor="checkbox">Warning</ControlLabel>
                </div>
                <div
                  className="checkbox checkbox-success checkbox-circle"
                  onClick={(e) => this.updateCheckbox(e, 'availabilitySelections', 'no_alert')}
                  role="button"
                  onKeyPress={(e) => this.updateCheckbox(e, 'availabilitySelections', 'no_alert')}
                  tabIndex="-2"
                >
                  <input
                    type="checkbox"
                    checked={this.state.availabilitySelections === 'no_alert'}
                    onChange={(e) => e.preventDefault()}
                  />
                  <ControlLabel htmlFor="checkbox">No alert</ControlLabel>
                </div>
              </Col>
            </Row>
            <Row className="integrity-selections">
              <Col xs={12} className="alert-list-sub-header">
                Integrity
              </Col>
              <Col xs={12} className="display-inline">
                <div
                  className="checkbox checkbox-success checkbox-circle"
                  onClick={(e) => this.updateCheckbox(e, 'integritySelections', 'critical')}
                  role="button"
                  onKeyPress={(e) => this.updateCheckbox(e, 'integritySelections', 'critical')}
                  tabIndex="-3"
                >
                  <input
                    type="checkbox"
                    checked={this.state.integritySelections === 'critical'}
                    onChange={(e) => e.preventDefault()}
                  />
                  <ControlLabel htmlFor="checkbox">Critical</ControlLabel>
                </div>
                <div
                  className="checkbox checkbox-success checkbox-circle"
                  onClick={(e) => this.updateCheckbox(e, 'integritySelections', 'error')}
                  role="button"
                  onKeyPress={(e) => this.updateCheckbox(e, 'integritySelections', 'error')}
                  tabIndex="-3"
                >
                  <input
                    type="checkbox"
                    checked={this.state.integritySelections === 'error'}
                    onChange={(e) => e.preventDefault()}
                  />
                  <ControlLabel htmlFor="checkbox">Error</ControlLabel>
                </div>
                <div
                  className="checkbox checkbox-success checkbox-circle"
                  onClick={(e) => this.updateCheckbox(e, 'integritySelections', 'warning')}
                  role="button"
                  onKeyPress={(e) => this.updateCheckbox(e, 'integritySelections', 'warning')}
                  tabIndex="-4"
                >
                  <input
                    type="checkbox"
                    checked={this.state.integritySelections === 'warning'}
                    onChange={(e) => e.preventDefault()}
                  />
                  <ControlLabel htmlFor="checkbox">Warning</ControlLabel>
                </div>
                <div
                  className="checkbox checkbox-success checkbox-circle"
                  onClick={(e) => this.updateCheckbox(e, 'integritySelections', 'no_alert')}
                  role="button"
                  onKeyPress={(e) => this.updateCheckbox(e, 'integritySelections', 'no_alert')}
                  tabIndex="-3"
                >
                  <input
                    type="checkbox"
                    checked={this.state.integritySelections === 'no_alert'}
                    onChange={(e) => e.preventDefault()}
                  />
                  <ControlLabel htmlFor="checkbox">No alert</ControlLabel>
                </div>
              </Col>
            </Row>
          </Col>
        </Col>
      </Row>
    );
  }
}
AlertList.propTypes = {
  availability: PropTypes.string,
  integrity: PropTypes.string,
  updateAlertLevel: PropTypes.func.isRequired,
  submitAlert: PropTypes.func,
};

AlertList.defaultProps = {
  availability: 'no_alert',
  integrity: 'no_alert',
  submitAlert: () => {},
};

export default AlertList;
