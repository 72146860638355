import 'bootstrap/dist/css/bootstrap.css';
import createHistory from 'history/createBrowserHistory';
import Promise from 'promise-polyfill';
import Raven from 'raven-js';
import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import 'react-select/dist/react-select.css';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import 'url-search-params-polyfill';

import './App.css';
import application from './Reducers';
import Routes from './Views/routes';

// General Styling
require('es6-shim');

require('es6-object-assign').polyfill();

// To add to window
if (!window.Promise) {
  window.Promise = Promise;
}

// If Sentry Key is available add Sentry logging
if (process.env.REACT_APP_SENTRY_KEY) {
  window.Raven = Raven.config(process.env.REACT_APP_SENTRY_KEY).install();
}

// Create Store from Reducers
const composeEnhancers =
  process.env.NODE_ENV !== 'production'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;
const store = createStore(application, composeEnhancers(applyMiddleware(thunk)));

const history = createHistory({
  basename: process.env.REACT_APP_BASENAME,
});

// eslint-disable-next-line react/prefer-stateless-function
class App extends Component {
  render() {
    return (
      <Provider store={store} style={{ height: '100%' }}>
        <Router history={history}>
          <Routes />
        </Router>
      </Provider>
    );
  }
}

export default App;
