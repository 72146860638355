// default imports
import React, { PureComponent } from 'react';

class SSLSeal extends PureComponent {
  componentDidMount() {
    const script = document.createElement('script');
    // script.src = "https://seal.godaddy.com/getSeal?sealID=JNjrOUTd564zbco6XFx2eUCU6D8dMm062cmb2a6sJ34KJHt4IRecAdxILWW2";
    script.async = true;
    document.getElementById('siteseal').appendChild(script);
  }

  render() {
    return <span id="siteseal" />;
  }
}

export default SSLSeal;
