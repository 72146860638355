// default imports
import { getDomainConfiguration } from 'Actions/domains';
import Loading from 'Components/Loading/Loading';
import SidebarNavigation from 'Components/SidebarNavigation/SidebarNavigation';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';

import DomainAlertInformation from './Components/DomainAlertInformation/DomainAlertInformation';
import EmailAlert from './Components/EmailAlert/EmailAlert';
import Pagerduty from './Components/Pagerduty/Pagerduty';
import SlackAlert from './Components/SlackAlert/SlackAlert';
import WebhookAlert from './Components/WebhookAlert/WebhookAlert';
// custom components
// styling
import './ConfigureAlerts.css';

export class ConfigureAlerts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDomainID: '',
      currentIntegration: 'PagerDuty',
      alertServices: '',
      alertRules: '',
    };
    this.setSubNav = this.setSubNav.bind(this);
    this.setDomainID = this.setDomainID.bind(this);
    this.setIntegrationID = this.setIntegrationID.bind(this);
    this.toShow = this.toShow.bind(this);
    this.retreieveServices = this.retreieveServices.bind(this);
  }

  componentWillMount() {
    this.retreieveServices();
    this.props.dispatch(getDomainConfiguration());
  }

  setSubNav() {
    const subNavItems = [];

    if (Object.prototype.hasOwnProperty.call(this.props.domains.domains, 'results')) {
      this.props.domains.domains.results.forEach((domain) => {
        subNavItems.push({ component: domain.name });
      });
    } else {
      subNavItems.push({ component: <Loading loadingText="Loading domains..." /> });
    }
    return (
      <SidebarNavigation
        navheader="Alerts"
        links={subNavItems}
        current={this.state.currentDomainID}
        set={this.setDomainID}
        key="subNav"
      />
    );
  }

  setDomainID(id) {
    this.setState({
      currentDomainID: id,
      currentIntegration: '',
    });
    this.props.domains.domains.results.forEach((domain) => {
      if (id === domain.name) {
        this.setState({
          currentDomainID: domain.name,
        });
      }
    });
  }

  setIntegrationID(id) {
    this.setState({
      currentDomainID: '',
      currentIntegration: id,
    });
  }

  retreieveServices() {
    const alertRules = this.props.FetchService.getAlertRules();
    this.props.FetchService.getServices().then((resp) => {
      alertRules.then((response) => {
        this.setState({
          alertServices: resp || {},
          alertRules: response,
        });
      });
    });
  }

  toShow() {
    let component = '';
    if (this.state.currentIntegration) {
      switch (this.state.currentIntegration) {
        case 'PagerDuty':
          component = (
            <Pagerduty
              displayDomain={this.setDomainID}
              information={this.state.alertServices}
              listUpdated={this.retreieveServices}
              alertRules={this.state.alertRules.results}
            />
          );
          break;
        case 'Email':
          component = <EmailAlert />;
          break;
        case 'Webhook':
          component = <WebhookAlert />;
          break;
        case 'Slack':
          component = <SlackAlert />;
          break;
        default:
          break;
      }
    } else if (this.state.currentDomainID) {
      component = (
        <DomainAlertInformation
          domainName={this.state.currentDomainID}
          information={this.state.alertServices}
          alertRules={this.state.alertRules.results}
          listUpdated={this.retreieveServices}
        />
      );
    }
    return component;
  }

  render() {
    if (this.state.alertRules && this.state.alertServices) {
      const nav = this.setSubNav();
      const component = this.toShow();
      return (
        <Grid>
          <Row>
            <Col md={3}>
              <Row>
                <Col xs={12}>
                  <SidebarNavigation
                    navheader="Integrations"
                    links={[
                      {
                        component: 'PagerDuty',
                      } /*  { component: 'Email' }, { component: 'Webhook' }, { component: 'Slack' } */,
                    ]}
                    current={this.state.currentIntegration}
                    set={this.setIntegrationID}
                  />
                </Col>
                <Col xs={12}>{nav}</Col>
              </Row>
            </Col>
            <Col xs={9}>{component}</Col>
          </Row>
        </Grid>
      );
    }
    return <Loading loadingText="Loading Alerts..." />;
  }
}

ConfigureAlerts.propTypes = {
  dispatch: PropTypes.func.isRequired,
  domains: PropTypes.shape({
    domains: PropTypes.object,
  }).isRequired,
  FetchService: PropTypes.shape({
    getAlertRules: PropTypes.func,
    getServices: PropTypes.func,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    domains: state.domains,
  };
}

export default connect(mapStateToProps)(ConfigureAlerts);
