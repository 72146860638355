// default imports
import {
  listResourceRecords,
  deleteResourceRecord,
  addResourceRecord,
} from 'Actions/resourceRecords';
import { getSubscriptions } from 'Actions/subscriptions';
import InformationModal from 'Components/InformationModal/InformationModal';
// custom components
import Loading from 'Components/Loading/Loading';
import StatusIcon from 'Components/StatusIcon';
// actions
import * as types from 'Constants/actionTypes';
import {
  SUBSCRIPTION_PREMIUM,
  SUBSCRIPTION_BASIC,
  SUBSCRIPTION_FREE,
} from 'Constants/subscriptionTypes';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Col, Form, FormControl, FormGroup, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import StorageService from 'utils/StorageService';

import ResourceRecordListItem from '../ResourceRecordListItem/ResourceRecordListItem';
// styling
import './ResourceRecordInformation.css';

export class ResourceRecordInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRemoveResourceRecordModal: false,
      showAddResourceRecordModal: false,
      showPurchaseResourceRecordModal: false,
      error: '',
      modalResourceRecord: null,
      createdName: '',
      purchasing: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(listResourceRecords());
  }

  closeRemoveResourceRecordModal = () => {
    this.setState({ error: '', showRemoveResourceRecordModal: false, modalResourceRecord: null });
  };

  removeResourceRecord = async () => {
    const { id } = this.state.modalResourceRecord;
    const action = await this.props.dispatch(deleteResourceRecord(id));
    if (action.type === types.DELETE_RESOURCE_RECORD_ERROR) {
      this.setState({ error: action.error || 'Unknown error. Please try again later.' });
    } else {
      this.closeRemoveResourceRecordModal();
    }
  };

  closeAddResourceRecordModal = () => {
    this.setState({ createdName: '', error: '', showAddResourceRecordModal: false });
  };

  dispatchAddResourceRecord = async () => {
    const { createdName } = this.state;
    const { selectedDomain } = this.props;
    const action = await this.props.dispatch(addResourceRecord(selectedDomain.id, createdName));
    if (action.type === types.ADD_RESOURCE_RECORD_ERROR) {
      this.setState({ error: action.error || 'Unknown error. Please try again later.' });
    } else {
      this.closeAddResourceRecordModal();
      this.props.dispatch(getSubscriptions());
    }
  };

  addResourceRecord = (e) => {
    e.preventDefault();
    if (this.props.manuallyPaying) {
      this.dispatchAddResourceRecord();
      return;
    }
    const { resourceRecords, subscription, selectedDomain } = this.props;
    const subscriptionType = selectedDomain.subscription_type;
    let nrResourceRecords = 0;
    if (resourceRecords.resource_records) {
      nrResourceRecords = _.filter(resourceRecords.resource_records, {
        domain: selectedDomain.id,
      }).length;
    }
    const subscriptionHostSlots =
      subscriptionType === SUBSCRIPTION_PREMIUM
        ? 5
        : subscriptionType === SUBSCRIPTION_BASIC
        ? 1
        : 0;
    const freeSlots =
      subscription && subscription.extra_resource_records
        ? subscription.extra_resource_records.quantity -
          subscription.extra_resource_records.allocated
        : 0;

    if (nrResourceRecords < subscriptionHostSlots || freeSlots > 0) {
      this.dispatchAddResourceRecord();
    } else {
      this.setState({ showPurchaseResourceRecordModal: true });
    }
  };

  closePurchaseResourceRecordModal = () => {
    this.setState({ error: '', showPurchaseResourceRecordModal: false });
  };

  purchaseResourceRecord = async (e) => {
    e.preventDefault();
    const { subscription } = this.props;
    this.setState({ purchasing: true });
    await this.props.FetchService.updateSubscription(subscription.id, {
      domains: {
        ...subscription.domains,
      },
      basic_domains: {
        ...subscription.basic_domains,
      },
      extra_locations: {
        ...subscription.extra_locations,
      },
      extra_resource_records: {
        plan: subscription.extra_resource_records.plan,
        quantity: subscription.extra_resource_records.quantity + 1,
      },
      billing_interval: subscription.billing_interval,
    })
      .then((resp) => {
        this.setState({ purchasing: false });
        if (resp.error) {
          this.setState({
            error: resp.error,
            purchasing: false,
          });
        } else if (
          resp.extra_resource_records.quantity ===
          subscription.extra_resource_records.quantity + 1
        ) {
          this.dispatchAddResourceRecord();
          this.closePurchaseResourceRecordModal();
        } else {
          this.setState({
            error:
              'There was a problem adding the host records to the subscription. Try again later.',
            purchasing: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          error: 'Something went wrong. Try again later.',
          purchasing: false,
        });
      });
  };

  testCreatedName() {
    // eslint-disable-next-line no-useless-escape
    return /^[a-z\d\._-]{0,253}$/i.test(this.state.createdName);
  }

  render() {
    const { resourceRecords, selectedDomain, plans, subscription } = this.props;
    const { purchasing } = this.state;
    const readonly = StorageService.getReadOnlyMode();
    const filteredResourceRecords =
      resourceRecords && resourceRecords.fetched && resourceRecords.resource_records
        ? resourceRecords.resource_records.filter((i) => i.domain === selectedDomain.id)
        : [];
    const resourcePlan = plans.find((plan) => plan.name === 'record') || {};
    return (
      <div className="resource-records-container">
        <Col xs={12} className="sub-config-header">
          Host name integrity checks
        </Col>
        <Col xs={12} className="config-content">
          {resourceRecords.fetched && (
            <Row>
              {filteredResourceRecords.length ? (
                <Row className="resource-record-list-header">
                  <Col xs={2}>Host ID</Col>
                  <Col>Host name</Col>
                  {!readonly && <Col xs={1} />}
                </Row>
              ) : (
                ''
              )}
              {filteredResourceRecords.length ? (
                filteredResourceRecords.map((i) => (
                  <ResourceRecordListItem
                    key={i.id}
                    domain={selectedDomain}
                    resourceRecord={i}
                    onRemove={(modalResourceRecord) =>
                      this.setState({
                        modalResourceRecord,
                        showRemoveResourceRecordModal: true,
                      })
                    }
                  />
                ))
              ) : selectedDomain.subscription_type === SUBSCRIPTION_PREMIUM ? (
                <div style={{ margin: '0 30px 20px' }}>
                  Five host records are included in the price.
                </div>
              ) : selectedDomain.subscription_type === SUBSCRIPTION_BASIC ? (
                <div style={{ margin: '0 30px 20px' }}>
                  One host record is included in the price.
                </div>
              ) : (
                <div style={{ margin: '0 30px 20px' }}>
                  Host name integrity checks are unavailable to free domains.
                </div>
              )}
            </Row>
          )}
          {resourceRecords.fetching && <Loading loadingText="Loading host records..." />}
          {!readonly && selectedDomain.subscription_type !== SUBSCRIPTION_FREE && (
            <Row>
              <Col xs={12}>
                <Button
                  onClick={() => this.setState({ showAddResourceRecordModal: true })}
                  style={{ marginLeft: 15 }}
                  className="btn-success"
                >
                  Add
                </Button>
              </Col>
            </Row>
          )}
          <Col xs={12}>
            <InformationModal
              showModal={this.state.showRemoveResourceRecordModal}
              closeCallback={this.closeRemoveResourceRecordModal}
              modalHeader="Remove host record"
            >
              <div className="modal-container">
                <div className="modal-text">Are you sure you want to remove this host record?</div>
                <div className="modal-buttons">
                  <StatusIcon
                    inProgress={resourceRecords.delete_in_progress}
                    error={!!this.state.error}
                  />
                  <Button
                    disabled={resourceRecords.delete_in_progress}
                    className="btn-danger"
                    onClick={this.closeRemoveResourceRecordModal}
                  >
                    No
                  </Button>
                  <Button
                    disabled={resourceRecords.delete_in_progress}
                    onClick={() =>
                      this.removeResourceRecord().then(this.props.dispatch(getSubscriptions()))
                    }
                    className="btn-success"
                  >
                    Yes
                  </Button>
                  {this.state.error && (
                    <div className="error error-box error-message">{this.state.error}</div>
                  )}
                </div>
              </div>
            </InformationModal>
            <InformationModal
              showModal={this.state.showAddResourceRecordModal}
              closeCallback={this.closeAddResourceRecordModal}
              modalHeader="Add host record"
            >
              <div className="modal-container">
                <div className="modal-text">
                  <Form id="addResource">
                    <FormGroup controlId="name">
                      <Col style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <FormControl
                          autoFocus
                          type="text"
                          name="name"
                          placeholder="www"
                          value={this.state.createdName}
                          onChange={(e) => this.setState({ createdName: e.target.value })}
                        />
                        <strong style={{ marginLeft: 10 }}>.{selectedDomain.name}</strong>
                      </Col>
                    </FormGroup>
                  </Form>
                </div>
                <div className="modal-buttons">
                  <StatusIcon
                    inProgress={resourceRecords.add_in_progress}
                    error={!!this.state.error}
                  />
                  <Button
                    className="btn-primary"
                    disabled={resourceRecords.add_in_progress}
                    onClick={this.closeAddResourceRecordModal}
                  >
                    Close
                  </Button>
                  <Button
                    type="submit"
                    form="addResource"
                    className="btn-success"
                    disabled={!this.testCreatedName() || resourceRecords.add_in_progress}
                    onClick={this.addResourceRecord}
                  >
                    Add
                  </Button>
                  {this.state.error && (
                    <div style={{ marginTop: 10 }} className="error error-box error-message">
                      {this.state.error}
                    </div>
                  )}
                </div>
              </div>
            </InformationModal>
            <InformationModal
              showModal={this.state.showPurchaseResourceRecordModal}
              closeCallback={this.closePurchaseResourceRecordModal}
              modalHeader="Update Subscription"
            >
              <div className="modal-container">
                <div className="modal-text">
                  <span style={{ display: 'block' }}>
                    Each additional host record will add an additional $
                    {subscription.billing_interval === 'yearly'
                      ? (Number(resourcePlan.yearly_price) / 12).toFixed(2)
                      : resourcePlan.monthly_price}{' '}
                    / month to your account.
                  </span>
                  <span style={{ display: 'block' }}>Are you sure?</span>
                </div>
                <div className="modal-buttons">
                  <StatusIcon inProgress={purchasing} error={!!this.state.error} />
                  <Button
                    disabled={purchasing}
                    onClick={this.closePurchaseResourceRecordModal}
                    className="btn-danger"
                  >
                    No
                  </Button>
                  <Button
                    type="submit"
                    className="btn-success"
                    disabled={purchasing}
                    onClick={this.purchaseResourceRecord}
                  >
                    Yes
                  </Button>
                  {this.state.error && (
                    <div style={{ marginTop: 10 }} className="error error-box error-message">
                      {this.state.error}
                    </div>
                  )}
                </div>
              </div>
            </InformationModal>
          </Col>
        </Col>
      </div>
    );
  }
}

ResourceRecordInformation.propTypes = {
  selectedDomain: PropTypes.shape({
    domain_id: PropTypes.string,
    id: PropTypes.number,
    locations: PropTypes.array,
    name: PropTypes.string,
  }).isRequired,
  subscription: PropTypes.shape({
    id: PropTypes.string,
    domains: PropTypes.shape({
      allocated: PropTypes.number.isRequired,
      quantity: PropTypes.number.isRequired,
    }),
    basic_domains: PropTypes.shape({
      allocated: PropTypes.number.isRequired,
      quantity: PropTypes.number.isRequired,
    }),
    extra_locations: PropTypes.shape({
      allocated: PropTypes.number.isRequired,
      quantity: PropTypes.number.isRequired,
    }),
    extra_resource_records: PropTypes.shape({
      allocated: PropTypes.number.isRequired,
      quantity: PropTypes.number.isRequired,
    }),
    billing_interval: PropTypes.string,
  }),
  FetchService: PropTypes.shape({
    getSubscriptions: PropTypes.func,
    updateSubscription: PropTypes.func,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  resourceRecords: PropTypes.shape({
    resource_records: PropTypes.array,
    fetching: PropTypes.bool,
    fetched: PropTypes.bool,
    delete_in_progress: PropTypes.bool,
    add_in_progress: PropTypes.bool,
  }).isRequired,
  plans: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  manuallyPaying: PropTypes.bool.isRequired,
  redirect: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  resourceRecords: state.resourceRecords,
});

export default connect(mapStateToProps)(ResourceRecordInformation);
