import React, { PureComponent } from 'react';
import { Button } from 'react-bootstrap';

import './CookieAlert.css';

function readCookie(name) {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function close() {
  document.cookie = 'consent=yes; expires=Thu, 1 Nov 2022 20:47:11 UTC; path=/';
  document.getElementsByClassName('cookieAlert')[0].classList.add('closed');
}

function alreadyConsent() {
  const test = readCookie('consent');
  if (test === 'yes') {
    close();
  }
}

export class CookieAlert extends PureComponent {
  componentDidMount() {
    alreadyConsent();
  }

  render() {
    return (
      <div className="cookieAlert">
        This website uses cookies to ensure you get the best experience on our website.{' '}
        <a href="/legal/cookies-policy" target="_blank">
          {' '}
          Learn more{' '}
        </a>
        <Button onClick={close}> Got it! </Button>
      </div>
    );
  }
}
export default CookieAlert;
