// default imports
// actions
import { getMe } from 'Actions/authentication';
import { getDomainConfiguration } from 'Actions/domains';
import InformationModal from 'Components/InformationModal/InformationModal';
// constants
import * as paymentTypes from 'Constants/paymentTypes';
import { SUBSCRIPTION_PREMIUM } from 'Constants/subscriptionTypes';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Grid, Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
// custom components
import FetchService from 'utils/FetchService';
import GenerateUserMetadata from 'utils/GenerateUserMetadata';

import InitialDomainConfiguration from './Components/InitialDomainConfiguration/InitialDomainConfiguration';
// styling
import './Registration.css';

export class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      domainProcess: 'initial',
      domainError: '',
      showModal: false,
      redirectModal: false,
      domainSuccess: false,
      progressIcon: '',
      error: '',
      processing: false,
    };
    this.sendConfiguration = this.sendConfiguration.bind(this);
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
    this.redirect = this.redirect.bind(this);
    this.createDomain = this.createDomain.bind(this);
  }

  componentDidMount() {
    if (!(this.props.domainStore.fetching || this.props.domainStore.fetched)) {
      this.props.dispatch(getDomainConfiguration());
    }
  }

  addDomain = (domainName) => {
    const { locations } = this.props;

    FetchService.addDomain({
      name: domainName,
      location_ids: GenerateUserMetadata.defaultLocations(locations.locations),
      subscription_type: SUBSCRIPTION_PREMIUM,
    })
      .then(() => this.props.dispatch(getMe()))
      .then(() => this.props.dispatch(getDomainConfiguration()))
      .then(() => {
        this.setState({
          progressIcon: <i className="fas fa-check success" />,
          redirectModal: true,
          processing: false,
        });
      })
      .catch((error) => {
        const errorText = Object.keys(error).map((err) => error[err]);
        this.setState({
          progressIcon: <i className="fas fa-times error" />,
          error: errorText,
          processing: false,
        });
      });
  };

  createDomain(domainName) {
    FetchService.createSubscription({
      domains: {
        quantity: 1,
      },
      basic_domains: {
        quantity: 0,
      },
      extra_locations: {
        quantity: 0,
      },
      extra_resource_records: {
        quantity: 0,
      },
      billing_interval: 'monthly',
    })
      .then(() => {
        this.addDomain(domainName);
      })
      .catch(() => {
        this.addDomain(domainName);
      });
  }

  close() {
    this.setState({ showModal: false });
  }

  open() {
    this.setState({ showModal: true });
  }

  redirect() {
    this.props.history.replace('/configure/domains');
  }

  sendConfiguration(domainName) {
    this.setState({
      progressIcon: <i className="fas fa-spinner fa-spin in-progress" />,
      error: '',
      processing: true,
    });
    if (this.props.auth.payment === paymentTypes.NOT_A_CUSTOMER) {
      FetchService.addCustomer()
        .then(() => {
          this.createDomain(domainName);
        })
        .catch((error) => {
          this.setState({
            progressIcon: <i className="fas fa-times error" />,
            error: error.detail.message,
            processing: false,
          });
        });
    } else {
      this.createDomain(domainName);
    }
  }

  render() {
    const disableConf = !!(
      this.state.domainProcess === 'in-progress' || this.state.domainProcess === 'success'
    );
    return (
      <Grid className="registrationContainer">
        <Row>
          <Col md={12} className="configure-box">
            <div className="sub-config-header">
              Setup Subscription
              <i
                className="fas fa-info-circle information-icon-header"
                role="button"
                tabIndex="0"
                onKeyPress={this.open}
                onClick={this.open}
              />
            </div>
            <InformationModal
              showModal={this.state.showModal}
              closeCallback={this.close}
              modalHeader="Setup instructions"
            >
              <div className="modal-text">
                <ul className="list-guide">
                  <li>
                    <b>1.</b> Enter your domain name under &ldquo;Domain Name&rdquo;.
                  </li>
                  <li>
                    <b>2.</b> Click the configure button.
                  </li>
                </ul>
                <p>The configuration may take up to 30 seconds to complete.</p>
              </div>
              <div className="modal-buttons">
                <Button onClick={this.close}>Close</Button>
              </div>
            </InformationModal>
            <div className="config-content">
              <InitialDomainConfiguration
                sendConfiguration={this.sendConfiguration}
                disabled={disableConf}
                domainError={this.state.domainError}
                domainSuccess={this.state.domainSuccess}
                plans={this.props.plans || []}
              />
              <div className="progress-icon">
                {this.state.error}
                {!!this.state.progressIcon && (
                  <span>
                    {this.state.processing && (
                      <span>Configuring domain. This may take up to 30 seconds.</span>
                    )}
                    {this.state.progressIcon}
                  </span>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <InformationModal
          showModal={this.state.redirectModal}
          closeCallback={this.redirect}
          modalHeader="Success"
        >
          <div className="modal-text centerContent">
            <p>
              <b>Your configuration was successful!</b>
            </p>
          </div>
          <div className="modal-buttons">
            <Button className="btn btn-success" onClick={this.redirect}>
              Ok
            </Button>
          </div>
        </InformationModal>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.authentication,
    locations: state.locations,
    plans: state.init.plans,
    domainStore: state.domains,
  };
}

Registration.propTypes = {
  history: PropTypes.shape({ replace: PropTypes.func.isRequired }).isRequired,
  auth: PropTypes.shape({
    payment: PropTypes.string,
  }).isRequired,
  domainStore: PropTypes.shape({
    selected_domain: PropTypes.object,
    fetching: PropTypes.bool,
    reconfigure_in_progress: PropTypes.bool,
    fetched: PropTypes.bool,
    domains: PropTypes.object,
    reconfigure_errors: PropTypes.string.isRequired,
    delete_in_progress: PropTypes.bool,
  }).isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.array,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  plans: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default connect(mapStateToProps)(Registration);
