// default imports
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Row, Col, FormGroup, ControlLabel, Checkbox, Button } from 'react-bootstrap';
import DatePicker from 'react-bootstrap-date-picker';
import Select from 'react-select';

// custom imports
// style imports
import './HistoryFilters.css';

export class HistoryFilters extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      severity: [],
    };

    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handledomainChange = this.handledomainChange.bind(this);
    this.search = this.search.bind(this);
  }

  componentWillMount() {
    const locations = [];
    const domains = [];
    this.props.locations.forEach((location) => {
      locations.push({
        value: location.location_id,
        label: `${location.city_or_region}, ${
          location.state ? `${location.state}, ${location.country}` : location.country
        }`,
      });
    });
    this.props.domains.forEach((domain) => {
      domains.push({ value: domain.id, label: domain.name });
    });
    this.setState({
      location_options: locations,
      domain_options: domains,
    });
  }

  handleChange(type, value) {
    this.setState({
      [type]: value,
    });
  }

  handleSelectChange(value) {
    this.setState({ location: value });
  }

  handledomainChange(value) {
    this.setState({
      domain: value,
    });
  }

  handleSeverityChange = (value, checked) => {
    if (checked) {
      this.state.severity.push(value);
    } else {
      const index = this.state.severity.indexOf(value);
      this.state.severity.splice(index, 1);
    }
  };

  search() {
    this.props.search(this.state);
  }

  render() {
    return (
      <Row id="HistoryFilters">
        <Col sm={10} className="col-sm-offset-1">
          <Row>
            <Col sm={6} xs={12}>
              <ControlLabel>From:</ControlLabel>
              <DatePicker
                id="start-datepicker"
                value={this.state.start_date}
                onChange={(value) => this.handleChange('start_date', value)}
              />
            </Col>
            <Col sm={6} xs={12}>
              <ControlLabel>To:</ControlLabel>
              <DatePicker
                id="end-datepicker"
                value={this.state.end_date}
                onChange={(value) => this.handleChange('end_date', value)}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6} xs={12}>
              <FormGroup controlId="formControlsSelect">
                <ControlLabel>Domain:</ControlLabel>
                <Select
                  options={this.state.domain_options}
                  placeholder="Select domain"
                  onChange={this.handledomainChange}
                  simpleValue
                  value={this.state.domain}
                />
              </FormGroup>
            </Col>
            <Col sm={6} xs={12}>
              <ControlLabel>Locations:</ControlLabel>
              <Select
                multi
                onChange={this.handleSelectChange}
                options={this.state.location_options}
                placeholder="Select locations"
                closeOnSelect={false}
                simpleValue
                value={this.state.location}
              />
            </Col>
          </Row>
          <Row className="bottom-row">
            <Col xs={8}>
              <ControlLabel>Severity:</ControlLabel>
              <FormGroup>
                <Checkbox
                  onChange={(value) => {
                    this.handleSeverityChange(0, value.target.checked);
                  }}
                  name="severity"
                  inline
                >
                  <i className="fas fa-check check" />
                  <span>Info</span>
                </Checkbox>
                <Checkbox
                  onChange={(value) => {
                    this.handleSeverityChange(1, value.target.checked);
                  }}
                  name="severity"
                  inline
                >
                  <i className="fas fa-check check" />
                  <span>Warning</span>
                </Checkbox>
                <Checkbox
                  onChange={(value) => {
                    this.handleSeverityChange(2, value.target.checked);
                  }}
                  name="severity"
                  inline
                >
                  <i className="fas fa-check check" />
                  <span>Error</span>
                </Checkbox>
                <Checkbox
                  onChange={(value) => {
                    this.handleSeverityChange(3, value.target.checked);
                  }}
                  name="severity"
                  inline
                >
                  <i className="fas fa-check check" />
                  <span>Fatal</span>
                </Checkbox>
              </FormGroup>
            </Col>
            <Col xs={4}>
              <Button className="btn btn-primary search-button" onClick={this.search}>
                Search
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

HistoryFilters.propTypes = {
  search: PropTypes.func.isRequired,
  domains: PropTypes.arrayOf(PropTypes.object).isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default HistoryFilters;
