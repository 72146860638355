import PropTypes from 'prop-types';
import React from 'react';
import { Modal, Button } from 'react-bootstrap';

import './InformationModal.css';

function InformationModal({ showModal, children, modalHeader, closeCallback }) {
  return (
    <Modal show={showModal} onHide={closeCallback} className="information-modal">
      <div className="information-modal-header">
        {modalHeader}
        <Button
          bsStyle="link"
          className="edit-modal-icon"
          onClick={closeCallback}
          onKeyPress={closeCallback}
        >
          <i className="fas fa-times" />
        </Button>
      </div>
      <div className="information-modal-content">{children}</div>
    </Modal>
  );
}

InformationModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  modalHeader: PropTypes.string,
  showModal: PropTypes.bool,
  closeCallback: PropTypes.func.isRequired,
};

InformationModal.defaultProps = {
  children: null,
  modalHeader: '',
  showModal: true,
};

export default InformationModal;
