// default imports
import { inviteUser } from 'Actions/invitedUsers';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Row, Col, FormGroup, FormControl, ControlLabel, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import Select from 'react-select';

// custom imports
// style imports
import './InviteUser.css';

export class InviteUser extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      error: '',
      success: '',
    };

    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  componentWillMount() {
    const domains = [];
    this.props.domains.forEach((domain) => {
      domains.push({ value: domain.id, label: domain.name });
    });
    this.setState({
      domain_options: domains,
    });
  }

  handleSelectChange(value) {
    this.setState({ domain: value });
  }

  inviteUser = async () => {
    const { domain, email } = this.state;
    const domains = domain ? domain.split(',') : [];
    const response = await this.props.dispatch(inviteUser(email, domains));
    const { error, success } = response;
    this.setState({ error, success });
  };

  validForm() {
    const { email } = this.state;
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  render() {
    const { invitedUsers } = this.props;
    return (
      <Row id="InviteUser">
        <Col sm={10} className="col-sm-offset-1">
          <Row>
            <Col xs={6}>
              <ControlLabel>Domains:</ControlLabel>
              <Select
                multi
                onChange={this.handleSelectChange}
                options={this.state.domain_options}
                placeholder="Select domains"
                closeOnSelect={false}
                simpleValue
                value={this.state.domain}
              />
            </Col>
            <Col xs={6}>
              <FormGroup controlId="user">
                <Col componentClass={ControlLabel} id="control-label">
                  E-mail:
                </Col>
                <Col>
                  <FormControl
                    type="email"
                    name="user"
                    placeholder="name@example.com"
                    value={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row className="bottom-row">
            <Col xs={4}>
              <Button
                disabled={invitedUsers.loading_invite_user || !this.validForm()}
                className="btn btn-primary search-button"
                onClick={this.inviteUser}
              >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  Invite
                  {invitedUsers.loading_invite_user && (
                    <div style={{ marginLeft: 8 }}>
                      <i className="fas fa-spinner fa-spin fa-fw in-progress" />
                    </div>
                  )}
                </div>
              </Button>
            </Col>
            <Col xs={8}>
              {this.state.error && (
                <div className="error error-box error-message">{this.state.error}</div>
              )}
              {this.state.success && (
                <div className="success success-box success-message">{this.state.success}</div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

InviteUser.propTypes = {
  domains: PropTypes.arrayOf(PropTypes.object).isRequired,
  invitedUsers: PropTypes.shape({
    fetched: PropTypes.bool,
    fetching: PropTypes.bool,
    busy: PropTypes.bool,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  invitedUsers: state.invitedUsers,
});

export default connect(mapStateToProps)(InviteUser);
