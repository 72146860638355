// default imports
// custom components
import Switch from 'Components/Switch/Switch';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import StorageService from '../../../../utils/StorageService';
// styling
import './MenuPopUp.css';

export class MenuPopUp extends PureComponent {
  changeConfiguration() {
    this.props.configuration.cfg_status =
      this.props.configuration.cfg_status !== 'active' ? 'active' : 'disabled';
    this.forceUpdate();
    this.props.saveConfiguration(this.props.configuration);
  }

  render() {
    const { utc } = this.props.menuInfo || {};
    const day = utc ? moment(utc).format('YYYY-MM-DD') : 'Invalid date';
    const time = utc ? moment(utc).format('HH:mm:ss') : 'Invalid Time';
    const { user } = this.props.authentication;
    const isReadOnlyMode = user.is_user || StorageService.getReadOnlyMode();
    const cfgStatus = this.props.configuration.cfg_status || 'unavailable';
    return (
      <div
        className={this.props.leftTableItem ? 'DataMenu right' : 'DataMenu'}
        style={this.props.show ? {} : { display: 'none' }}
      >
        <div className="CellMenuHeader">
          {!utc && cfgStatus !== 'active' && (
            <p>{cfgStatus === 'disabled' ? 'Disabled' : 'Unavailable'}</p>
          )}
          {utc && <p>{day}</p>}
          {utc && <p>{time}</p>}
          {!utc && cfgStatus === 'active' && <p>No available data</p>}
        </div>
        <div className="CellMenuBody">
          <p className="CellMenuMessage">
            {this.props.menuInfo ? this.props.menuInfo.msg : 'No data available'}
          </p>
          {this.props.no_change || isReadOnlyMode ? (
            ''
          ) : (
            <div className="disableEnableToggle">
              <div className="toggler">
                <Switch
                  onClick={() => this.changeConfiguration()}
                  on={cfgStatus === 'active'}
                  disabled={cfgStatus === 'unavailable'}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

MenuPopUp.propTypes = {
  leftTableItem: PropTypes.bool.isRequired,
  no_change: PropTypes.bool,
  menuInfo: PropTypes.shape({
    msg: PropTypes.string,
    utc: PropTypes.string,
  }).isRequired,
  show: PropTypes.bool.isRequired,
  configuration: PropTypes.shape({
    cfg_status: PropTypes.string,
  }).isRequired,
  saveConfiguration: PropTypes.func.isRequired,
  authentication: PropTypes.shape({
    user: PropTypes.object,
  }).isRequired,
};

MenuPopUp.defaultProps = {
  no_change: true,
};

const mapStateToProps = (state) => ({
  authentication: state.authentication,
});

export default connect(mapStateToProps)(MenuPopUp);
