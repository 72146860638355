// default components
// custom components
import Loading from 'Components/Loading/Loading';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Component } from 'react';
import { Grid, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// styling
import './PagerDutyConnect.css';

class PagerDutyConnect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
    };
  }

  componentWillMount() {
    if (this.props.location.search) {
      const { config } = queryString.parse(this.props.location.search);
      const services = JSON.parse(config);
      const promises = [];

      services.integration_keys.forEach((integration) => {
        promises.push(
          this.props.FetchService.addService({
            account: services.account.subdomain,
            service_key: integration.integration_key,
            service_name: integration.name,
          }).catch((error) => {
            this.setState({
              error,
            });
          }),
        );
      });
      Promise.all(promises)
        .then(() => {
          if (!this.state.error) {
            this.props.history.replace('/configure/alerts');
          }
        })
        .catch((error) => {
          this.setState({
            error,
          });
        });
    } else {
      this.setState({
        error: 'Connection unsuccessful',
      });
    }
  }

  render() {
    if (this.state.error) {
      return (
        <Grid>
          <Row id="connect">
            The Service couldn&#39;t be added to this account.
            <br />
            <Link to="configure/alerts">Go to alerts</Link>
          </Row>
        </Grid>
      );
    }
    return (
      <Grid>
        <Row>
          <Loading loadingText="Adding PagerDuty Service..." />
        </Row>
      </Grid>
    );
  }
}

PagerDutyConnect.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  FetchService: PropTypes.shape({
    addService: PropTypes.func,
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
};

export default PagerDutyConnect;
