export const STRIPE_TRIAL = 'STRIPE_TRIAL';
export const STRIPE_ACTIVE = 'STRIPE_ACTIVE';
export const STRIPE_PAST_DUE = 'STRIPE_PAST_DUE';
export const STRIPE_CANCELED = 'STRIPE_CANCELED';
export const STRIPE_UNPAID = 'STRIPE_UNPAID';

export const MANUALLY_PAYING_CUSTOMER = 'MANUALLY_PAYING_CUSTOMER';

export const DELINQUENT = 'DELINQUENT';
export const NOT_A_CUSTOMER = 'NOT_A_CUSTOMER';
export const NO_SUBSCRIPTION = 'NO_SUBSCRIPTION';
export const FREE_USER = 'FREE_USER';
