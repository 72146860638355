export const INITIATE_DATA = 'INITIATE_DATA';

export const ADD_AUTHZERO = 'ADD_AUTHZERO';
export const USER_IS_AUTHENTICATED = 'USER_IS_AUTHENTICATED';
export const USER_IS_NOT_AUTHENTICATED = 'USER_IS_NOT_AUTHENTICATED';

export const GET_PAYMENT_TYPE = 'GET_PAYMENT_TYPE';

export const FETCH_LOCATIONS_INIT = 'FETCH_LOCATIONS_INIT';
export const FETCH_LOCATIONS_ERROR = 'FETCH_LOCATIONS_ERROR';
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS';

export const FETCH_PLANS_INIT = 'FETCH_PLANS_INIT';
export const FETCH_PLANS_ERROR = 'FETCH_PLANS_ERROR';
export const FETCH_PLANS_SUCCESS = 'FETCH_PLANS_SUCCESS';

export const LOGIN_INIT = 'LOGIN_INIT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const LOGOUT_USER = 'LOGOUT_USER';

export const FETCH_DOMAINS_INITIATED = 'FETCH_DOMAINS_INITIATED';
export const FETCH_DOMAINS_SUCCESS = 'FETCH_DOMAINS_SUCCESS';
export const FETCH_DOMAINS_ERROR = 'FETCH_DOMAINS_ERROR';

export const FETCH_DOMAIN_ERROR = 'FETCH_DOMAIN_ERROR';
export const FETCH_DOMAIN_SUCCESS = 'FETCH_DOMAIN_SUCCESS';

export const DELETE_DOMAIN_INITIATED = 'DELETE_DOMAIN_INITIATED';
export const DELETE_DOMAIN_ERROR = 'DELETE_DOMAIN_ERROR';
export const DELETE_DOMAIN_SUCCESS = 'DELETE_DOMAIN_SUCCESS';

export const RECONFIGURE_DOMAIN_INITIATED = 'RECONFIGURE_DOMAIN_INITIATED';
export const RECONFIGURE_DOMAIN_SUCCESS = 'RECONFIGURE_DOMAIN_SUCCESS';
export const RECONFIGURE_DOMAIN_ERROR = 'RECONFIGURE_DOMAIN_ERROR';

export const UPDATE_DOMAIN_INITIATED = 'UPDATE_DOMAIN_INITIATED';
export const UPDATE_DOMAIN_SUCCESS = 'UPDATE_DOMAIN_SUCCESS';
export const UPDATE_DOMAIN_ERROR = 'UPDATE_DOMAIN_ERROR';

export const NEW_ACTIVE_DOMAIN = 'NEW_ACTIVE_DOMAIN';

export const FETCH_CONFIGURATION_INITIATED = 'FETCH_CONFIGURATION_INITIATED';
export const FETCH_CONFIGURATION_SUCCESS = 'FETCH_CONFIGURATION_SUCCESS';
export const FETCH_CONFIGURATION_ERROR = 'FETCH_CONFIGURATION_ERROR';

export const CHANGE_CONFIGURATION_INIT = 'CHANGE_CONFIGURATION_';

export const FETCH_DOMAIN_DATA_INITIATED = 'FETCH_DOMAIN_DATA_INITIATED';
export const FETCH_DOMAIN_DATA_SUCCESS = 'FETCH_DOMAIN_DATA_SUCCESS';
export const FETCH_DOMAIN_DATA_ERROR = 'FETCH_DOMAIN_DATA_ERROR';

export const UPDATE_DASHBOARD_SORTING = 'UPDATE_DASHBOARD_SORTING';

export const UPDATE_DOMAIN_CONFIGURATION = 'UPDATE_DOMAIN_CONFIGURATION';
export const UPDATE_DOMAIN_CONFIGURATION_FINISHED = 'UPDATE_DOMAIN_CONFIGURATION_FINISHED';

export const INITIAL_CONFIGURATION = 'INITIAL_CONFIGURATION';

export const INIT_HISTORY_SEARCH = 'INIT_HISTORY_SEARCH';
export const INIT_HISTORY_SEARCH_ERROR = 'INIT_HISTORY_SEARCH_ERROR';
export const INIT_HISTORY_SEARCH_SUCCESS = 'INIT_HISTORY_SEARCH_SUCCESS';

export const CHANGE_PAGE = 'CHANGE_PAGE';
export const FETCH_NEXT_INIT = 'FETCH_NEXT_INIT';
export const FETCH_NEXT_SUCCESS = 'FETCH_NEXT_SUCCESS';
export const FETCH_NEXT_ERROR = 'FETCH_NEXT_ERROR';

export const HISTORY_DETAILED_INIT = 'HISTORY_DETAILED_INIT';
export const HISTORY_DETAILED_SUCCESS = 'HISTORY_DETAILED_SUCCESS';
export const HISTORY_DETAILED_ERROR = 'HISTORY_DETAILED_ERROR';
export const USER_DATA = 'USER_DATA';
export const CHANGE_USER_PAYMENT = 'CHANGE_USER_PAYMENT';

export const INITIALIZE_GET_INVOICES = 'INITIALIZE_GET_INVOICES';
export const SUCCESS_GET_INVOICES = 'SUCCESS_GET_INVOICES';
export const ERROR_GET_INVOICES = 'ERROR_GET_INVOICES';

export const INITIALIZE_PAY_INVOICE = 'INITIALIZE_PAY_INVOICE';
export const SUCCESS_PAY_INVOICE = 'SUCCESS_GET_INVOICE';
export const ERROR_PAY_INVOICE = 'ERROR_PAY_INVOICE';

export const INVITE_USER_INITIATED = 'INVITE_USER_INITIATED';
export const INVITE_USER_SUCCESS = 'INVITE_USER_SUCCESS';
export const INVITE_USER_ERROR = 'INVITE_USER_ERROR';

export const FETCH_USERS_INITIATED = 'FETCH_USERS_INITIATED';
export const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';

export const FETCH_INVITED_USERS_INITIATED = 'FETCH_INVITED_USERS_INITIATED';
export const FETCH_INVITED_USERS_ERROR = 'FETCH_INVITED_USERS_ERROR';
export const FETCH_INVITED_USERS_SUCCESS = 'FETCH_INVITED_USERS_SUCCESS';

export const PATCH_INVITATION_INITIATED = 'PATCH_INVITATION_INITIATED';
export const PATCH_INVITATION_ERROR = 'PATCH_INVITATION_ERROR';
export const PATCH_INVITATION_SUCCESS = 'PATCH_INVITATION_SUCCESS';

export const DELETE_INVITATION_INITIATED = 'DELETE_INVITATION_INITIATED';
export const DELETE_INVITATION_ERROR = 'DELETE_INVITATION_ERROR';
export const DELETE_INVITATION_SUCCESS = 'DELETE_INVITATION_SUCCESS';

export const RESEND_INVITATION_INITIATED = 'RESEND_INVITATION_INITIATED';
export const RESEND_INVITATION_ERROR = 'RESEND_INVITATION_ERROR';
export const RESEND_INVITATION_SUCCESS = 'RESEND_INVITATION_SUCCESS';

export const PATCH_MANAGE_USER_INITIATED = 'PATCH_MANAGE_USER_INITIATED';
export const PATCH_MANAGE_USER_ERROR = 'PATCH_MANAGE_USER_ERROR';
export const PATCH_MANAGE_USER_SUCCESS = 'PATCH_MANAGE_USER_SUCCESS';

export const DELETE_MANAGE_USER_INITIATED = 'DELETE_MANAGE_USER_INITIATED';
export const DELETE_MANAGE_USER_ERROR = 'DELETE_MANAGE_USER_ERROR';
export const DELETE_MANAGE_USER_SUCCESS = 'DELETE_MANAGE_USER_SUCCESS';

export const FETCH_RESOURCE_RECORDS_INITIATED = 'FETCH_RESOURCE_RECORDS_INITIATED';
export const FETCH_RESOURCE_RECORDS_ERROR = 'FETCH_RESOURCE_RECORDS_ERROR';
export const FETCH_RESOURCE_RECORDS_SUCCESS = 'FETCH_RESOURCE_RECORDS_SUCCESS';

export const DELETE_RESOURCE_RECORD_INITIATED = 'DELETE_RESOURCE_RECORD_INITIATED';
export const DELETE_RESOURCE_RECORD_ERROR = 'DELETE_RESOURCE_RECORD_ERROR';
export const DELETE_RESOURCE_RECORD_SUCCESS = 'DELETE_RESOURCE_RECORD_SUCCESS';

export const RECONFIGURE_RESOURCE_RECORD_INITIATED = 'RECONFIGURE_RESOURCE_RECORD_INITIATED';
export const RECONFIGURE_RESOURCE_RECORD_ERROR = 'RECONFIGURE_RESOURCE_RECORD_ERROR';
export const RECONFIGURE_RESOURCE_RECORD_SUCCESS = 'RECONFIGURE_RESOURCE_RECORD_SUCCESS';

export const ADD_RESOURCE_RECORD_INITIATED = 'ADD_RESOURCE_RECORD_INITIATED';
export const ADD_RESOURCE_RECORD_ERROR = 'ADD_RESOURCE_RECORD_ERROR';
export const ADD_RESOURCE_RECORD_SUCCESS = 'ADD_RESOURCE_RECORD_SUCCESS';

export const FETCH_STAFF_USERS_INITIATED = 'FETCH_STAFF_USERS_INITIATED';
export const FETCH_STAFF_USERS_ERROR = 'FETCH_STAFF_USERS_ERROR';
export const FETCH_STAFF_USERS_SUCCESS = 'FETCH_STAFF_USERS_SUCCESS';

// Subscription
export const INITIATE_GET_SUBSCRIPTIONS = 'INITIATE_GET_SUBSCRIPTIONS';
export const SUCCESS_GET_SUBSCRIPTIONS = 'SUCCESS_GET_SUBSCRIPTIONS';
export const ERROR_GET_SUBSCRIPTIONS = 'ERROR_GET_SUBSCRIPTIONS';

export const INITIATE_GET_SUBSCRIPTION = 'INITIATE_GET_SUBSCRIPTION';
export const SUCCESS_GET_SUBSCRIPTION = 'SUCCESS_GET_SUBSCRIPTION';
export const ERROR_GET_SUBSCRIPTION = 'ERROR_GET_SUBSCRIPTION';

export const INITIATE_DELETE_SUBSCRIPTION = 'INITIATE_DELETE_SUBSCRIPTION';
export const SUCCESS_DELETE_SUBSCRIPTION = 'SUCCESS_DELETE_SUBSCRIPTION';
export const ERROR_DELETE_SUBSCRIPTION = 'ERROR_DELETE_SUBSCRIPTION';

export const INITIATE_CREATE_SUBSCRIPTION = 'INITIATE_CREATE_SUBSCRIPTION';
export const SUCCESS_CREATE_SUBSCRIPTION = 'SUCCESS_CREATE_SUBSCRIPTION';
export const ERROR_CREATE_SUBSCRIPTION = 'ERROR_CREATE_SUBSCRIPTION';

export const INITIATE_UPDATE_SUBSCRIPTION = 'INITIATE_UPDATE_SUBSCRIPTION';
export const SUCCESS_UPDATE_SUBSCRIPTION = 'SUCCESS_UPDATE_SUBSCRIPTION';
export const ERROR_UPDATE_SUBSCRIPTION = 'ERROR_UPDATE_SUBSCRIPTION';

// Payment-Method
export const INITIATE_GET_PAYMENT_METHODS = 'INITIATE_GET_PAYMENT_METHODS';
export const SUCCESS_GET_PAYMENT_METHODS = 'SUCCESS_GET_PAYMENT_METHODS';
export const ERROR_GET_PAYMENT_METHODS = 'ERROR_GET_PAYMENT_METHODS';

export const INITIATE_GET_PAYMENT_METHOD = 'INITIATE_GET_PAYMENT_METHOD';
export const SUCCESS_GET_PAYMENT_METHOD = 'SUCCESS_GET_PAYMENT_METHOD';
export const ERROR_GET_PAYMENT_METHOD = 'ERROR_GET_PAYMENT_METHOD';

export const INITIATE_DELETE_PAYMENT_METHOD = 'INITIATE_DELETE_PAYMENT_METHOD';
export const SUCCESS_DELETE_PAYMENT_METHOD = 'SUCCESS_DELETE_PAYMENT_METHOD';
export const ERROR_DELETE_PAYMENT_METHOD = 'ERROR_DELETE_PAYMENT_METHOD';

export const INITIATE_ADD_PAYMENT_METHOD = 'INITIATE_ADD_PAYMENT_METHOD';
export const SUCCESS_ADD_PAYMENT_METHOD = 'SUCCESS_ADD_PAYMENT_METHOD';
export const ERROR_ADD_PAYMENT_METHOD = 'ERROR_ADD_PAYMENT_METHOD';

export const INITIATE_UPDATE_PAYMENT_METHOD = 'INITIATE_UPDATE_PAYMENT_METHOD';
export const SUCCESS_UPDATE_PAYMENT_METHOD = 'SUCCESS_UPDATE_PAYMENT_METHOD';
export const ERROR_UPDATE_PAYMENT_METHOD = 'ERROR_UPDATE_PAYMENT_METHOD';

// Api Keys
export const FETCH_API_KEYS_INITIATED = 'FETCH_API_KEYS_INITIATED';
export const FETCH_API_KEYS_SUCCESS = 'FETCH_API_KEYS_SUCCESS';
export const FETCH_API_KEYS_ERROR = 'FETCH_API_KEYS_ERROR';

export const CREATE_API_KEYS_INITIATED = 'CREATE_API_KEYS_INITIATED';
export const CREATE_API_KEYS_SUCCESS = 'CREATE_API_KEYS_SUCCESS';
export const CREATE_API_KEYS_ERROR = 'CREATE_API_KEYS_ERROR';

export const DELETE_API_KEYS_INITIATED = 'DELETE_API_KEYS_INITIATED';
export const DELETE_API_KEYS_SUCCESS = 'DELETE_API_KEYS_SUCCESS';
export const DELETE_API_KEYS_ERROR = 'DELETE_API_KEYS_ERROR';
