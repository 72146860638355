import * as types from 'Constants/actionTypes';

const initialSettings = {
  domains: {},
  fetching: false,
  fetched: false,
  selected_domain: {},
  reconfigure_in_progress: false,
  reconfigure_errors: '',
  update_in_progress: false,
  update_errors: '',
  delete_in_progress: false,
};

const domains = (state = initialSettings, action) => {
  switch (action.type) {
    case types.FETCH_DOMAINS_INITIATED:
      return { ...state, fetching: true };
    case types.FETCH_DOMAINS_SUCCESS:
      if (action.selected) {
        return {
          ...state,
          fetching: false,
          fetched: true,
          domains: action.Response,
          selected_domain: action.selected,
        };
      }
      return {
        ...state,
        fetching: false,
        fetched: true,
        domains: action.Response,
      };
    case types.FETCH_DOMAIN_SUCCESS:
      return {
        ...state,
        domains: {
          ...state.domains,
          results: state.domains.results.map((domain) => {
            if (domain.id === action.id) return action.Response;
            return domain;
          }),
        },
      };
    case types.FETCH_LOCATIONS_ERROR:
      return {
        ...state,
        fetching: false,
        fetched: false,
        errors: action.Response.error,
      };
    case types.NEW_ACTIVE_DOMAIN:
      return { ...state, selected_domain: action.domain };
    case types.DELETE_DOMAIN_INITIATED:
      return {
        ...state,
        delete_in_progress: true,
      };
    case types.DELETE_DOMAIN_SUCCESS: {
      const results = state.domains.results.filter((i) => i.id !== action.id);
      return {
        ...state,
        delete_in_progress: false,
        domains: {
          ...state.domains,
          results,
        },
        selected_domain: results.length ? results[0] : {},
      };
    }
    case types.DELETE_DOMAIN_ERROR:
      return {
        ...state,
        delete_in_progress: false,
      };
    case types.RECONFIGURE_DOMAIN_INITIATED:
      return { ...state, reconfigure_in_progress: true, reconfigure_errors: '' };
    case types.RECONFIGURE_DOMAIN_SUCCESS:
      return { ...state, reconfigure_in_progress: false };
    case types.RECONFIGURE_DOMAIN_ERROR:
      return { ...state, reconfigure_in_progress: false, reconfigure_errors: action.errors };
    case types.UPDATE_DOMAIN_INITIATED:
      return { ...state, update_in_progress: true, update_errors: '' };
    case types.UPDATE_DOMAIN_SUCCESS:
      return { ...state, update_in_progress: false };
    case types.UPDATE_DOMAIN_ERROR:
      return { ...state, update_in_progress: false, update_errors: action.errors };
    case types.LOGOUT_USER:
      return { ...initialSettings };
    default:
      return state;
  }
};

export default domains;
