import * as types from 'Constants/actionTypes';
import FetchService from 'utils/FetchService';

import { getMe } from './authentication';
import { getSubscriptions } from './subscriptions';

export function getDomainConfiguration(selected = '') {
  return (dispatch) => {
    dispatch({ type: types.FETCH_DOMAINS_INITIATED });
    FetchService.getDomains().then((Response) => {
      if (Response.error) {
        dispatch({ type: types.FETCH_DOMAINS_ERROR, Response });
      } else if (selected) {
        const newSelectedData = Response.results.filter((item) => item.id === selected.id);
        dispatch({ type: types.FETCH_DOMAINS_SUCCESS, Response, selected: newSelectedData[0] });
      } else {
        dispatch({ type: types.FETCH_DOMAINS_SUCCESS, Response });
      }
    });
  };
}

export function fetchDomain(id) {
  return (dispatch) =>
    FetchService.getDomain(id).then((Response) => {
      if (Response.error) {
        return { type: types.FETCH_DOMAIN_ERROR, error: Response.error };
      }
      return dispatch({ type: types.FETCH_DOMAIN_SUCCESS, Response, id });
    });
}

export function viewNewDomain(id, domains) {
  return (dispatch) => {
    let domain = {};
    domains.results.forEach((item) => {
      if (item.name === id) {
        domain = item;
      }
    });
    dispatch({ type: types.NEW_ACTIVE_DOMAIN, domain });
  };
}

export function domainAdded(name) {
  return (dispatch) => {
    dispatch({ type: types.FETCH_DOMAINS_INITIATED });
    FetchService.getDomains().then((Response) => {
      if (Response.error) {
        dispatch({ type: types.FETCH_DOMAINS_ERROR, Response });
      } else {
        let selected = {};
        dispatch(getSubscriptions());
        dispatch(getMe());
        Response.results.forEach((item) => {
          if (item.name === name) selected = item;
        });
        dispatch({ type: types.FETCH_DOMAINS_SUCCESS, Response, selected });
      }
    });
  };
}

export const deleteDomain = (id) => async (dispatch) => {
  dispatch({ type: types.DELETE_DOMAIN_INITIATED });
  try {
    await FetchService.deleteDomain(id);
    dispatch(getSubscriptions());
    dispatch(getMe());
    return dispatch({ type: types.DELETE_DOMAIN_SUCCESS, id });
  } catch (error) {
    return dispatch({ type: types.DELETE_DOMAIN_ERROR, error });
  }
};

export function reconfigureDomain(id) {
  return (dispatch) => {
    dispatch({ type: types.RECONFIGURE_DOMAIN_INITIATED });
    FetchService.reconfigureDomain(id)
      .then(() => {
        dispatch(fetchDomain(id)).then((res) => {
          if (res.error) {
            dispatch({ type: types.RECONFIGURE_DOMAIN_ERROR, errors: res.error });
          } else {
            dispatch({ type: types.RECONFIGURE_DOMAIN_SUCCESS });
          }
        });
      })
      .catch((err) => {
        dispatch({ type: types.RECONFIGURE_DOMAIN_ERROR, errors: err });
      });
  };
}

export const updateDomain = (domain) => async (dispatch) => {
  dispatch({ type: types.UPDATE_DOMAIN_INITIATED });
  try {
    await FetchService.updateDomain(domain.id, domain);
    dispatch(fetchDomain(domain.id)).then((res) => {
      if (res.error) {
        dispatch({ type: types.UPDATE_DOMAIN_ERROR, errors: res.error });
      } else {
        dispatch({ type: types.UPDATE_DOMAIN_SUCCESS });
      }
    });
  } catch (err) {
    dispatch({ type: types.UPDATE_DOMAIN_ERROR, errors: err });
  }
};
