// default imports
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Button, Modal } from 'react-bootstrap';

import StorageService from '../../../../utils/StorageService';
import AddAlertToDomain from '../AddAlertToDomain/AddAlertToDomain';
// import Select from 'react-select';
// custom components
// import AlertList from '../AlertList/AlertList';
import EditAlertLevel from '../EditAlertLevel/EditAlertLevel';
// import FetchService from 'utils/FetchService';
// import StroageService from 'utils/StorageService';
// styling
import './DomainAlertInformation.css';

const prettyPrintAlertLevel = {
  error: 'Error',
  warning: 'Warning',
  critical: 'Critical',
  no_alert: 'No Alert',
};

export class DomainAlertInformation extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showEditModal: false,
      showAddModal: false,
      activeService: {},
      message: '',
      processIcon: '',
    };
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
  }

  open(name = '') {
    if (name) {
      this.setState({
        showEditModal: true,
        activeService: name,
      });
    } else {
      this.setState({
        showAddModal: true,
      });
    }
  }

  close(name = '') {
    if (name === 'delete' || name === 'add' || name === 'update') {
      this.props.listUpdated();
      this.setState({
        showEditModal: false,
        showAddModal: false,
      });
    } else {
      this.setState({
        showEditModal: false,
        showAddModal: false,
      });
    }
  }

  mapKeyName(key) {
    let name = '';
    this.props.information.results.forEach((service) => {
      if (service.service_key === key) {
        name = service.service_name;
      }
    });
    return name;
  }

  createIntegrationList() {
    const alertList = [];
    const isReadOnlyMode = StorageService.getReadOnlyMode();
    if (this.props.alertRules.length) {
      this.props.alertRules.forEach((rule) => {
        if (rule.domain === this.props.domainName) {
          const serviceName = this.mapKeyName(rule.service_key);
          alertList.push(
            <Col
              xs={12}
              key={rule.service_key}
              className="domain-box"
              title="Edit Alert"
              onClick={() => this.open(rule)}
            >
              <Col xs={3} className="box-line">
                <div className="box-sub-header">Integration</div>
                <div className="box-sub-text">PagerDuty</div>
              </Col>
              <Col xs={3}>
                <div className="box-sub-header">Service</div>
                <div className="box-sub-text">{serviceName}</div>
              </Col>
              <Col xs={3}>
                <div className="box-sub-header">Availability</div>
                <div className="box-sub-text">{prettyPrintAlertLevel[rule.availability_level]}</div>
              </Col>
              <Col xs={2}>
                <div className="box-sub-header">Integrity</div>
                <div className="box-sub-text">{prettyPrintAlertLevel[rule.integrity_level]}</div>
              </Col>
              <Col xs={1}>
                <i className="fas fa-pencil edit-icon" />
              </Col>
            </Col>,
          );
        }
      });
    }
    if (alertList.length) {
      return <div className="service-container">{alertList}</div>;
    }
    if (isReadOnlyMode) {
      return (
        <Col xs={12} key="no_rules" className="no-rules">
          No alert rules.
        </Col>
      );
    }
    return (
      <Col xs={12} key="no_rules" className="no-rules">
        Add alert rules to your domains so you can be notified when something happens.
      </Col>
    );
  }

  render() {
    const integrationList = this.createIntegrationList();
    const isReadOnlyMode = StorageService.getReadOnlyMode();
    return (
      <div>
        <Col xs={12} className="sub-config-header">
          {this.props.domainName}
        </Col>
        <Col xs={12} className="config-content config-alert">
          {integrationList}
          <Col xs={12} className="alert-buttons">
            <div>
              {this.state.message} <i className={this.state.processIcon} />
            </div>
            {!isReadOnlyMode && (
              <Button className="btn btn-primary add-alert-button" onClick={() => this.open()}>
                Add Alert
              </Button>
            )}
          </Col>
        </Col>
        <Modal show={this.state.showEditModal} onHide={this.close} className="information-modal">
          <EditAlertLevel
            close={this.close}
            activeService={this.state.activeService}
            availability="Error"
            integrity="Critical"
          />
        </Modal>
        <Modal show={this.state.showAddModal} onHide={this.close} className="information-modal">
          <div className="sub-config-header">
            Add Alert
            <i
              className="fas fa-times edit-modal-icon"
              onClick={this.close}
              role="button"
              tabIndex="0"
              onKeyPress={this.close}
            />
          </div>
          <div className="modal-edit-content">
            <AddAlertToDomain
              close={this.close}
              information={this.props.information}
              domainName={this.props.domainName}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

DomainAlertInformation.propTypes = {
  information: PropTypes.shape({
    results: PropTypes.array,
  }).isRequired,
  listUpdated: PropTypes.func.isRequired,
  alertRules: PropTypes.arrayOf(PropTypes.object || null).isRequired,
  domainName: PropTypes.string.isRequired,
};

export default DomainAlertInformation;
