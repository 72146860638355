// default imports
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Select from 'react-select';

// custom components
// styling
import './SelectService.css';

export class SelectService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
    this.changeService = this.changeService.bind(this);
  }

  changeService(val) {
    if (val) {
      this.setState({
        value: val,
      });
    } else {
      this.setState({
        value: '',
      });
    }
    this.props.connectedService(val);
  }

  render() {
    const options = [];
    if (this.props.services.results) {
      this.props.services.results.forEach((service) => {
        options.push({ value: service.service_key, label: service.service_name });
      });
    }
    return (
      <div className="select-container">
        <Select
          name="service-selection"
          value={this.state.value}
          options={options}
          onChange={this.changeService}
        />
      </div>
    );
  }
}

SelectService.propTypes = {
  services: PropTypes.shape({
    results: PropTypes.array,
  }).isRequired,
  connectedService: PropTypes.func.isRequired,
};

export default SelectService;
