// default imports
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Col } from 'react-bootstrap';

import { getMinNrOfLocationsForDomain } from '../../utils/GenerateUserMetadata';
import StorageService from '../../utils/StorageService';
// styling
import './SelectLocations.css';

export class SelectLocations extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      activeLocations: props.activeLocations,
      locationsError: '',
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeLocations !== this.props.activeLocations) {
      this.setState({
        activeLocations: nextProps.activeLocations,
      });
    }
  }

  changeLocationStatus = (item, checked) => {
    const activeLocations = checked
      ? [...this.state.activeLocations, item]
      : _.filter(this.state.activeLocations, (loc) => loc.location_id !== item.location_id);
    const notEnoughLocations = activeLocations.length < getMinNrOfLocationsForDomain();
    this.setState(
      {
        locationsError: notEnoughLocations ? 'You need to select at least 3 locations' : '',
      },
      () => {
        this.props.updateLocations(activeLocations, !notEnoughLocations);
      },
    );
  };

  render() {
    const isReadOnlyMode = StorageService.getReadOnlyMode();
    const { locations, locationMessage } = this.props;
    return (
      <div>
        <Col xs={12} mdOffset={1} lgOffset={0}>
          <p className="configureSubHeading margin">Select locations </p>
          <div className="domainLocations">
            {_.map(locations, (item) => {
              const isChecked = !!_.find(this.state.activeLocations, {
                location_id: item.location_id,
              });
              return (
                <button
                  type="button"
                  key={`${item.location_id}_${isChecked ? 'checked' : 'not_checked'}`}
                  className="location-checkbox-container"
                  onClick={() => !isReadOnlyMode && this.changeLocationStatus(item, !isChecked)}
                >
                  {isChecked ? (
                    <i className="fas fa-circle location-checkbox success" />
                  ) : (
                    <i className="far fa-circle  fa-circle location-checkbox" />
                  )}
                  <b>{`${item.city_or_region}, ${item.country}`}</b>
                </button>
              );
            })}
          </div>
          <div className="error-box additional-information">{locationMessage}</div>
          <div className="error error-box">{this.state.locationsError}</div>
        </Col>
      </div>
    );
  }
}
SelectLocations.propTypes = {
  activeLocations: PropTypes.arrayOf(PropTypes.shape({})),
  updateLocations: PropTypes.func,
  locationMessage: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
};

SelectLocations.defaultProps = {
  activeLocations: [],
  updateLocations: () => {},
  locationMessage: 'Add additional locations for $25 / month',
};

export default SelectLocations;
