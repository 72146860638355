// default imports
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Row, Table } from 'react-bootstrap';

// custom imports
import PaginationButton from '../../../../Components/PaginationButton/PaginationButton';
// style imports
import './InvoiceList.css';

export class InvoiceList extends PureComponent {
  getProgress = (closed, paid, attempted) => {
    if (closed && paid && attempted) {
      return <td className="success">Paid</td>;
    }
    if (closed && !paid && attempted) {
      return <td className="error">Unpaid</td>;
    }
    if (!closed && !paid && !attempted) {
      return <td>Upcoming</td>;
    }
    if (!closed && !paid && attempted) {
      return <td className="in-progress">Ongoing</td>;
    }
    if (closed && !paid && !attempted) {
      return <td>Closed</td>;
    }
    return <td>-</td>;
  };

  getRows = () => {
    const startIndex = this.props.page * 25;
    const pageContent = this.props.results.slice(startIndex, startIndex + 25);
    return _.map(pageContent, (item) => (
      <tr key={item.id}>
        <td>{item.due_date ? moment(item.due_date).format('YYYY-MM-DD') : <i>No date</i>}</td>
        <td>{item.period_start ? moment(item.period_start).format('YYYY-MM-DD') : '-'}</td>
        <td>{item.period_end ? moment(item.period_end).format('YYYY-MM-DD') : '-'}</td>
        <td>{`$ ${item.amount_due}`}</td>
        <td>{`$ ${item.amount_paid}`}</td>
        {this.getProgress(item.closed, item.paid, item.attempted)}
        <td className="center-row link">
          <a href={item.hosted_invoice_url} target="_blank" rel="noopener noreferrer">
            {!item.paid && item.attempted && !this.props.isReadOnlyMode ? `Pay` : `View`}
          </a>
        </td>
      </tr>
    ));
  };

  render() {
    const { page, results } = this.props;
    if (!results.length) {
      return (
        <Row id="InvoiceList">
          <div className="pagination">No Available results</div>
        </Row>
      );
    }
    return (
      <Row id="InvoiceList">
        <Table striped bordered condensed hover>
          <thead>
            <tr>
              <th>Due Date</th>
              <th>Period Start</th>
              <th>Period End</th>
              <th>Amount</th>
              <th>Paid</th>
              <th>Progress</th>
              <th />
            </tr>
          </thead>
          <tbody>{this.getRows()}</tbody>
        </Table>

        <div className="pagination">
          <PaginationButton
            symbol="&#8592;"
            disabled={page === 0}
            onClick={() => this.props.changePagination(-1)}
          />
          <PaginationButton
            symbol="&#8594;"
            disabled={page + 1 >= results.length / 25}
            onClick={() => this.props.changePagination(1)}
          />
          <div>{page + 1}</div>
        </div>
      </Row>
    );
  }
}

InvoiceList.propTypes = {
  results: PropTypes.arrayOf(PropTypes.object),
  page: PropTypes.number,
  changePagination: PropTypes.func,
  isReadOnlyMode: PropTypes.bool.isRequired,
};

InvoiceList.defaultProps = {
  results: [],
  page: 0,
  changePagination: () => {},
};

export default InvoiceList;
