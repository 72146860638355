import * as types from 'Constants/actionTypes';

const initialSettings = {
  fetching: false,
  adding: false,
  updating: false,
  errors: {},
  paymentMethods: {},
  busy: false,
};

const paymentMethod = (state = initialSettings, action) => {
  switch (action.type) {
    case types.INITIATE_GET_PAYMENT_METHODS:
      return { ...state, fetching: true, fetched: false };
    case types.SUCCESS_GET_PAYMENT_METHODS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        paymentMethods: action.Response,
      };
    case types.ERROR_GET_PAYMENT_METHODS:
      return {
        ...state,
        fetching: false,
        errors: action.Response.errors,
      };

    case types.INITIATE_GET_PAYMENT_METHOD:
      return { ...state, fetching: true, fetched: false };
    case types.SUCCESS_GET_PAYMENT_METHOD:
      return {
        ...state,
        fetching: false,
        fetched: true,
        paymentMethods: action.paymentMethods,
      };
    case types.ERROR_GET_PAYMENT_METHOD:
      return {
        ...state,
        fetching: false,
        errors: action.errors,
      };

    case types.INITIATE_DELETE_PAYMENT_METHOD:
      return {
        ...state,
        busy: true,
        errors: '',
      };
    case types.SUCCESS_DELETE_PAYMENT_METHOD:
      return {
        ...state,
        busy: false,
      };
    case types.ERROR_DELETE_PAYMENT_METHOD:
      return {
        ...state,
        busy: false,
        errors: action.errors,
      };

    case types.INITIATE_ADD_PAYMENT_METHOD:
      return {
        ...state,
        adding: true,
        errors: '',
      };
    case types.SUCCESS_ADD_PAYMENT_METHOD:
      return {
        ...state,
        adding: false,
      };
    case types.ERROR_ADD_PAYMENT_METHOD:
      return {
        ...state,
        adding: false,
        errors: action.errors,
      };

    case types.INITIATE_UPDATE_PAYMENT_METHOD:
      return {
        ...state,
        updating: true,
        errors: '',
      };
    case types.SUCCESS_UPDATE_PAYMENT_METHOD:
      return {
        ...state,
        updating: false,
      };
    case types.ERROR_UPDATE_PAYMENT_METHOD:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      };

    case types.LOGOUT_USER:
      return { ...initialSettings };
    default:
      return state;
  }
};

export default paymentMethod;
