import * as types from 'Constants/actionTypes';
import FetchService from 'utils/FetchService';

export function listResourceRecords() {
  return (dispatch) => {
    dispatch({ type: types.FETCH_RESOURCE_RECORDS_INITIATED });
    FetchService.getResourceRecords()
      .then((Response) => {
        dispatch({ type: types.FETCH_RESOURCE_RECORDS_SUCCESS, Response });
      })
      .catch((error) => {
        dispatch({ type: types.FETCH_RESOURCE_RECORDS_ERROR, error });
      });
  };
}

export function deleteResourceRecord(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.DELETE_RESOURCE_RECORD_INITIATED });
      const response = await FetchService.deleteResourceRecord(id);
      if (response.error) {
        throw response.error;
      } else {
        return dispatch({ type: types.DELETE_RESOURCE_RECORD_SUCCESS, id, response });
      }
    } catch (error) {
      return dispatch({ type: types.DELETE_RESOURCE_RECORD_ERROR, error });
    }
  };
}

export function reconfigureResourceRecord(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.RECONFIGURE_RESOURCE_RECORD_INITIATED });
      const response = await FetchService.reconfigureResourceRecord(id);
      if (response.error) {
        throw response.error;
      }
      dispatch(listResourceRecords());
      return dispatch({ type: types.RECONFIGURE_RESOURCE_RECORD_SUCCESS });
    } catch (error) {
      return dispatch({ type: types.RECONFIGURE_RESOURCE_RECORD_ERROR, error });
    }
  };
}

export function addResourceRecord(domain, name) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.ADD_RESOURCE_RECORD_INITIATED });
      const response = await FetchService.addResourceRecord(domain, name);
      if (response.error) {
        throw response.error;
      }
      dispatch(listResourceRecords());
      return dispatch({ type: types.ADD_RESOURCE_RECORD_SUCCESS, response });
    } catch (error) {
      let err = String(error);
      if (error.detail) {
        try {
          err = error.detail;
        } catch (e) {
          err = 'Error when parsing error';
        }
      } else if (error.name) {
        err = `Name: ${error.name[0]}`;
      } else if (error.domain) {
        err = `Domain: ${error.domain[0]}`;
      } else if (error.non_field_errors) {
        err = { notEnoughSlots: true };
      }
      return dispatch({
        type: types.ADD_RESOURCE_RECORD_ERROR,
        error: err,
      });
    }
  };
}
