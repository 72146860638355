// default imports
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
// custom components
import FetchService from 'utils/FetchService';

import AlertList from '../AlertList/AlertList';
import SelectService from '../SelectService/SelectService';
// styling
import './AddAlertToDomain.css';

export class AddAlertToDomain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      connectedService: {},
      availabilityLevel: 'no_alert',
      integrityLevel: 'no_alert',
      errorMessage: '',
      addProcessSymbol: '',
      disableButtons: false,
    };
    this.updateConnectedService = this.updateConnectedService.bind(this);
    this.updateAlertLevel = this.updateAlertLevel.bind(this);
    this.saveAlert = this.saveAlert.bind(this);
  }

  saveAlert() {
    if (this.state.connectedService.value) {
      this.setState({
        errorMessage: '',
        disableButtons: true,
        addProcessSymbol: 'fas fa-spin fa-spinner in-progress',
      });
      FetchService.addAlertRules({
        domain: this.props.domainName,
        service_key: this.state.connectedService.value,
        availability_level: this.state.availabilityLevel,
        integrity_level: this.state.integrityLevel,
      })
        .then(() => {
          this.setState({
            errorMessage: '',
            addProcessSymbol: 'fas fa-check success',
          });
          this.props.close('add');
        })
        .catch(() => {
          this.setState({
            errorMessage: "Service couldn't be added to domain",
            addProcessSymbol: 'fas fa-times error',
            disableButtons: false,
          });
        });
    } else {
      this.setState({
        errorMessage: 'You need to select a Service',
        addProcessSymbol: 'fas fa-times error',
        disableButtons: false,
      });
    }
  }

  updateConnectedService(val) {
    this.setState({
      connectedService: val,
    });
  }

  updateAlertLevel(avail, key) {
    if (avail === 'availabilitySelections') {
      this.setState({
        availabilityLevel: key,
      });
    } else {
      this.setState({
        integrityLevel: key,
      });
    }
  }

  render() {
    return (
      <div>
        <p className="configureSubHeading">Select Service</p>
        <SelectService
          services={this.props.information}
          connectedService={this.updateConnectedService}
        />
        <AlertList updateAlertLevel={this.updateAlertLevel} />
        <div className="modal-edit-buttons">
          <div className="error error-box">
            {' '}
            {this.state.errorMessage} <i className={this.state.addProcessSymbol} />
          </div>
          <Button
            className="btn btn-success"
            disabled={this.state.disableButtons}
            onClick={this.saveAlert}
          >
            Save
          </Button>
        </div>
      </div>
    );
  }
}

AddAlertToDomain.propTypes = {
  information: PropTypes.shape().isRequired,
  close: PropTypes.func.isRequired,
  domainName: PropTypes.string.isRequired,
};

export default AddAlertToDomain;
