import * as types from 'Constants/actionTypes';

const initialSettings = {
  fetching: false,
  fetched: false,
  results: [],
  error: '',
  pagination: {},
  busy: false,
  patching_manage_user: false,
  deleting_manage_user: false,
};

const users = (state = initialSettings, action) => {
  switch (action.type) {
    case types.FETCH_USERS_INITIATED:
      return {
        ...state,
        fetching: true,
        fetched: false,
        busy: true,
        pagination: {},
      };
    case types.FETCH_USERS_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        results: action.results,
        pagination: action.pagination,
        busy: false,
      };
    case types.FETCH_USERS_ERROR:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.error,
      };
    case types.CHANGE_PAGE:
      return {
        ...state,
        pagination: { ...state.pagination, page: action.page },
      };
    case types.FETCH_NEXT_INIT:
      return {
        ...state,
        busy: true,
      };
    case types.FETCH_NEXT_SUCCESS:
      return {
        ...state,
        busy: false,
        results: [...state.results, ...action.results],
        pagination: {
          ...state.pagination,
          ...action.pagination,
        },
      };
    case types.FETCH_NEXT_ERROR:
      return {
        ...state,
        busy: false,
        error: action.error,
      };
    case types.PATCH_MANAGE_USER_INITIATED:
      return { ...state, patching_manage_user: true };
    case types.PATCH_MANAGE_USER_SUCCESS: {
      const { results } = state;
      const { user } = action;
      const index = results.findIndex((i) => i.id === user.id);
      results.splice(index, 1, user);
      return { ...state, patching_manage_user: false, results };
    }
    case types.PATCH_MANAGE_USER_ERROR:
      return { ...state, patching_manage_user: false };
    case types.DELETE_MANAGE_USER_INITIATED:
      return { ...state, deleting_manage_user: true };
    case types.DELETE_MANAGE_USER_SUCCESS:
      return {
        ...state,
        deleting_manage_user: false,
        results: state.results.filter((i) => i.id !== action.id),
      };
    case types.DELETE_MANAGE_USER_ERROR:
      return { ...state, deleting_manage_user: false };
    case types.LOGOUT_USER:
      return { ...initialSettings };
    default:
      return state;
  }
};

export default users;
