// default imports
import CookieAlert from 'Components/CookieAlert/CookieAlert';
// Custom components
import Footer from 'Components/Footer/Footer';
import Header from 'Components/Header/Header';
import createHistory from 'history/createBrowserHistory';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Switch, Router, withRouter } from 'react-router-dom';

// Styling
import './Container.css';

const history = createHistory({
  basename: process.env.REACT_APP_BASENAME,
});
const HeaderWithRouter = withRouter(Header);

export class Container extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      previousAcceptedPayment: this.props.acceptedPayment(),
      alertDismissed: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const nextAcceptedPayment = nextProps.acceptedPayment();
    if (nextAcceptedPayment !== this.state.previousAcceptedPayment) {
      this.setState({
        previousAcceptedPayment: nextAcceptedPayment,
      });
    }
  }

  noActiveCardAlert = () => (
    <div className="alert alert-danger alert-dismissible no-card-alert" role="alert">
      <Button
        type="button"
        className="close"
        data-dismiss="alert"
        aria-label="Close"
        onClick={() => {
          this.setState(
            {
              alertDismissed: true,
            },
            () => this.props.StorageService.setAlertDismissTime(moment().format('YYYYMMDDHHmmss')),
          );
        }}
      >
        <span aria-hidden="true">&times;</span>
      </Button>
      You have no active credit card registered. Go to Manage Subscriptions to add one.
    </div>
  );

  render() {
    const { locations, init, FetchService, StorageService, auth, children, paymentMethodData } =
      this.props;

    let childs = null;
    if (children) {
      childs = React.Children.map(this.props.children, (child) =>
        React.cloneElement(child, {
          auth: auth.auth,
          FetchService,
          StorageService,
          locations,
          plans: init.plans,
        }),
      );
    }
    const { alertDismissed } = this.state;

    const hasNoCard = paymentMethodData.fetched
      ? !paymentMethodData.paymentMethods.results.length
      : false;
    const alertDismissedTime = StorageService.getAlertDismissTime();
    const dismissTime = alertDismissedTime ? moment(alertDismissedTime, 'YYYYMMDDHHmmss') : null;
    const dismissedRecently = dismissTime ? dismissTime > moment().subtract(1, 'days') : false;

    return (
      <Router history={history}>
        <div>
          <CookieAlert />
          <HeaderWithRouter auth={auth.auth} />
          {auth.auth.loggedIn() && // Conditional rendering of header
            hasNoCard &&
            !alertDismissed &&
            !dismissedRecently &&
            auth.user.is_customer &&
            this.noActiveCardAlert()}
          <div className="contentContainer container" id="contentContainer">
            <Switch>{childs}</Switch>
          </div>
          <Footer auth={auth.auth} />
        </div>
      </Router>
    );
  }
}

Container.propTypes = {
  init: PropTypes.shape(),
  auth: PropTypes.shape({ auth: PropTypes.object }).isRequired,
  FetchService: PropTypes.shape().isRequired,
  StorageService: PropTypes.shape().isRequired,
  children: PropTypes.arrayOf(PropTypes.object).isRequired,
  locations: PropTypes.func.isRequired,
  acceptedPayment: PropTypes.func.isRequired,
  paymentMethodData: PropTypes.shape({
    fetching: PropTypes.bool,
    fetched: PropTypes.bool,
    adding: PropTypes.bool,
    updating: PropTypes.bool,
    errors: PropTypes.object,
    paymentMethods: PropTypes.shape({
      count: PropTypes.number,
      results: PropTypes.arrayOf(PropTypes.object),
    }),
    busy: PropTypes.bool,
  }).isRequired,
};

Container.defaultProps = {
  init: undefined,
  paymentMethodData: {},
};

function mapStateToProps(state) {
  return {
    paymentMethodData: state.paymentMethod,
  };
}

export default connect(mapStateToProps)(Container);
