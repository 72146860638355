import * as types from 'Constants/actionTypes';

const initialSettings = {
  invoices: {
    fetching: false,
    fetched: false,
    data: undefined,
    errors: undefined,
    busy: false,
    message: undefined,
  },
};

const purchases = (state = initialSettings, action) => {
  switch (action.type) {
    case types.INITIALIZE_GET_INVOICES:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          fetching: true,
          fetched: false,
          data: undefined,
        },
      };
    case types.SUCCESS_GET_INVOICES:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          fetching: false,
          fetched: true,
          data: action.invoices,
        },
      };
    case types.ERROR_GET_INVOICES:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          fetching: false,
          fetched: false,
          errors: action.errors,
        },
      };
    case types.INITIALIZE_PAY_INVOICE:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          busy: true,
        },
      };
    case types.SUCCESS_PAY_INVOICE:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          busy: false,
        },
      };
    case types.ERROR_PAY_INVOICE:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          busy: false,
          errors: action.errors,
        },
      };
    case types.LOGOUT_USER:
      return { ...initialSettings };
    default:
      return state;
  }
};

export default purchases;
