import * as types from 'Constants/actionTypes';
import FetchService from 'utils/FetchService';

export function getLocations() {
  return (dispatch) => {
    dispatch({ type: types.FETCH_LOCATIONS_INIT });
    FetchService.getLocations()
      .then((Response) => {
        dispatch({ type: types.FETCH_LOCATIONS_SUCCESS, Response });
      })
      .catch((err) => {
        dispatch({ type: types.FETCH_LOCATIONS_ERROR, Response: err });
      });
  };
}

export function getPlans() {
  return (dispatch) => {
    dispatch({ type: types.FETCH_PLANS_INIT });
    FetchService.getPlans()
      .then((Response) => {
        dispatch({ type: types.FETCH_PLANS_SUCCESS, Response });
      })
      .catch((err) => {
        dispatch({ type: types.FETCH_LOCATIONS_ERROR, Response: err });
      });
  };
}
