import { initAuth0, logout, getMe } from 'Actions/authentication';
// Actions
import { getLocations, getPlans } from 'Actions/init';
import { getPaymentMethods } from 'Actions/paymentMethod';
import Loading from 'Components/Loading/Loading';
// constants
import * as paymentTypes from 'Constants/paymentTypes';
import Container from 'Containers/Container/Container';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import FetchService from 'utils/FetchService';
import StorageService from 'utils/StorageService';

import ConfigureAlerts from './ConfigureAlerts/ConfigureAlerts';
import ConfigureApiKeys from './ConfigureApiKeys/ConfigureApiKeys';
import ConfigureDomains from './ConfigureDomains/ConfigureDomains';
import ConfigureSubscriptions from './ConfigureSubscriptions/ConfigureSubscriptions';
import Dashboard from './Dashboard/Dashboard';
import ForgotPassword from './ForgotPassword/ForgotPassword';
// import Admin from './Admin/Admin';
import HistoryPage from './History/History';
import HistoryDashboard from './HistoryDashboard/HistoryDashboard';
// Child routes
import Login from './Login/Login';
import PageNotFound from './PageNotFound/PageNotFound';
import PagerDutyConnect from './PagerDutyConnect/PagerDutyConnect';
import Registration from './Registration/Registration';
import Signup from './Signup/Signup';
import ConfigureUsers from './Users/Users';

/**
 * Creates a new element with the corresponding function and props
 * @param {Function} component
 * @param {Object} rest
 */
const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return React.createElement(component, finalProps);
};

/**
 * Takes the component past to the route +
 * parents props and renders a new element with the passed props.
 * @param {Function, Object} param0
 */
function PropsRoute({ component, ...rest }) {
  return (
    <Route {...rest} render={(routeProps) => renderMergedProps(component, routeProps, rest)} />
  );
}
PropsRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

/**
 * Takes the component past to the route +
 * parents props and renders a new element with the passed props if
 * the user is Authorized, otherwise user is redirected to login.
 * @param {Function, Object} param0
 */
function PrivateRoute({ component, ...rest }) {
  let path = '';
  if (rest.requireAuth()) {
    const payment = rest.acceptedPayment();
    if (payment !== 'OK') {
      if (
        (payment === paymentTypes.NOT_A_CUSTOMER || payment === paymentTypes.NO_SUBSCRIPTION) &&
        rest.path !== '/registration'
      ) {
        path = '/registration';
      }
    } else if (payment === 'OK' && rest.path === '/registration') {
      path = '/dashboard';
    }
  } else {
    path = '/login';
  }
  return (
    <Route
      {...rest}
      render={(routeProps) =>
        !path ? (
          renderMergedProps(component, routeProps, rest)
        ) : (
          <Redirect
            to={{
              pathname: path,
              state: { from: routeProps.location },
            }}
          />
        )
      }
    />
  );
}
PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

function paymentNotRequired() {
  return 'OK';
}

export class Routes extends Component {
  componentWillMount() {
    this.props.dispatch(getLocations());
    this.props.dispatch(getPlans());
    this.props.dispatch(initAuth0());
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.auth.loggedIn() && !nextProps.auth.userFetched) {
      this.props.dispatch(getMe());
      this.props.dispatch(getPaymentMethods());
    }
  }

  /**
   * Called every time user enters a private route
   */
  requireAuth = () => {
    if (!this.props.auth.auth.loggedIn()) {
      this.props.dispatch(logout(this.props.auth));
      return false;
    }
    return true;
  };

  acceptedPayment = () => {
    switch (this.props.auth.payment) {
      case paymentTypes.STRIPE_TRIAL:
        return 'OK';
      case paymentTypes.STRIPE_ACTIVE:
        return 'OK';
      case paymentTypes.MANUALLY_PAYING_CUSTOMER:
        return 'OK';
      case paymentTypes.DELINQUENT:
        return paymentTypes.DELINQUENT;
      case paymentTypes.NOT_A_CUSTOMER:
        return paymentTypes.NOT_A_CUSTOMER;
      case paymentTypes.FREE_USER:
        return paymentTypes.FREE_USER;
      case paymentTypes.NO_SUBSCRIPTION:
        return paymentTypes.NO_SUBSCRIPTION;
      default:
        return undefined;
    }
  };

  subscribedOrFree = () => {
    switch (this.props.auth.payment) {
      case paymentTypes.STRIPE_TRIAL:
        return 'OK';
      case paymentTypes.STRIPE_ACTIVE:
        return 'OK';
      case paymentTypes.MANUALLY_PAYING_CUSTOMER:
        return 'OK';
      case paymentTypes.DELINQUENT:
        return paymentTypes.DELINQUENT;
      case paymentTypes.NOT_A_CUSTOMER:
        return paymentTypes.NOT_A_CUSTOMER;
      case paymentTypes.FREE_USER:
        return 'OK';
      case paymentTypes.NO_SUBSCRIPTION:
        return paymentTypes.NO_SUBSCRIPTION;
      default:
        return undefined;
    }
  };

  render() {
    const { locations, auth, init } = this.props;
    if (locations.locations_fetched && init.plans_fetched && auth.authServiceCreated) {
      return (
        <Container
          auth={auth}
          FetchService={FetchService}
          StorageService={StorageService}
          locations={getLocations}
          init={init}
          acceptedPayment={this.acceptedPayment}
        >
          <PropsRoute path="/login" component={Login} />
          <PropsRoute path="/sign-up" exact component={Signup} />
          <PropsRoute path="/notfound" exact component={PageNotFound} />
          <PropsRoute path="/forgot-password" component={ForgotPassword} />
          <PrivateRoute
            path="/dashboard"
            exact
            component={Dashboard}
            requireAuth={this.requireAuth}
            acceptedPayment={this.subscribedOrFree}
          />
          <PrivateRoute
            path="/registration"
            exact
            component={Registration}
            requireAuth={this.requireAuth}
            acceptedPayment={this.acceptedPayment}
          />
          <PrivateRoute
            path="/configure/domains"
            exact
            component={ConfigureDomains}
            requireAuth={this.requireAuth}
            acceptedPayment={this.subscribedOrFree}
          />
          <PrivateRoute
            path="/configure/subscriptions"
            exact
            component={ConfigureSubscriptions}
            requireAuth={this.requireAuth}
            acceptedPayment={paymentNotRequired}
          />
          <PrivateRoute
            path="/configure/alerts"
            exact
            component={ConfigureAlerts}
            requireAuth={this.requireAuth}
            acceptedPayment={this.acceptedPayment}
          />
          <PrivateRoute
            path="/configure/users"
            exact
            component={ConfigureUsers}
            requireAuth={this.requireAuth}
            acceptedPayment={paymentNotRequired}
          />
          <PrivateRoute
            path="/configure/apikeys"
            exact
            component={ConfigureApiKeys}
            requireAuth={this.requireAuth}
            acceptedPayment={this.acceptedPayment}
          />
          <PrivateRoute
            path="/connect"
            component={PagerDutyConnect}
            requireAuth={this.requireAuth}
            acceptedPayment={this.acceptedPayment}
          />
          <PrivateRoute
            path="/history"
            exact
            component={HistoryPage}
            requireAuth={this.requireAuth}
            acceptedPayment={this.acceptedPayment}
          />
          <PrivateRoute
            path="/history/:domain_id"
            component={HistoryDashboard}
            requireAuth={this.requireAuth}
            acceptedPayment={this.acceptedPayment}
          />
          <Redirect from="/" exact to="login" />
          <Redirect from="*" to="/notfound" />
        </Container>
      );
    }
    return <Loading />;
  }
}

Routes.propTypes = {
  auth: PropTypes.shape({
    authServiceCreated: PropTypes.bool.isRequired,
    authenticated: PropTypes.bool.isRequired,
    auth: PropTypes.object.isRequired,
    payment: PropTypes.string,
    paymentFetched: PropTypes.bool.isRequired,
    userFetched: PropTypes.bool.isRequired,
  }).isRequired,
  init: PropTypes.shape({
    plans_fetched: PropTypes.bool.isRequired,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    locations_fetched: PropTypes.bool.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.authentication,
    locations: state.locations,
    init: state.init,
  };
}

export default connect(mapStateToProps)(Routes);
