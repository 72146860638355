import { getBaseAPI, updateBaseAPI, deleteBaseAPI, get, baseFetch, baseAPI } from './HttpService';
import StorageService from './StorageService';

// Locations
function getLocations() {
  return getBaseAPI('locations/');
}

// Domains
function getDomains() {
  return getBaseAPI('domains/', { Authorization: `JWT ${StorageService.getAccessToken()}` });
}

function getDomain(id) {
  return getBaseAPI(`domains/${id}/`, { Authorization: `JWT ${StorageService.getAccessToken()}` });
}

function deleteDomain(id) {
  return deleteBaseAPI(`domains/${id}/`, {
    Authorization: `JWT ${StorageService.getAccessToken()}`,
    'Content-type': 'application/json',
    Accept: 'application/json',
  });
}

function addDomain(data) {
  return updateBaseAPI('domains/', 'POST', data, {
    Authorization: `JWT ${StorageService.getAccessToken()}`,
    'Content-type': 'application/json',
    Accept: 'application/json',
  });
}

function addLocation(id, data) {
  return updateBaseAPI(`domains/${id}/`, 'PUT', data, {
    Authorization: `JWT ${StorageService.getAccessToken()}`,
    'Content-type': 'application/json',
    Accept: 'application/json',
  });
}

function reconfigureDomain(id, data) {
  return updateBaseAPI(`domains/${id}/reconfigure/`, 'POST', data, {
    Authorization: `JWT ${StorageService.getAccessToken()}`,
    'Content-type': 'application/json',
    Accept: 'application/json',
  });
}

function updateDomain(id, data) {
  return updateBaseAPI(`domains/${id}/`, 'PUT', data, {
    Authorization: `JWT ${StorageService.getAccessToken()}`,
    'Content-type': 'application/json',
    Accept: 'application/json',
  });
}

// Resource-records
function getResourceRecords() {
  return getBaseAPI('resource-records/', {
    Authorization: `JWT ${StorageService.getAccessToken()}`,
  });
}

function deleteResourceRecord(id) {
  return deleteBaseAPI(`resource-records/${id}/`, {
    Authorization: `JWT ${StorageService.getAccessToken()}`,
    'Content-type': 'application/json',
    Accept: 'application/json',
  });
}

function addResourceRecord(domain, name) {
  return updateBaseAPI(
    'resource-records/',
    'POST',
    { domain, name },
    {
      Authorization: `JWT ${StorageService.getAccessToken()}`,
      'Content-type': 'application/json',
      Accept: 'application/json',
    },
  );
}

function reconfigureResourceRecord(id) {
  return updateBaseAPI(
    `resource-records/${id}/reconfigure/`,
    'PATCH',
    {},
    {
      Authorization: `JWT ${StorageService.getAccessToken()}`,
      'Content-type': 'application/json',
      Accept: 'application/json',
    },
  );
}

// Check-rules
function sendNewConfiguration(obj, id) {
  return updateBaseAPI(`check-rules/${id}/`, 'PUT', obj, {
    Authorization: `JWT ${StorageService.getAccessToken()}`,
    'Content-type': 'application/json',
    Accept: 'application/json',
  });
}

// Alert-rules
function getAlertRules() {
  return getBaseAPI('alert-rules/', { Authorization: `JWT ${StorageService.getAccessToken()}` });
}

function deleteAlertRules(id) {
  return deleteBaseAPI(`alert-rules/${id}/`, {
    Authorization: `JWT ${StorageService.getAccessToken()}`,
    'Content-type': 'application/json',
    Accept: 'application/json',
  });
}

function addAlertRules(data) {
  return updateBaseAPI('alert-rules/', 'POST', data, {
    Authorization: `JWT ${StorageService.getAccessToken()}`,
    'Content-type': 'application/json',
    Accept: 'application/json',
  });
}

function updateAlertRules(id, data) {
  return updateBaseAPI(`alert-rules/${id}/`, 'PATCH', data, {
    Authorization: `JWT ${StorageService.getAccessToken()}`,
    'Content-type': 'application/json',
    Accept: 'application/json',
  });
}

// Services
function getServices() {
  return getBaseAPI('services/', { Authorization: `JWT ${StorageService.getAccessToken()}` });
}

function deleteService(id) {
  return deleteBaseAPI(`services/${id}/`, {
    Authorization: `JWT ${StorageService.getAccessToken()}`,
    'Content-type': 'application/json',
    Accept: 'application/json',
  });
}

function addService(data) {
  return updateBaseAPI('services/', 'POST', data, {
    Authorization: `JWT ${StorageService.getAccessToken()}`,
    'Content-type': 'application/json',
    Accept: 'application/json',
  });
}

// Subscriptions
function getSubscriptions() {
  return getBaseAPI('subscriptions/', { Authorization: `JWT ${StorageService.getAccessToken()}` });
}

function getSubscription(subscriptionId) {
  return getBaseAPI(`subscriptions/${subscriptionId}/`, {
    Authorization: `JWT ${StorageService.getAccessToken()}`,
  });
}

function deleteSubscription(subscriptionId) {
  return deleteBaseAPI(`subscriptions/${subscriptionId}/`, {
    Authorization: `JWT ${StorageService.getAccessToken()}`,
  });
}

function createSubscription(subscription) {
  return updateBaseAPI('subscriptions/', 'POST', subscription, {
    Authorization: `JWT ${StorageService.getAccessToken()}`,
    'Content-type': 'application/json',
    Accept: 'application/json',
  });
}

function updateSubscription(subscriptionId, subscription) {
  return updateBaseAPI(`subscriptions/${subscriptionId}/`, 'PUT', subscription, {
    Authorization: `JWT ${StorageService.getAccessToken()}`,
    'Content-type': 'application/json',
    Accept: 'application/json',
  });
}

// Customer
function getCustomers() {
  return getBaseAPI('customers/', { Authorization: `JWT ${StorageService.getAccessToken()}` });
}

function addCustomer(data) {
  return updateBaseAPI('customers/', 'POST', data, {
    Authorization: `JWT ${StorageService.getAccessToken()}`,
    'Content-type': 'application/json',
    Accept: 'application/json',
  });
}

// Plans
function getPlans() {
  return getBaseAPI('plans/');
}

// User
function getUserInfo(token) {
  return getBaseAPI('users/profile/', { Authorization: `JWT ${token}` });
}

function getMe() {
  return getBaseAPI('users/me/', { Authorization: `JWT ${StorageService.getAccessToken()}` });
}

// Monitor
function getDashboardData() {
  return getBaseAPI('monitor/', { Authorization: `JWT ${StorageService.getAccessToken()}` });
}

function getDomainDashboardData(id) {
  return getBaseAPI(`monitor/${id}/`, { Authorization: `JWT ${StorageService.getAccessToken()}` });
}

// History
function getHistory(searchQuery) {
  return getBaseAPI(`history/${searchQuery}`, {
    Authorization: `JWT ${StorageService.getAccessToken()}`,
  });
}

function fetchNextHistory(url) {
  return get(url, { Authorization: `JWT ${StorageService.getAccessToken()}` });
}

function getDetailedHistory(id) {
  return getBaseAPI(`history/${id}/`, { Authorization: `JWT ${StorageService.getAccessToken()}` });
}

// Invoices
function getInvoices() {
  return getBaseAPI('invoices/', { Authorization: `JWT ${StorageService.getAccessToken()}` });
}

function payInvoice(id, invoice) {
  return updateBaseAPI(`invoices/${id}/pay/`, 'POST', invoice, {
    Authorization: `JWT ${StorageService.getAccessToken()}`,
    'Content-type': 'application/json',
    Accept: 'application/json',
  });
}

// Invitation
function signUpWithToken(body) {
  return updateBaseAPI('invitation/accept/', 'POST', body, {
    'Content-type': 'application/json',
    Accept: 'application/json',
  });
}

function sendUserInvitation(email, allowedDomains) {
  return updateBaseAPI(
    'invitation/',
    'POST',
    {
      email,
      allowed_domain_ids: allowedDomains,
    },
    {
      Authorization: `JWT ${StorageService.getAccessToken()}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  );
}

function getInvitations() {
  return getBaseAPI('invitation/', { Authorization: `JWT ${StorageService.getAccessToken()}` });
}

function patchInvitation(id, allowedDomains) {
  return updateBaseAPI(
    `invitation/${id}/`,
    'PATCH',
    {
      allowed_domain_ids: allowedDomains,
    },
    {
      Authorization: `JWT ${StorageService.getAccessToken()}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  );
}

function deleteInvitation(id) {
  return deleteBaseAPI(`invitation/${id}/`, {
    Authorization: `JWT ${StorageService.getAccessToken()}`,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  });
}

function resendInvitation(id) {
  return updateBaseAPI(
    `invitation/${id}/resend/`,
    'POST',
    {},
    {
      Authorization: `JWT ${StorageService.getAccessToken()}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  );
}

// Manage-user
function getManageUsers() {
  return getBaseAPI('manage-user/', { Authorization: `JWT ${StorageService.getAccessToken()}` });
}

function getManageUser(id) {
  return getBaseAPI(`manage-user/${id}/`, {
    Authorization: `JWT ${StorageService.getAccessToken()}`,
  });
}

function patchManageUser(id, allowedDomainIds) {
  return updateBaseAPI(
    `manage-user/${id}/`,
    'PATCH',
    { allowed_domain_ids: allowedDomainIds },
    {
      Authorization: `JWT ${StorageService.getAccessToken()}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  );
}

function deleteManageUser(id) {
  return deleteBaseAPI(`manage-user/${id}/`, {
    Authorization: `JWT ${StorageService.getAccessToken()}`,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  });
}

function getStaffUsers(email) {
  return baseFetch(`${baseAPI}staff-users/?email=${email || ''}`, {
    method: 'GET',
    headers: { Authorization: `JWT ${StorageService.getAccessToken()}` },
    ignoreImpersonate: true,
  });
}

// Payment-method
function getPaymentMethods() {
  return getBaseAPI('payment-method/', { Authorization: `JWT ${StorageService.getAccessToken()}` });
}

function getPaymentMethod(paymentMethodId) {
  return getBaseAPI(`payment-method/${paymentMethodId}/`, {
    Authorization: `JWT ${StorageService.getAccessToken()}`,
  });
}

function deletePaymentMethod(paymentMethodId) {
  return deleteBaseAPI(`payment-method/${paymentMethodId}/`, {
    Authorization: `JWT ${StorageService.getAccessToken()}`,
  });
}

function addPaymentMethod(paymentMethod) {
  return updateBaseAPI(
    'payment-method/',
    'POST',
    {
      payment_method_id: paymentMethod,
    },
    {
      Authorization: `JWT ${StorageService.getAccessToken()}`,
      'Content-type': 'application/json',
      Accept: 'application/json',
    },
  );
}

function updatePaymentMethod(paymentMethodId, paymentMethod) {
  return updateBaseAPI(`payment-method/${paymentMethodId}/`, 'PATCH', paymentMethod, {
    Authorization: `JWT ${StorageService.getAccessToken()}`,
    'Content-type': 'application/json',
    Accept: 'application/json',
  });
}

// Setup-intent
function getSetupIntent() {
  return updateBaseAPI(
    'setup-intent/',
    'POST',
    {},
    {
      Authorization: `JWT ${StorageService.getAccessToken()}`,
      'Content-type': 'application/json',
      Accept: 'application/json',
    },
  );
}

// Api Keys
function getApiKeys() {
  return getBaseAPI('api-token/', { Authorization: `JWT ${StorageService.getAccessToken()}` });
}

function createApiKey(name) {
  return updateBaseAPI(
    'api-token/',
    'POST',
    {
      name,
    },
    {
      Authorization: `JWT ${StorageService.getAccessToken()}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  );
}

function deleteApiKey(id) {
  return deleteBaseAPI(`api-token/${id}/`, {
    Authorization: `JWT ${StorageService.getAccessToken()}`,
  });
}

const FetchService = {
  getLocations,
  getDomains,
  getDomain,
  deleteDomain,
  addDomain,
  addLocation,
  reconfigureDomain,
  updateDomain,
  getResourceRecords,
  deleteResourceRecord,
  addResourceRecord,
  reconfigureResourceRecord,
  sendNewConfiguration,
  getAlertRules,
  deleteAlertRules,
  addAlertRules,
  updateAlertRules,
  getServices,
  deleteService,
  addService,
  getSubscriptions,
  getSubscription,
  deleteSubscription,
  createSubscription,
  updateSubscription,
  getCustomers,
  addCustomer,
  getPlans,
  getUserInfo,
  getMe,
  getDashboardData,
  getDomainDashboardData,
  getHistory,
  fetchNextHistory,
  getDetailedHistory,
  getInvoices,
  payInvoice,
  signUpWithToken,
  sendUserInvitation,
  getInvitations,
  patchInvitation,
  deleteInvitation,
  resendInvitation,
  getManageUsers,
  getManageUser,
  patchManageUser,
  deleteManageUser,
  getStaffUsers,
  getPaymentMethods,
  getPaymentMethod,
  deletePaymentMethod,
  addPaymentMethod,
  updatePaymentMethod,
  getSetupIntent,
  getApiKeys,
  createApiKey,
  deleteApiKey,
};

export default FetchService;
