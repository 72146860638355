import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';

import apiKeys from './apiKeys';
import authentication from './authentication';
import dashboard from './dashboard';
import domains from './domains';
import history from './history';
import historyDetailed from './historyDetailed';
import init from './init';
import invitedUsers from './invitedUsers';
import locations from './locations';
import paymentMethod from './paymentMethod';
import purchases from './purchases';
import resourceRecords from './resourceRecords';
import staffUsers from './staffUsers';
import subscriptions from './subscriptions';
import users from './users';

const rootReducer = combineReducers({
  routing: routerReducer,
  authentication,
  init,
  dashboard,
  locations,
  domains,
  purchases,
  history,
  historyDetailed,
  staffUsers,
  subscriptions,
  resourceRecords,
  users,
  invitedUsers,
  paymentMethod,
  apiKeys,
});

const application = (state, action) => {
  if (action.type === 'LOGOUT_USER') {
    return rootReducer(
      {
        ...state,
        dashboard: undefined,
        purchases: undefined,
        subscriptions: undefined,
      },
      action,
    );
  }
  return rootReducer(state, action);
};

export default application;
