import * as types from 'Constants/actionTypes';
import FetchService from 'utils/FetchService';

export function getPaymentMethods() {
  return (dispatch) => {
    dispatch({ type: types.INITIATE_GET_PAYMENT_METHODS });
    FetchService.getPaymentMethods().then((Response) => {
      if (Response.error) {
        dispatch({ type: types.ERROR_GET_PAYMENT_METHODS, Response });
      } else {
        dispatch({ type: types.SUCCESS_GET_PAYMENT_METHODS, Response });
      }
    });
  };
}

export function getPaymentMethod(paymentMethodId) {
  return (dispatch) => {
    dispatch({ type: types.INITIATE_GET_PAYMENT_METHOD });
    FetchService.getPaymentMethod(paymentMethodId)
      .then((Response) => {
        dispatch({ type: types.SUCCESS_GET_PAYMENT_METHOD, paymentMethods: Response.results });
      })
      .catch((error) => {
        dispatch({ type: types.ERROR_GET_PAYMENT_METHOD, errors: error });
      });
  };
}

export function deletePaymentMethod(paymentMethodId) {
  return (dispatch) => {
    dispatch({ type: types.INITIATE_DELETE_PAYMENT_METHOD });
    FetchService.deletePaymentMethod(paymentMethodId)
      .then(() => {
        dispatch({ type: types.SUCCESS_DELETE_PAYMENT_METHOD });
        dispatch(getPaymentMethods());
      })
      .catch((error) => {
        dispatch({ type: types.ERROR_DELETE_PAYMENT_METHOD, errors: error });
      });
  };
}

export function addPaymentMethod(paymentMethod) {
  return (dispatch) => {
    dispatch({ type: types.INITIATE_ADD_PAYMENT_METHOD });
    return FetchService.addPaymentMethod(paymentMethod)
      .then(() => {
        dispatch({ type: types.SUCCESS_ADD_PAYMENT_METHOD });
        dispatch(getPaymentMethods());
      })
      .catch((error) => {
        dispatch({ type: types.ERROR_ADD_PAYMENT_METHOD, errors: error });
      });
  };
}

export function updatePaymentMethod(paymentMethodId, paymentMethod) {
  return async (dispatch) => {
    dispatch({ type: types.INITIATE_UPDATE_PAYMENT_METHOD });
    await FetchService.updatePaymentMethod(paymentMethodId, paymentMethod)
      .then(() => {
        dispatch({ type: types.SUCCESS_UPDATE_PAYMENT_METHOD });
        dispatch(getPaymentMethods());
      })
      .catch((error) => {
        dispatch({ type: types.ERROR_UPDATE_PAYMENT_METHOD, errors: error });
      });
  };
}
