function setActive(status, color) {
  if (status === 'disabled') {
    return '#a8a9ad';
  }
  return color;
}

function setColor(value, greenIsBlack) {
  switch (value) {
    case 0:
      if (greenIsBlack) {
        return '#4c4c4c';
      }
      return '#08A500';

    case 1:
      return '#EDED00';
    case 2:
      return '#DD8C00';
    case 3:
      return '#DD0000';
    case 4:
      return '#8C8C8C';
    default:
      return '#8C8C8C';
  }
}

function setTextColor(value, greenIsBlack) {
  switch (value) {
    case 1:
      return '#EDED00';
    case 2:
      return '#DD8C00';
    case 3:
      return '#DD0000';
    case 4:
      return '#8C8C8C';
    case 5:
      return '#8C8C8C';
    default:
      if (greenIsBlack) {
        return '#4c4c4c';
      }
      return '#08A500';
  }
}

function setGlyph(value) {
  switch (value) {
    case 0:
      return 'fa-check-circle';
    case 1:
      return 'fa-exclamation-circle';
    case 2:
      return 'fa-times-circle';
    case 3:
      return 'fa-minus-circle';
    default:
      return 'fa-question-circle';
  }
}

const SymbolHelper = {
  setGlyph,
  setColor,
  setActive,
  setTextColor,
};
export default SymbolHelper;
