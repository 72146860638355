import * as types from 'Constants/actionTypes';
import FetchService from 'utils/FetchService';
import 'whatwg-fetch';

export function getApiKeys() {
  return (dispatch) => {
    dispatch({ type: types.FETCH_API_KEYS_INITIATED });
    FetchService.getApiKeys().then((response) => {
      if (response.error) {
        dispatch({ type: types.FETCH_API_KEYS_ERROR, error: response.error });
      } else {
        dispatch({
          type: types.FETCH_API_KEYS_SUCCESS,
          results: response.results,
          pagination: { next: response.next, previous: response.previous, page: 0 },
        });
      }
    });
  };
}

export function changePage(pageChange, data) {
  return (dispatch) => {
    const page =
      data.pagination.page + pageChange <= data.results.length / 40
        ? data.pagination.page + pageChange
        : data.pagination.page;
    dispatch({ type: types.CHANGE_PAGE, page });
    if (page * 40 >= data.results.length - 120 && data.pagination.next && !data.busy) {
      dispatch({ type: types.FETCH_NEXT_INIT });
      FetchService.fetchNextHistory(data.pagination.next).then((response) => {
        if (response.error) {
          dispatch({ type: types.FETCH_NEXT_ERROR, error: response.error });
        } else {
          dispatch({
            type: types.FETCH_NEXT_SUCCESS,
            results: response.results,
            pagination: { next: response.next, previous: response.previous },
          });
        }
      });
    }
  };
}

export const createApiKey = (apiKey) => async (dispatch) => {
  try {
    dispatch({ type: types.CREATE_API_KEYS_INITIATED });
    const key = await FetchService.createApiKey(apiKey);
    return dispatch({ type: types.CREATE_API_KEYS_SUCCESS, key });
  } catch (error) {
    return dispatch({ type: types.CREATE_API_KEYS_ERROR, error: String(error) });
  }
};

export const deleteApiKey = (id) => async (dispatch) => {
  try {
    dispatch({ type: types.DELETE_API_KEYS_INITIATED });
    await FetchService.deleteApiKey(id);
    return dispatch({ type: types.DELETE_API_KEYS_SUCCESS, id });
  } catch (error) {
    return dispatch({ type: types.DELETE_API_KEYS_ERROR, error: String(error) });
  }
};

const apiKeys = {
  getApiKeys,
  deleteApiKey,
  createApiKey,
  changePage,
};
export default apiKeys;
