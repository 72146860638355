// default imports
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Glyphicon } from 'react-bootstrap';

// custom components
// styling
import './Footer.css';

export class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFooter: true,
    };
    this.fullScreen = this.fullScreen.bind(this);
  }

  isLoggedIn() {
    return this.props.auth.loggedIn();
  }

  fullScreen() {
    const elem = document.getElementById('contentContainer');
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (this.state.showFooter) {
      document.getElementByClass('contentContainer').addClass('fullScreen');
      document.getElementByID('navbar').hide();
      this.setState({
        showFooter: !this.state.showFooter,
      });
    } else {
      document.getElementByClass('contentContainer').removeClass('fullScreen');
      document.getElementByID('#navbar').show();
      this.setState({
        showFooter: !this.state.showFooter,
      });
    }
  }

  render() {
    let fullScreenButton;
    if (this.isLoggedIn()) {
      fullScreenButton = (
        <Button className="fullScreenBtn" onClick={this.fullScreen}>
          <Glyphicon glyph={this.state.showFooter ? 'resize-full' : 'resize-small'} />
        </Button>
      );
    }

    return (
      <div className="footer" style={{ background: this.state.showFooter ? '#201751' : 'none' }}>
        {fullScreenButton}
      </div>
    );
  }
}

Footer.propTypes = {
  auth: PropTypes.shape({
    loggedIn: PropTypes.func,
  }).isRequired,
};

export default Footer;
