// default imports
import React from 'react';
import { Col } from 'react-bootstrap';

// custom components
// styling
import './EmailAlert.css';

function EmailAlert() {
  return (
    <div>
      <Col xs={12} className="sub-config-header" />
      <Col xs={12} className="config-content config-alert" />
    </div>
  );
}

export default EmailAlert;
