import * as types from 'Constants/actionTypes';
import StorageService from 'utils/StorageService';

const initialSettings = {
  fetching: false,
  data_fetched: false,
  domains: [],
  errors: {},
  copy_configuration: [],
  sort: StorageService.sorting(),
  configuration_changed: false,
  busy: false,
  has_updated: false,
};

function copyConfiguration(location) {
  const dns = { ...location.dns };
  const domainCheck = { ...location.domain_check };
  return {
    config_id: location.config_id,
    dns,
    domain_check: domainCheck,
    record_data: location.record_data || [],
    host: location.host,
  };
}

const dashboard = (state = initialSettings, action) => {
  const copyConfig = [];
  let newCopy = [];
  let domains = [];
  switch (action.type) {
    case types.FETCH_DOMAIN_DATA_INITIATED:
      return { ...state, fetching: true };
    case types.FETCH_DOMAIN_DATA_SUCCESS:
      if (state.busy && !state.has_updated) {
        return { ...state };
      }
      if (state.busy && state.has_updated) {
        return {
          ...state,
          busy: false,
          fetching: false,
          has_updated: false,
        };
      }
      if (action.responseList) {
        action.responseList.forEach((domain) => {
          domain.locations.forEach((location) => {
            const matching = state.copy_configuration.find(
              (el) => el.config_id === location.config_id,
            );
            copyConfig.push(matching || copyConfiguration(location));
          });
        });
      }
      return {
        ...state,
        fetching: false,
        data_fetched: true,
        domains: action.responseList,
        copy_configuration: copyConfig,
      };
    case types.FETCH_DOMAIN_DATA_ERROR:
      return {
        ...state,
        fetching: false,
      };
    case types.UPDATE_DASHBOARD_SORTING:
      return { ...state, sort: action.sort };
    case types.UPDATE_DOMAIN_CONFIGURATION:
      return { ...state, busy: true, has_updated: false };
    case types.UPDATE_DOMAIN_CONFIGURATION_FINISHED:
      if (!action.id) {
        return {
          ...state,
          has_updated: true,
        };
      }
      newCopy = state.copy_configuration.map((item) => {
        if (item.config_id === action.id) return copyConfiguration(action.newConf);
        return item;
      });
      domains = state.domains.map((domain) => ({
        ...domain,
        locations: domain.locations.map((location) => {
          if (location.config_id === action.id) {
            return { ...action.newConf, data: location.data };
          }
          return location;
        }),
      }));
      return {
        ...state,
        copy_configuration: newCopy,
        domains,
        has_updated: true,
      };
    case types.LOGOUT_USER:
      return {
        ...initialSettings,
      };
    default:
      return state;
  }
};

export default dashboard;
