import * as types from 'Constants/actionTypes';

const initialSettings = {
  fetching: false,
  fetched: false,
  results: {},
  error: '',
};

const historyDetailed = (state = initialSettings, action) => {
  switch (action.type) {
    case types.HISTORY_DETAILED_INIT:
      return {
        ...state,
        fetching: true,
        fetched: false,
        results: {},
      };
    case types.HISTORY_DETAILED_SUCCESS:
      return {
        ...state,
        fetched: true,
        fetching: false,
        results: action.data,
      };
    case types.HISTORY_DETAILED_ERROR:
      return {
        ...state,
        fetching: false,
        fetched: false,
        results: {},
        error: action.error,
      };
    case types.LOGOUT_USER:
      return { ...initialSettings };
    default:
      return state;
  }
};

export default historyDetailed;
