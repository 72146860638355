import * as types from 'Constants/actionTypes';
import FetchService from 'utils/FetchService';
import 'whatwg-fetch';

export const inviteUser = (email, domains) => async (dispatch) => {
  try {
    dispatch({ type: types.INVITE_USER_INITIATED });
    const response = await FetchService.sendUserInvitation(email, domains);
    return dispatch({
      type: types.INVITE_USER_SUCCESS,
      invitation: response,
      success: `An invitation has been sent to ${email}.`,
    });
  } catch (error) {
    let err = String(error);
    if (error.email) {
      [err] = error.email;
    } else if (error.allowed_domain_ids) {
      [err] = error.allowed_domain_ids;
    }
    return dispatch({ type: types.INVITE_USER_ERROR, error: err });
  }
};

export const deleteInvitation = (id) => async (dispatch) => {
  try {
    dispatch({ type: types.DELETE_INVITATION_INITIATED });
    await FetchService.deleteInvitation(id);
    return dispatch({ type: types.DELETE_INVITATION_SUCCESS, id });
  } catch (error) {
    return dispatch({ type: types.DELETE_INVITATION_ERROR, error: String(error) });
  }
};

export function listInvitedUsers() {
  return (dispatch) => {
    dispatch({ type: types.FETCH_INVITED_USERS_INITIATED });
    FetchService.getInvitations().then((response) => {
      if (response.error) {
        dispatch({ type: types.FETCH_INVITED_USERS_ERROR, error: response.error });
      } else {
        dispatch({
          type: types.FETCH_INVITED_USERS_SUCCESS,
          results: response.results,
          pagination: { next: response.next, previous: response.previous, page: 0 },
        });
      }
    });
  };
}

export const patchInvitation = (id, allowedDomains) => async (dispatch) => {
  try {
    dispatch({ type: types.PATCH_INVITATION_INITIATED });
    const invitation = await FetchService.patchInvitation(id, allowedDomains);
    return dispatch({ type: types.PATCH_INVITATION_SUCCESS, invitation, success: true });
  } catch (error) {
    let err = String(error);
    if (error.allowed_domain_ids) {
      [err] = error.allowed_domain_ids;
    }
    return dispatch({ type: types.PATCH_INVITATION_ERROR, error: err });
  }
};

export const resendInvitation = (id) => async (dispatch) => {
  try {
    dispatch({ type: types.RESEND_INVITATION_INITIATED });
    const invitation = await FetchService.resendInvitation(id);
    // Check invitation is returned as a JSON obj
    if (!invitation || !Number.isInteger(invitation.id)) {
      throw new Error('Invitation could not be updated.');
    }
    return dispatch({ type: types.RESEND_INVITATION_SUCCESS, invitation });
  } catch (unhandledError) {
    const error = String(unhandledError || 'Unknown error.');
    return dispatch({ type: types.RESEND_INVITATION_ERROR, error });
  }
};

const invitedUsers = {
  inviteUser,
  deleteInvitation,
  listInvitedUsers,
  patchInvitation,
  resendInvitation,
};
export default invitedUsers;
