// default imports
import {
  getDomainConfiguration,
  viewNewDomain,
  domainAdded,
  deleteDomain,
  reconfigureDomain,
} from 'Actions/domains';
import { getSubscriptions } from 'Actions/subscriptions';
// custom components
import Loading from 'Components/Loading/Loading';
import SidebarNavigation from 'Components/SidebarNavigation/SidebarNavigation';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';

import { SUBSCRIPTION_FREE } from '../../Constants/subscriptionTypes';
import StorageService from '../../utils/StorageService';
import AddDomain from './Components/AddDomain/AddDomain';
import DomainInformation from './Components/DomainInformation/DomainInformation';
import ResourceRecordInformation from './Components/ResourceRecordInformation/ResourceRecordInformation';
// styling
import './ConfigureDomains.css';

export class ConfigureDomains extends PureComponent {
  constructor(props) {
    super(props);
    props.getDomainConfiguration(props.domainStore.selected_domain);
    props.getSubscriptions();
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.domainStore &&
      this.props.domainStore.domains !== nextProps.domainStore.domains
    ) {
      nextProps.getSubscriptions();
    }
  }

  showDomain(manuallyPaying) {
    const { domainStore, subscriptionData } = this.props;
    const subscription = subscriptionData.subscriptions.results.length
      ? subscriptionData.subscriptions.results[0]
      : {};
    if (_.has(domainStore.selected_domain, 'name')) {
      return (
        <DomainInformation
          domain={domainStore.selected_domain}
          locations={this.props.locations}
          manuallyPaying={manuallyPaying}
          auth={this.props.auth}
          authentication={this.props.authentication}
          FetchService={this.props.FetchService}
          isDeleting={domainStore.delete_in_progress}
          deleteDomain={this.props.deleteDomain}
          subscription={subscription}
          reconfigureDomain={() => this.props.reconfigureDomain(domainStore.selected_domain.id)}
          reconfigureInProgress={domainStore.reconfigure_in_progress}
          reconfigureErrors={domainStore.reconfigure_errors}
          getDomainConfiguration={() =>
            this.props.getDomainConfiguration(domainStore.selected_domain)
          }
          plans={this.props.plans}
        />
      );
    }
    return (
      <AddDomain
        locations={this.props.locations}
        history={this.props.history}
        domainList={domainStore.domains.results}
        addDomain={this.addDomain}
        domainAdded={this.props.domainAdded}
        manuallyPaying={manuallyPaying}
        FetchService={this.props.FetchService}
        StorageService={this.props.StorageService}
        subscriptionInformation={subscription}
        plans={this.props.plans}
        hasFree={this.props.authentication.user.has_free_domain}
      />
    );
  }

  render() {
    const { domainStore, subscriptionData } = this.props;
    const manuallyPaying =
      this.props.authentication.user && this.props.authentication.user.manually_paying;
    if (
      !domainStore.fetching &&
      ((!subscriptionData.fetching && subscriptionData.subscriptions.count > 0) || manuallyPaying)
    ) {
      const hasDomain = _.has(domainStore.selected_domain, 'name');
      const isReadOnlyMode = StorageService.getReadOnlyMode();

      const subscription = subscriptionData.subscriptions.results.length
        ? subscriptionData.subscriptions.results[0]
        : {};
      let header = '';
      if (hasDomain) {
        header = domainStore.selected_domain.name;
      } else if (isReadOnlyMode) {
        header = 'No Domain selected';
      } else {
        header = 'Add Domain';
      }
      const subNavItems = [];
      if (!isReadOnlyMode) {
        subNavItems.push({ component: 'Add a domain' });
      }
      return (
        <Grid>
          <Row>
            <Col md={3}>
              <div>
                <SidebarNavigation
                  navheader="Domains"
                  links={[
                    ..._.map(domainStore.domains.results, (domain) => ({
                      component: domain.name,
                      subscription: domain.subscription_type,
                    })),
                    ...subNavItems,
                  ]}
                  current={domainStore.selected_domain.name || ''}
                  set={(id) => this.props.viewNewDomain(id, domainStore.domains)}
                  not_loaded={!domainStore.fetched}
                />
              </div>
            </Col>
            <Col md={9}>
              <Col xs={12} className="sub-config-header">
                {header}
              </Col>
              <Col xs={12} className="config-content">
                {(hasDomain || !isReadOnlyMode) && this.showDomain(manuallyPaying)}
              </Col>
              {hasDomain && domainStore.selected_domain.subscription_type !== SUBSCRIPTION_FREE && (
                <ResourceRecordInformation
                  subscription={subscription}
                  manuallyPaying={manuallyPaying}
                  selectedDomain={domainStore.selected_domain}
                  plans={this.props.plans}
                  FetchService={this.props.FetchService}
                  redirect={() => {}}
                />
              )}
            </Col>
          </Row>
        </Grid>
      );
    }
    return <Loading loadingText="Loading Domains..." />;
  }
}

ConfigureDomains.propTypes = {
  authentication: PropTypes.shape({
    user: PropTypes.shape({
      manually_paying: PropTypes.bool,
      has_free_domain: PropTypes.bool,
    }),
  }).isRequired,
  domainStore: PropTypes.shape({
    selected_domain: PropTypes.object,
    fetching: PropTypes.bool,
    reconfigure_in_progress: PropTypes.bool,
    fetched: PropTypes.bool,
    domains: PropTypes.object,
    reconfigure_errors: PropTypes.string.isRequired,
    delete_in_progress: PropTypes.bool,
  }).isRequired,
  subscriptionData: PropTypes.shape({
    subscriptions: PropTypes.shape({ count: PropTypes.number, results: PropTypes.array }),
    fetching: PropTypes.bool,
  }).isRequired,
  history: PropTypes.shape().isRequired,
  FetchService: PropTypes.shape().isRequired,
  StorageService: PropTypes.shape().isRequired,
  auth: PropTypes.shape().isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.array,
  }).isRequired,
  selected_domain: PropTypes.shape(),
  domainAdded: PropTypes.func.isRequired,
  getDomainConfiguration: PropTypes.func.isRequired,
  getSubscriptions: PropTypes.func.isRequired,
  viewNewDomain: PropTypes.func.isRequired,
  reconfigureDomain: PropTypes.func.isRequired,
  deleteDomain: PropTypes.func.isRequired,
  plans: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

ConfigureDomains.defaultProps = {
  selected_domain: {},
};

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
    domainStore: state.domains,
    subscriptionData: state.subscriptions,
    locations: state.locations,
    plans: state.init.plans,
  };
}

export default connect(mapStateToProps, {
  domainAdded,
  getDomainConfiguration,
  getSubscriptions,
  viewNewDomain,
  reconfigureDomain,
  deleteDomain,
})(ConfigureDomains);
