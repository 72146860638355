// default imports
import { getDomainConfiguration } from 'Actions/domains';
// actions
import { searchHistory, changePage } from 'Actions/history';
// custom imports
import Loading from 'Components/Loading/Loading';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';

import HistoryFilters from './Components/HistoryFilters/HistoryFilters';
import SearchResults from './Components/SearchResults/SearchResults';
// styles
import './History.css';

export class History extends PureComponent {
  constructor(props) {
    super(props);
    this.search = this.search.bind(this);
    this.changePagination = this.changePagination.bind(this);
  }

  componentWillMount() {
    if (!this.props.domains.fetched) {
      this.props.dispatch(getDomainConfiguration());
    }
  }

  search(filters) {
    this.props.dispatch(searchHistory(filters));
  }

  changePagination(page) {
    this.props.dispatch(changePage(page, this.props.history));
  }

  render() {
    const { locations, domains, history } = this.props;
    return (
      <Grid id="HistoryPage">
        <Col xs={12}>
          <Row className="filter-header">
            <Col xs={12}>History</Col>
          </Row>
          <Row className="filter-content">
            {locations.locations_fetched && domains.fetched ? (
              <HistoryFilters
                locations={locations.locations}
                domains={domains.domains.results}
                search={this.search}
              />
            ) : (
              <Loading loadingText="Loading filters..." />
            )}
          </Row>
        </Col>

        {history.fetched || history.fetching ? (
          <Col xs={12}>
            <Row className="filter-header">
              <Col xs={12}>Results</Col>
            </Row>
            <Row className="filter-content">
              {history.fetched ? (
                <SearchResults
                  results={history.results}
                  page={history.pagination.page}
                  changePagination={this.changePagination}
                  busy={history.busy}
                />
              ) : (
                <Loading loadingText="Loading Search Results..." />
              )}
            </Row>
          </Col>
        ) : (
          ''
        )}
      </Grid>
    );
  }
}

History.propTypes = {
  domains: PropTypes.shape({
    fetched: PropTypes.bool,
    domains: PropTypes.object,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    locations_fetched: PropTypes.bool,
    locations: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  history: PropTypes.shape({
    fetched: PropTypes.bool,
    fetching: PropTypes.bool,
    busy: PropTypes.bool,
  }).isRequired,
};

function mapStateToProps(store) {
  return {
    locations: store.locations,
    domains: store.domains,
    history: store.history,
  };
}

export default connect(mapStateToProps)(History);
