export function getMinNrOfLocationsForDomain() {
  return 3;
}

function addDomainToUserMetadata(currentUserMetadata, newDomain) {
  // Returns the user_metadata with the new domain added
  const newUserMetadata = currentUserMetadata;
  newUserMetadata.domains = newUserMetadata.domains ? newUserMetadata.domains : [];
  newUserMetadata.domains.push(newDomain);
  if (newUserMetadata.domains.length > currentUserMetadata.nr_of_domains) {
    newUserMetadata.nr_of_domains = parseInt(newUserMetadata.nr_of_domains, 10) + 1;
    newUserMetadata.locations_paid_for =
      parseInt(newUserMetadata.locations_paid_for, 10) + getMinNrOfLocationsForDomain();
  }
  newUserMetadata.nr_of_locations =
    parseInt(newUserMetadata.nr_of_locations, 10) + getMinNrOfLocationsForDomain();
  return newUserMetadata;
}

function defaultLocations(locations) {
  const locationList = locations.slice(0, 3).map((location) => location.location_id);
  return locationList;
}

function extractLocations(locations) {
  const locationList = locations.map((location) => location.location_id);
  return locationList;
}

function generateDefaultUserMetadata() {
  // returns an empty user_metadata object, ready to be pushed to auth0 on user creation
  const json = {
    type: 'UserSettings',
    domains: '',
    payment: '1',
    nextPayment: '',
    nr_of_domains: '0',
    nr_of_locations: '0',
    min_nr_of_locations: '3',
    locations_paid_for: '0',
    update_frequency: '300',
  };

  return json;
}

function generateLocationsForDomain(locations, allLocations) {
  const validLocations = [];

  const allLoc = allLocations.locations;
  Object.keys(locations).forEach((location) => {
    Object.keys(allLoc).forEach((all) => {
      if (all.location_id === location) {
        validLocations.push({
          geoloc: all.geoloc,
          location_id: location,
        });
      }
    });
  });
  return validLocations;
}

const GenerateUserMetadata = {
  addDomainToUserMetadata,
  defaultLocations,
  extractLocations,
  generateDefaultUserMetadata,
  generateLocationsForDomain,
  getMinNrOfLocationsForDomain,
};
export default GenerateUserMetadata;
