import PropTypes from 'prop-types';
import React from 'react';

function StatusIcon({ inProgress, error, ...props }) {
  let className = '';
  if (error) {
    className = 'fas fa-times error';
  } else if (inProgress) {
    className = 'fas fa-spinner fa-spin fa-fw in-progress';
  }
  return <i className={className} {...props} />;
}

StatusIcon.propTypes = {
  error: PropTypes.bool,
  inProgress: PropTypes.bool,
};

StatusIcon.defaultProps = {
  error: false,
  inProgress: false,
};

export default StatusIcon;
