import * as types from 'Constants/actionTypes';
import FetchService from 'utils/FetchService';
import 'whatwg-fetch';

export function searchHistory(filters) {
  return (dispatch) => {
    dispatch({ type: types.INIT_HISTORY_SEARCH, filters });
    let searchQuery = '';
    Object.keys(filters).forEach((filter) => {
      const data = filters[filter];
      const preString = searchQuery.length === 0 ? '?' : '&';
      if (data === null || data === '' || data.length === 0) return null;
      switch (filter) {
        case 'domain':
          searchQuery += `${preString}domain=${data}`;
          break;
        case 'end_date':
          searchQuery += `${preString}date_executed_max=${data}`;
          break;
        case 'start_date':
          searchQuery += `${preString}date_executed_min=${data}`;
          break;
        case 'severity':
          data.forEach((severity, index) => {
            if (index > 0) {
              searchQuery += `&severity=${severity}`;
            } else {
              searchQuery += `${preString}severity=${severity}`;
            }
          });
          break;
        case 'location':
          data.split(',').forEach((location) => {
            if (searchQuery.length === 0) {
              searchQuery += `?location_id=${location}`;
            } else {
              searchQuery += `&location_id=${location}`;
            }
          });
          break;
        default:
          break;
      }
      return null;
    });
    FetchService.getHistory(searchQuery).then((response) => {
      if (response.error) {
        dispatch({ type: types.INIT_HISTORY_SEARCH_ERROR, error: response.error });
      } else {
        dispatch({
          type: types.INIT_HISTORY_SEARCH_SUCCESS,
          results: response.results,
          pagination: { next: response.next, previous: response.previous, page: 0 },
        });
      }
    });
  };
}

export function changePage(pageChange, history) {
  return (dispatch) => {
    const page =
      history.pagination.page + pageChange <= history.results.length / 25
        ? history.pagination.page + pageChange
        : history.pagination.page;
    dispatch({ type: types.CHANGE_PAGE, page });
    if (page * 25 >= history.results.length - 75 && history.pagination.next && !history.busy) {
      dispatch({ type: types.FETCH_NEXT_INIT });
      FetchService.fetchNextHistory(history.pagination.next).then((response) => {
        if (response.error) {
          dispatch({ type: types.FETCH_NEXT_ERROR, error: response.error });
        } else {
          dispatch({
            type: types.FETCH_NEXT_SUCCESS,
            results: response.results,
            pagination: { next: response.next, previous: response.previous },
          });
        }
      });
    }
  };
}

const history = { searchHistory, changePage };
export default history;
