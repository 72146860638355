// default components
import InformationModal from 'Components/InformationModal/InformationModal';
// custom components
import SSLSeal from 'Components/SSLSeal/SSLSeal';
import logo from 'assets/images/logo_color.svg';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { PureComponent } from 'react';
import {
  Button,
  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
} from 'react-bootstrap';
import { connect } from 'react-redux';

// styling
import './Signup.css';

class Signup extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      user: '',
      password: '',
      password_verification: '',
      showModal: false,
      showRedirectModal: false,
    };
    this.handleUserChange = this.handleUserChange.bind(this);
    this.getUserValidationState = this.getUserValidationState.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeRedirect = this.closeRedirect.bind(this);
    this.timer = null;
  }

  componentWillMount() {
    if (this.props.authenticated) {
      this.props.history.push('/dashboard');
    } else {
      const query = queryString.parse(this.props.location.search);
      if (query) {
        this.setState({
          token: query.token ? query.token : '',
        });
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.authenticated) {
      this.props.history.push('/dashboard');
    }
  }

  /**
   * Update email when user changes corresponding input field
   * @param {Event} e
   */
  handleUserChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  /**
   * Send signup submission to auth0 if email format is correct.
   * Redirect modal opens if user successfully signed up to auth0.
   * @param {Event} e
   */
  handleSubmit(e) {
    e.preventDefault();

    if (this.getUserValidationState()) {
      const { token } = this.state;
      if (this.state.token) {
        this.props.FetchService.signUpWithToken({
          token,
          email: this.state.user,
          password: this.state.password,
        })
          .then(() => {
            this.setState({
              errorMessage: ' ',
              showRedirectModal: true,
            });
            this.timer = setTimeout(() => this.closeRedirect(), 5000);
          })
          .catch((error) => {
            if (typeof error === 'object') {
              const errorMessage = Object.keys(error)
                .map((err) => error[err])
                .join();
              this.setState({
                errorMessage,
              });
            } else {
              this.setState({
                errorMessage: error,
              });
            }
          });
      } else {
        this.props.auth.signup(
          {
            connection: 'Username-Password-Authentication',
            email: this.state.user,
            password: this.state.password,
          },
          (err) => {
            if (err) {
              this.setState({
                errorMessage: err.description,
              });
            } else {
              this.setState({
                errorMessage: ' ',
                showRedirectModal: true,
              });
              this.timer = setTimeout(() => this.closeRedirect(), 5000);
            }
          },
        );
      }
    } else {
      this.setState({
        errorMessage: 'Invalid email',
      });
    }
  }

  /**
   * Validate format of email
   */
  getUserValidationState() {
    const { user } = this.state;
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (user.length > 0) {
      return re.test(user);
    }
    return false;
  }

  passwordsMatch() {
    return (
      this.state.password.length > 0 && this.state.password === this.state.password_verification
    );
  }

  /**
   * Open information modal
   */
  open() {
    this.setState({ showModal: true });
  }

  /**
   * Close information modal
   */
  close() {
    this.setState({ showModal: false });
  }

  /**
   * Close redirect modal and move to login page with email as query
   */
  closeRedirect() {
    this.setState({ showRedirectModal: false });
    clearTimeout(this.closeRedirect);
    this.props.history.push(`/login?user=${this.state.user}`);
  }

  render() {
    return (
      <Grid className="sign-up">
        <Row>
          <Col md={4} mdOffset={4} xs={12} className="sign-up-content">
            <img src={logo} alt="DNS monitor signup" className="signUpLogo" />
            <Form horizontal onSubmit={this.handleSubmit}>
              <Row>
                <Col xs={12}>
                  <FormGroup controlId="user">
                    <Col componentClass={ControlLabel} id="control-label" xs={10} xsOffset={1}>
                      E-mail:
                    </Col>
                    <Col xs={10} xsOffset={1}>
                      <FormControl
                        type="email"
                        name="user"
                        placeholder="name@example.com"
                        value={this.state.user}
                        onChange={this.handleUserChange}
                        autoComplete="username"
                      />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FormGroup controlId="password">
                    <Col componentClass={ControlLabel} id="control-label" xs={10} xsOffset={1}>
                      Password:
                    </Col>
                    <Col xs={10} xsOffset={1}>
                      <FormControl
                        type="password"
                        name="password"
                        placeholder="*******"
                        autoComplete="new-password"
                        value={this.state.password}
                        onChange={this.handleUserChange}
                      />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FormGroup controlId="password-verification">
                    <Col componentClass={ControlLabel} id="control-label" xs={10} xsOffset={1}>
                      Confirm password:
                    </Col>
                    <Col xs={10} xsOffset={1}>
                      <FormControl
                        type="password"
                        name="password_verification"
                        placeholder="*******"
                        value={this.state.password_verification}
                        onChange={this.handleUserChange}
                      />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="error-message">
                <Col xs={11}>{this.state.errorMessage}</Col>
              </Row>
              <Row className="submit-buttons">
                <Col xs={11}>
                  <i
                    className="fas fa-info-circle information-icon"
                    onClick={this.open}
                    onKeyPress={this.open}
                    role="button"
                    tabIndex="0"
                  />
                  <Button
                    disabled={!this.passwordsMatch()}
                    type="submit"
                    className="btn btn-primary"
                  >
                    Register
                  </Button>

                  <InformationModal
                    showModal={this.state.showModal}
                    closeCallback={this.close}
                    modalHeader="Sign-up instructions"
                  >
                    <ul className="list-guide">
                      <li>
                        <b>1. </b>
                        The user name must be a valid email address
                      </li>
                      <li>
                        <b>2. </b>
                        We need to validate the email address before you can continue. Follow the
                        link in the email sent to the address you selected as your user name.
                      </li>
                      <li>
                        <b>3. </b>
                        The user name you enter here will be your administrative account.
                      </li>
                      <li>
                        <b>4. </b>
                        When your email address is validated you can continue with the few
                        configuration steps to start the monitoring service of your first domain.
                      </li>
                    </ul>
                  </InformationModal>
                  <InformationModal
                    showModal={this.state.showRedirectModal}
                    closeCallback={this.closeRedirect}
                    modalHeader="Registration Completed"
                  >
                    <div className="modal-text">
                      Thank you for signing up to our DNS monitoring service! Please verify your
                      email address by clicking the link we sent to your registration email.
                      <br />
                      <br />
                      <b>You will now be redirected to the login page.</b>
                    </div>
                  </InformationModal>
                </Col>
              </Row>
            </Form>
            <Row className="agreement">
              <i>
                By registering you agree to our{' '}
                <a href="/legal/service-agreement" target="_blank">
                  <b className="term-link">Service agreement</b>
                </a>
                .
              </i>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="sslSeal text-center">
            <SSLSeal />
          </Col>
        </Row>
      </Grid>
    );
  }
}

Signup.propTypes = {
  auth: PropTypes.shape({ signup: PropTypes.func.isRequired }).isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  FetchService: PropTypes.shape({
    signUpWithToken: PropTypes.func,
  }).isRequired,
  authenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  authenticated: state.authentication.authenticated,
});

export default connect(mapStateToProps)(Signup);
