// default imports
// actions
import { getDomainMonitorData } from 'Actions/dashboard';
// custom components
import DataTable from 'Components/DataTable/DataTable';
// Constants
import {
  SUBSCRIPTION_BASIC,
  SUBSCRIPTION_FREE,
  SUBSCRIPTION_PREMIUM,
} from 'Constants/subscriptionTypes';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import FetchService from 'utils/FetchService';

// styling
import './DashboardDomain.css';

export class DashboardDomain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
      fetched: false,
      domain: {},
      showLocations: {},
      showDomain: false,
      copyConfig: [],
      latestRunTime: undefined,
    };
    this.fetchDomainData = this.fetchDomainData.bind(this);
    this.timer = this.timer.bind(this);
    this.toggleLocation = this.toggleLocation.bind(this);
    this.toggleDomain = this.toggleDomain.bind(this);
  }

  componentWillMount() {
    if (this.props.domain.domain_id) {
      this.fetchDomainData(this.props.domain.domain_id);
    }
  }

  componentDidMount() {
    this._intervalID = setInterval(this.timer, 60000);
    const showLocations = {};
    if (_.isEmpty(this.state.showLocations)) {
      this.props.domain.locations.forEach((location) => {
        showLocations[location.location_id] = false;
      });
      this.setState({
        showLocations,
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this._intervalID);
  }

  // eslint-disable-next-line class-methods-use-this
  copyConfiguration = (location) => {
    const dns = { ...location.dns };
    const domainCheck = { ...location.domain_check };
    return {
      config_id: location.config_id,
      dns,
      domain_check: domainCheck,
      record_data: location.record_data || [],
      host: location.host,
    };
  };

  fetchDomainData = (id) => {
    this.setState({
      fetching: true,
    });
    getDomainMonitorData(id)
      .then((Response) => {
        const copyConfig = [];
        Response.locations.forEach((location) => {
          copyConfig.push(this.copyConfiguration(location));
        });
        this.setState(
          {
            fetching: false,
            fetched: true,
            domain: Response,
            copyConfig,
          },
          () => {
            let sev = -1;
            let latestRun = Response.locations[0].data.date_executed;
            Response.locations.forEach((location) => {
              sev = location.data.severity ? Math.max(location.data.severity, sev) : sev;
              latestRun = moment(location.data.date_executed).isAfter(latestRun)
                ? location.data.date_executed
                : latestRun;
            });
            this.props.setSeverity(Response.domain, sev);
            this.setState({
              latestRunTime: latestRun,
            });
          },
        );
      })
      .catch(() => {
        this.setState({
          fetching: false,
        });
      });
  };

  setProgressBarColor = (value) => {
    switch (value) {
      case 0:
        return '#08A500';
      case 1:
        return '#EDED00';
      case 2:
        return '#DD8C00';
      case 3:
        return '#DD0000';
      case 4:
        return '#8C8C8C';
      default:
        return '#EEE';
    }
  };

  setAvailInteg = (severity, letter = '') => {
    switch (severity) {
      case 0:
        return <div className="severityCircle zero">{letter}</div>;
      case 1:
        return <div className="severityCircle one">{letter}</div>;
      case 2:
        return <div className="severityCircle two">{letter}</div>;
      case 3:
        return <div className="severityCircle three">{letter}</div>;
      case 4:
        return <div className="severityCircle four">{letter}</div>;
      default:
        return <div className="severityCircle temp">{letter}</div>;
    }
  };

  saveConfiguration = (copyConf) => {
    const dnsList = [];
    Object.keys(copyConf.dns).forEach((key) => {
      const item = copyConf.dns[key];
      const dns = {};
      Object.keys(item).forEach((check) => {
        if (check === 'ip' || check === 'name') {
          dns[check] = item[check];
        } else {
          dns[check] = item[check].cfg_status;
        }
      });
      dnsList.push(dns);
    });
    const hostList = [];
    Object.keys(copyConf.host).forEach((key) => {
      const item = copyConf.host[key];
      const host = {};
      Object.keys(item).forEach((check) => {
        host[check] = item[check];
      });
      hostList.push(host);
    });
    const domainCheck = {};
    const domain = copyConf.domain_check;
    Object.keys(copyConf.domain_check).forEach((item) => {
      domainCheck[item] = domain[item].cfg_status;
    });
    const obj = { dns: dnsList, host: hostList, domain_check: domainCheck };
    FetchService.sendNewConfiguration(obj, copyConf.config_id).then(() => {
      this.fetchDomainData(this.props.domain.domain_id);
    });
  };

  timer() {
    if (!this.state.fetching) this.fetchDomainData(this.props.domain.domain_id);
  }

  toggleLocation(locationId) {
    const { domain, showLocations } = this.state;
    const { subscriptionType } = this.props;
    showLocations[locationId] =
      !showLocations[locationId] &&
      (subscriptionType === SUBSCRIPTION_PREMIUM ||
        (subscriptionType === SUBSCRIPTION_BASIC && domain.locations[0].host[0]));
    this.setState({
      showLocations,
    });
  }

  toggleDomain() {
    const { showDomain } = this.state;
    this.setState({
      showDomain: !showDomain,
    });
  }

  render() {
    const { displayInformation, subscriptionType } = this.props;
    const { copyConfig, showLocations, showDomain } = this.state;
    const hasMonitorData = this.state.fetched;
    const locationConfigs = [];
    const showDomainLocations = {};

    if (hasMonitorData) {
      const { domain } = this.state;
      domain.locations.forEach((location) => {
        showDomainLocations[location.location_id] =
          showLocations[location.location_id] &&
          (subscriptionType === SUBSCRIPTION_PREMIUM ||
            (subscriptionType === SUBSCRIPTION_BASIC && location.host[0]));
      });
      _.sortBy(domain.locations, ['location_id']).forEach((locationConfig, index) => {
        const keyName = `${domain.domain}_${index}`;
        let copy = {};
        copyConfig.forEach((conf) => {
          if (conf.config_id === locationConfig.config_id) {
            copy = conf;
          }
        });
        const availabilityDot = locationConfig.data.availability
          ? this.setAvailInteg(locationConfig.data.availability.rc, 'A')
          : this.setAvailInteg(4, 'A');
        const integrityDot = locationConfig.data.integrity
          ? this.setAvailInteg(locationConfig.data.integrity.rc, 'I')
          : this.setAvailInteg(4, 'I');
        const hasHostData = !!locationConfig.host[0];
        let locationClass = '';
        if (showLocations[locationConfig.location_id]) {
          locationClass = 'expanded';
        } else if (
          domain.subscription_type === SUBSCRIPTION_PREMIUM ||
          (domain.subscription_type === SUBSCRIPTION_BASIC && hasHostData)
        ) {
          locationClass = 'collapsed';
        }

        locationConfigs.push(
          <div key={keyName}>
            <div
              role="button"
              tabIndex="0"
              onKeyPress={() => {
                this.toggleLocation(locationConfig.location_id);
              }}
              onClick={() => {
                this.toggleLocation(locationConfig.location_id);
              }}
              className={`locationConfigToggle ${locationClass}`}
            >
              <div className="availabilityIntegrityContainer">
                <h4 className="severity availability">{availabilityDot}</h4>
                <h4 className="severity integrity">{integrityDot}</h4>
              </div>
              <h5>{`${locationConfig.city_or_region},  ${locationConfig.country}`}</h5>
            </div>
            <div
              style={{ display: showLocations[locationConfig.location_id] ? 'block' : 'none' }}
              className="locationConfigContainer"
            >
              <div className="timeStamp">
                Last run: {moment(locationConfig.data.date_executed).format('YYYY-MM-DD HH:mm:ss')}
              </div>
              {showLocations[locationConfig.location_id] && subscriptionType !== SUBSCRIPTION_FREE && (
                <DataTable
                  locationConfig={{
                    ...locationConfig,
                    expirationStatus: domain.expiration ? domain.expiration.cfg_status : '',
                    expirationData: domain.expiration,
                  }}
                  copyConf={copy}
                  domainName={domain.domain}
                  displayInformation={displayInformation}
                  saveConfiguration={this.saveConfiguration}
                  subscriptionType={subscriptionType}
                />
              )}
            </div>
          </div>,
        );
      });
    } else {
      const { domain } = this.props;
      _.sortBy(domain.locations, ['location_id']).forEach((locationConfig, index) => {
        const keyName = `${domain.name}_${index}`;
        locationConfigs.push(
          <div key={keyName}>
            <div
              role="button"
              tabIndex="0"
              onKeyPress={() => {
                this.toggleLocation(locationConfig.location_id);
              }}
              onClick={() => {
                this.toggleLocation(locationConfig.location_id);
              }}
              className={`locationConfigToggle ${
                this.state.showLocations[locationConfig.location_id] ? 'expanded' : 'collapsed'
              }`}
            >
              <div className="availabilityIntegrityContainer">
                <h4 className="severity availability">{this.setAvailInteg(2, 'A')}</h4>
                <h4 className="severity integrity">{this.setAvailInteg(2, 'I')}</h4>
              </div>
              <h5>{`${locationConfig.city_or_region},  ${locationConfig.country}`}</h5>
            </div>
            <div
              style={{
                display: this.state.showLocations[locationConfig.location_id] ? 'block' : 'none',
              }}
              className={
                subscriptionType !== SUBSCRIPTION_FREE
                  ? 'locationConfigContainer'
                  : 'locationConfigContainer premium'
              }
            />
          </div>,
        );
      });
    }

    if (hasMonitorData) {
      const { domain } = this.state;
      let domainType = '[F]';
      if (domain.subscription_type === SUBSCRIPTION_PREMIUM) {
        domainType = '[P]';
      } else if (domain.subscription_type === SUBSCRIPTION_BASIC) {
        domainType = '[B]';
      }
      return (
        <Row>
          <Col md={12}>
            <div className="mainDomain">
              <div
                role="button"
                tabIndex="0"
                onKeyPress={() => {
                  this.toggleDomain(domain.domain_id);
                }}
                className={`mainDomainToggle ${showDomain ? 'expanded' : 'collapsed'}`}
                onClick={() => {
                  this.toggleDomain(domain.domain_id);
                }}
              >
                <div className="availabilityIntegrityContainer">
                  {domain.severity ? (
                    <h4 className="severity tip">
                      {this.setAvailInteg(domain.severity.rc)}
                      {domain.severity !== '' && domain.severity.rc !== 0 ? (
                        <span>{domain.severity.type}</span>
                      ) : (
                        ''
                      )}
                    </h4>
                  ) : (
                    ''
                  )}
                </div>
                <h4 className="domainName">
                  {domain.domain} {domainType}
                </h4>
                <p className="domainNameMeta">
                  {domain.expiration && domain.subscription_type === SUBSCRIPTION_PREMIUM
                    ? domain.expiration.data
                    : ''}
                </p>
              </div>
              <div
                className="mainDomainContainer"
                style={{ display: showDomain ? 'block' : 'none' }}
              >
                <div className="metaContainer">
                  <div className="timeStamp">
                    Last run: {moment(this.state.latestRunTime).format('YYYY-MM-DD HH:mm:ss')}
                  </div>
                  <div className="mainDomainMetadata">
                    <div className="graphContainer">
                      {domain.availability ? (
                        <div>
                          <p>Availability is at {domain.availability.percent}%</p>
                          <div className="graph">
                            <div
                              className="progressBar"
                              style={{
                                width: `${domain.availability.percent}%`,
                                backgroundColor: this.setProgressBarColor(domain.availability.rc),
                              }}
                            />
                          </div>
                          <p>{domain.availability.rc > 0 ? domain.availability.msg : ''}</p>
                        </div>
                      ) : (
                        <p>No availability data available.</p>
                      )}
                    </div>
                  </div>
                  <div className="mainDomainMetadata">
                    <div className="graphContainer">
                      {domain.integrity ? (
                        <div>
                          <p>Integrity is at {domain.integrity.percent}%</p>
                          <div className="graph">
                            <div
                              className="progressBar"
                              style={{
                                width: `${domain.integrity.percent}%`,
                                backgroundColor: this.setProgressBarColor(domain.integrity.rc),
                              }}
                            />
                          </div>
                          {domain.integrity.rc > 0 ? <p>{domain.integrity.msg}</p> : ''}
                        </div>
                      ) : (
                        <p> No integrity data available.</p>
                      )}
                    </div>
                  </div>
                </div>
                <div>{locationConfigs}</div>
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    const { domain } = this.props;
    // Draw dashboard using domain object from /domains/ endpoint instead of /monitor/
    return (
      <Row>
        <Col md={12}>
          <div className="mainDomain">
            <div
              role="button"
              tabIndex="0"
              onKeyPress={() => {
                this.toggleDomain(domain.domain_id);
              }}
              className={`mainDomainToggle ${showDomain ? 'expanded' : 'collapsed'}`}
              onClick={() => {
                this.toggleDomain(domain.domain_id);
              }}
            >
              <div className="availabilityIntegrityContainer loadDomainSpinner">
                <i className="fas fa-spinner fa-spin fa-fw" />
              </div>
              <h4 className="domainName">{domain.name}</h4>
            </div>
            <div className="mainDomainContainer" style={{ display: showDomain ? 'block' : 'none' }}>
              <div className="metaContainer">
                <div className="mainDomainMetadata">
                  <div className="graphContainer">
                    <p>
                      <i className="fas fa-spinner fa-spin fa-fw" />
                      Loading availability data.
                    </p>
                  </div>
                </div>
                <div className="mainDomainMetadata">
                  <div className="graphContainer">
                    <p>
                      <i className="fas fa-spinner fa-spin fa-fw" />
                      Loading integrity data.
                    </p>
                  </div>
                </div>
              </div>
              <div>{locationConfigs}</div>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

DashboardDomain.propTypes = {
  domain: PropTypes.shape().isRequired,
  displayInformation: PropTypes.bool.isRequired,
  subscriptionType: PropTypes.number.isRequired,
  setSeverity: PropTypes.func.isRequired,
};

export default DashboardDomain;
