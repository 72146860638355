// default imports
// actions
import { getDomainConfiguration } from 'Actions/domains';
// custom components
import Loading from 'Components/Loading/Loading';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Grid, Row, Col, ControlLabel } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import DashboardDomain from './Components/DashboardDomain/DashboardDomain';
// styling
import './Dashboard.css';

export class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_info: false,
      ordering: 'severity',
      domainSeverities: {},
    };
    this.sortDomains = this.sortDomains.bind(this);
    this.updateSort = this.updateSort.bind(this);
    this.setDomainSeverity = this.setDomainSeverity.bind(this);
  }

  componentWillMount() {
    this.props.dispatch(getDomainConfiguration());
  }

  setDomainSeverity(domainName, severity) {
    const { domainSeverities } = this.state;
    this.setState({
      domainSeverities: {
        ...domainSeverities,
        [domainName]: severity,
      },
    });
  }

  getDomainSeverity(domainName) {
    return this.state.domainSeverities[domainName] ? this.state.domainSeverities[domainName] : -1;
  }

  updateSort(event) {
    this.setState({
      ordering: event.target.value,
    });
  }

  sortDomains(domains) {
    switch (this.state.ordering) {
      case 'severity':
        domains.sort((a, b) => {
          const severityA = this.getDomainSeverity(a.props.domain.name);
          const severityB = this.getDomainSeverity(b.props.domain.name);
          return severityB - severityA;
        });
        break;
      case 'name':
        domains.sort((a, b) => a.props.domain.name.localeCompare(b.props.domain.name));
        break;
      case 'subscription':
        domains.sort((a, b) => a.props.domain.subscription_type - b.props.domain.subscription_type);
        break;
      default:
        break;
    }
  }

  render() {
    const { domainStore } = this.props;
    if (domainStore.fetched) {
      const domains = [];
      if (domainStore.domains.results.length > 0) {
        domainStore.domains.results.forEach((domain) => {
          domains.push(
            <DashboardDomain
              key={domain.domain_id}
              domain={domain}
              displayInformation={this.state.show_info}
              subscriptionType={domain.subscription_type}
              dispatch={this.props.dispatch}
              setSeverity={this.setDomainSeverity}
            />,
          );
        });
        this.sortDomains(domains);
      } else {
        return <Redirect to={{ pathname: '/configure/domains', state: { from: '/dashboard' } }} />;
      }

      return (
        <Grid className="dashboardContainer" id="dashboardContainer">
          {this.props.domainStore.domains.results.length > 1 ? (
            <Row>
              <Col md={12} className="selection-row">
                <ControlLabel style={{ marginRight: '10px' }}>Sort Order:</ControlLabel>
                <select
                  value={this.state.ordering}
                  onChange={(e) => {
                    this.updateSort(e);
                  }}
                >
                  <option className="sort-option" value="severity">
                    Severity
                  </option>
                  <option className="sort-option" value="name">
                    Name
                  </option>
                  <option className="sort-option" value="subscription">
                    Subscription Type
                  </option>
                </select>
                <ControlLabel style={{ marginRight: '10px', marginLeft: '30px' }}>
                  Check Information:
                </ControlLabel>
                <select
                  value={this.state.show_info}
                  onChange={(e) => {
                    this.setState({ show_info: e.target.value === 'true' });
                  }}
                >
                  <option className="sort-option" value>
                    On
                  </option>
                  <option className="sort-option" value={false}>
                    Off
                  </option>
                </select>
              </Col>
            </Row>
          ) : (
            ''
          )}
          {domains}
        </Grid>
      );
    }
    return (
      <Grid className="dashboardContainer" id="dashboardContainer">
        <Loading loadingText="Loading Domains..." />
      </Grid>
    );
  }
}

Dashboard.propTypes = {
  dispatch: PropTypes.func.isRequired,
  domainStore: PropTypes.shape({
    selected_domain: PropTypes.object,
    fetching: PropTypes.bool,
    reconfigure_in_progress: PropTypes.bool,
    fetched: PropTypes.bool,
    domains: PropTypes.object,
    reconfigure_errors: PropTypes.string.isRequired,
    delete_in_progress: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  domainStore: state.domains,
  sort: state.dashboard.sort,
});

export default connect(mapStateToProps)(Dashboard);
